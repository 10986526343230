const positions = [
 {
   "PositionId": "1",
   "JourneyType": "High School",
   "Category": "Skilled Trades",
   "Position": "Entry Marine Welder",
   "Skills": ["Hand-eye coordination","detail-oriented"],
   "Timeframe": "0-5 years",
   "SalaryMin": 30000,
   "SalaryMax": 55000,
   "Description": "Permanently joins metal parts of a ship using intense heat.  Successful welders have proficient hand-eye coordination and are detailed oriented.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed":['<iframe width="560" height="315" src="https://www.youtube.com/embed/jRr6RqMhogI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                 '<iframe width="560" height="315" src="https://www.youtube.com/embed/1za-7qiYO18" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "2",
   "JourneyType": "High School",
   "Category": "Skilled Trades",
   "Position": "Entry Deck Electrician",
   "Skills": ["Electrical"],
   "Timeframe": "0-5 years",
   "SalaryMin": 30000,
   "SalaryMax": 55000,
   "Description": "Installs, repairs, and tests electrical systems aboard a ship (lighting, power, distribution, communication or navigation). This career is in high demand as more electrical systems are being installed on ships.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/nDwSDNd6xN8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                  '<iframe width="560" height="315" src="https://www.youtube.com/embed/xCRxOKUr8yM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "3",
   "JourneyType": "High School",
   "Category": "Skilled Trades",
   "Position": "Entry Pipefitter",
   "Skills": ["Strength","Problem-solving"],
   "Timeframe": "0-5 years",
   "SalaryMin": 30000,
   "SalaryMax": 55000,
   "Description": "Fits and installs piping systems. Like a plumber, this career requires physical strength and stamina in addition to problem-solving skills.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/xaBSJVEW070" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "4",
   "JourneyType": "High School",
   "Category": "Skilled Trades",
   "Position": "Entry Shipfitter",
   "Skills": ["Detail","Measurement"],
   "Timeframe": "0-5 years",
   "SalaryMin": 30000,
   "SalaryMax": 55000,
   "Description": "Similar to a carpenter and framer in home construction, assembles and erects metal plates to make the structure of a ship. This skilled position requires attention to detail and good measurement skills.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/9mxKfSF2U9k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "5",
   "JourneyType": "High School",
   "Category": "Skilled Trades",
   "Position": "Entry Machinist",
   "Skills": ["Multi-task","Machinery"],
   "Timeframe": "0-5 years",
   "SalaryMin": 30000,
   "SalaryMax": 55000,
   "Description": "Uses computer-controlled or mechanically-based equipment to manufacture products. This career requires the ability to multi-task, proper understanding of machinery and job specifications to ensure high quality work.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed": ['<iframe src="https://player.vimeo.com/video/430752393?h=eac3451ade" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "6",
   "JourneyType": "High School",
   "Category": "Skilled Trades",
   "Position": "Entry Marine Painter",
   "Skills": ["Painting"],
   "Timeframe": "0-5 years",
   "SalaryMin": 30000,
   "SalaryMax": 55000,
   "Description": "Skilled in the craft of covering various vessels surfaces, both interior and exterior, with protective coatings using brushes, spray guns and rollers to avoid rust/corrosion of underwater portions of vessels.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/qOW2VX9-vDY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                  '<iframe src="https://player.vimeo.com/video/430751776?h=03c1c24f75" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "7",
   "JourneyType": "High School",
   "Category": "Trades Support",
   "Position": "Entry Materials Support",
   "Skills": ["Math","Documentation"],
   "Timeframe": "0-5 years",
   "SalaryMin": 27000,
   "SalaryMax": 42000,
   "Description": "Issues and controls production schedules and material flow in the shipyard. Basic math skills and documentation abilities are essential in this career.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "8",
   "JourneyType": "High School",
   "Category": "Trades Support",
   "Position": "Entry Facilities Maintenance",
   "Skills": ["Problem-solving"],
   "Timeframe": "0-5 years",
   "SalaryMin": 27000,
   "SalaryMax": 42000,
   "Description": "Maintains and operates facilities equipment/machinery. Excellent problem-problem-solving and critical thinking skills are important in this position. ​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "9",
   "JourneyType": "High School",
   "Category": "Trades Support",
   "Position": "Entry Tool/Drawing Clerks",
   "Skills": ["Detail"],
   "Timeframe": "0-5 years",
   "SalaryMin": 27000,
   "SalaryMax": 42000,
   "Description": "Retrieves data, issues and controls tools, drawings, consumable materials and safety equipment. Important skills for this position include: organization, attention to detail and working knowledge of design software.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "10",
   "JourneyType": "High School",
   "Category": "Apprentice School",
   "Position": "Apprentice School",
   "Skills": [""],
   "Timeframe": "0-5 years",
   "SalaryMin": 37000,
   "SalaryMax": 60000,
   "Description": "Apprentice Programs are typically 4-5 years. While working and receiving salary and benefits, students graduate with a certificate of apprenticeship, Associate’s degree and/or Bachelor’s degree.",
   "EducationRequirement": "4-5 years",
   "Cost": "Application Fee",
   "Benefits": 10000
 },
 {
   "PositionId": "11",
   "JourneyType": "High School",
   "Category": "Community College Pre-Hire",
   "Position": "Community College Pre-Hire",
   "Skills": [""],
   "Timeframe": "0-5 years",
   "SalaryMin": 32000,
   "SalaryMax": 55000,
   "Description": "You can enter shipyard trainee programs with a High School Diploma or GED. These trainee programs are a joint partnership with industry companies and community colleges.",
   "EducationRequirement": "1-4 years",
   "Cost": "$250 tuition fee (fee is reimbursed if the student/candidate is hired)",
   "Benefits": 10000
 },
 {
   "PositionId": "12",
   "JourneyType": "High School",
   "Category": "Skilled Trades",
   "Position": "Marine Welder",
   "Skills": ["Hand-eye coordination","detail-oriented"],
   "Timeframe": "6-15 years",
   "SalaryMin": 42000,
   "SalaryMax": 60000,
   "Description": "Permanently joins metal parts of a ship using intense heat.  Successful welders have proficient hand-eye coordination and are detailed oriented.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed":['<iframe width="560" height="315" src="https://www.youtube.com/embed/jRr6RqMhogI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                 '<iframe width="560" height="315" src="https://www.youtube.com/embed/1za-7qiYO18" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "13",
   "JourneyType": "High School",
   "Category": "Skilled Trades",
   "Position": "Deck Electrician",
   "Skills": ["Electrical"],
   "Timeframe": "6-15 years",
   "SalaryMin": 42000,
   "SalaryMax": 60000,
   "Description": "Installs, repairs, and tests electrical systems aboard a ship (lighting, power, distribution, communication or navigation). This career is in high demand as more electrical systems are being installed on ships.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/nDwSDNd6xN8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                  '<iframe width="560" height="315" src="https://www.youtube.com/embed/xCRxOKUr8yM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "14",
   "JourneyType": "High School",
   "Category": "Skilled Trades",
   "Position": "Pipefitter",
   "Skills": ["Strength","Problem-solving"],
   "Timeframe": "6-15 years",
   "SalaryMin": 42000,
   "SalaryMax": 60000,
   "Description": "Fits and installs piping systems. Like a plumber, this career requires physical strength and stamina in addition to problem-solving skills.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "15",
   "JourneyType": "High School",
   "Category": "Skilled Trades",
   "Position": "Shipfitter",
   "Skills": ["Detail","Measurement"],
   "Timeframe": "6-15 years",
   "SalaryMin": 42000,
   "SalaryMax": 60000,
   "Description": "Similar to a carpenter and framer in home construction, assembles and erects metal plates to make the structure of a ship. This skilled position requires attention to detail and good measurement skills.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/9mxKfSF2U9k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "16",
   "JourneyType": "High School",
   "Category": "Skilled Trades",
   "Position": "Machinist",
   "Skills": ["Multi-task","Machinery"],
   "Timeframe": "6-15 years",
   "SalaryMin": 42000,
   "SalaryMax": 60000,
   "Description": "Uses computer-controlled or mechanically-based equipment to manufacture products. This career requires the ability to multi-task, proper understanding of machinery and job specifications to ensure high quality work.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed": ['<iframe src="https://player.vimeo.com/video/430752393?h=eac3451ade" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "17",
   "JourneyType": "High School",
   "Category": "Skilled Trades",
   "Position": "Marine Painter",
   "Skills": ["Painting"],
   "Timeframe": "6-15 years",
   "SalaryMin": 42000,
   "SalaryMax": 60000,
   "Description": "Skilled in the craft of covering various vessels surfaces, both interior and exterior, with protective coatings using brushes, spray guns and rollers to avoid rust/corrosion of underwater portions of vessels.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/qOW2VX9-vDY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                  '<iframe src="https://player.vimeo.com/video/430751776?h=03c1c24f75" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "18",
   "JourneyType": "High School",
   "Category": "Trades Support",
   "Position": "Materials Support",
   "Skills": ["Math","Documentation"],
   "Timeframe": "6-15 years",
   "SalaryMin": 34000,
   "SalaryMax": 46000,
   "Description": "Issues and controls production schedules and material flow in the shipyard. Basic math skills and documentation abilities are essential in this career.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "19",
   "JourneyType": "High School",
   "Category": "Trades Support",
   "Position": "Facilities Maintenance",
   "Skills": ["Problem-solving"],
   "Timeframe": "6-15 years",
   "SalaryMin": 34000,
   "SalaryMax": 46000,
   "Description": "Maintains and operates facilities equipment/machinery. Excellent problem-problem-solving and critical thinking skills are important in this position. ​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "20",
   "JourneyType": "High School",
   "Category": "Trades Support",
   "Position": "Tool/Drawing Clerks",
   "Skills": ["Detail"],
   "Timeframe": "6-15 years",
   "SalaryMin": 34000,
   "SalaryMax": 46000,
   "Description": "Retrieves data, issues and controls tools, drawings, consumable materials and safety equipment. Important skills for this position include: organization, attention to detail and working knowledge of design software.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "21",
   "JourneyType": "High School",
   "Category": "Trades Support",
   "Position": "QA Inspector",
   "Skills": ["Detail"],
   "Timeframe": "6-15 years",
   "SalaryMin": 34000,
   "SalaryMax": 46000,
   "Description": "Performs quality assurance inspections, tests, and audits required to substantiate conformance to drawing specifications and contract requirements in all phases of construction.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "22",
   "JourneyType": "High School",
   "Category": "Construction Management",
   "Position": "Foreman",
   "Skills": ["Supervising","Organization"],
   "Timeframe": "6-15 years",
   "SalaryMin": 55000,
   "SalaryMax": 110000,
   "Description": "Supervises, assigns, evaluates and coordinates skilled trade personnel according to skill experience and job knowledge.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "23",
   "JourneyType": "High School",
   "Category": "Construction Management",
   "Position": "Construction Supervisor",
   "Skills": ["Supervising","Organization"],
   "Timeframe": "6-15 years",
   "SalaryMin": 55000,
   "SalaryMax": 110000,
   "Description": "Leads, guides, coordinates, assists trades and subcontractors to perform work within their areas of responsibility to make project goals.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "24",
   "JourneyType": "High School",
   "Category": "Degreed Careers",
   "Position": "Degreed Careers",
   "Skills": [""],
   "Timeframe": "6-15 years",
   "SalaryMin": 30000,
   "SalaryMax": 55000,
   "Description": "Most companies offer tuition assistance/reimbursement to pay for furthering education or have established partnerships with higher education institutions for students to obtain a degree while employed.",
   "EducationRequirement": "2-4 years",
   "Cost": "",
   "Benefits": null
 },
 {
   "PositionId": "25",
   "JourneyType": "High School",
   "Category": "Professional",
   "Position": "Professional",
   "Skills": [""],
   "Timeframe": "6-15 years",
   "SalaryMin": 30000,
   "SalaryMax": 55000,
   "Description": "For some companies and jobs, relevant or non-relevant years of experience can be substituted for educational requirements.",
   "EducationRequirement": "None",
   "Cost": "",
   "Benefits": null
 },
 {
   "PositionId": "26",
   "JourneyType": "High School",
   "Category": "Specialist",
   "Position": "Specialist",
   "Skills": [""],
   "Timeframe": "16+ years",
   "SalaryMin": 50000,
   "SalaryMax": 80000,
   "Description": "Based on production needs, skills, abilities and qualifications each trade has various specialist job functions, such as Fiber Optics Specialist, Crane Operator Specialist, Pipe Fitter Specialist, Nuclear Machinist Specialist, Shipwright Optical/Measurement Specialist, Machinist Specialist, etc.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 20000
 },
 {
   "PositionId": "27",
   "JourneyType": "High School",
   "Category": "Management",
   "Position": "General Foreman",
   "Skills": ["Leadership"],
   "Timeframe": "16+ years",
   "SalaryMin": 75000,
   "SalaryMax": 120000,
   "Description": "Leads, guides, coordinates and supervises a team of foreman, skilled trades and subcontracted labor. Must understand company policies and standards, customer requirements and career opportunities.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 20000
 },
 {
   "PositionId": "28",
   "JourneyType": "High School",
   "Category": "Management",
   "Position": "Superintendent",
   "Skills": ["Organization","Detail"],
   "Timeframe": "16+ years",
   "SalaryMin": 75000,
   "SalaryMax": 120000,
   "Description": "Oversees key aspects of a projects progress by determining personnel, material, equipment and facilities needed through establishing basic priorities and work sequences. ",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 20000
 },
 {
   "PositionId": "29",
   "JourneyType": "High School",
   "Category": "Management",
   "Position": "Program Manager",
   "Skills": ["Leadership"],
   "Timeframe": "16+ years",
   "SalaryMin": 75000,
   "SalaryMax": 120000,
   "Description": "Responsible for overseeing and managing several projects. Must be able to lead teams to implement a programs vision or mission. Key skills include: multi-tasking, problem solving, and a service mentality. ",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 20000
 },
 {
   "PositionId": "30",
   "JourneyType": "Professional",
   "Category": "Skilled Trades",
   "Position": "Entry Marine Welder",
   "Skills": ["Hand-eye coordination","detail-oriented"],
   "Timeframe": "0-5 years",
   "SalaryMin": 30000,
   "SalaryMax": 55000,
   "Description": "Permanently joins metal parts of a ship using intense heat.  Successful welders have proficient hand-eye coordination and are detailed oriented.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed":['<iframe width="560" height="315" src="https://www.youtube.com/embed/jRr6RqMhogI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                 '<iframe width="560" height="315" src="https://www.youtube.com/embed/1za-7qiYO18" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "31",
   "JourneyType": "Professional",
   "Category": "Skilled Trades",
   "Position": "Entry Deck Electrician",
   "Skills": ["Electrical"],
   "Timeframe": "0-5 years",
   "SalaryMin": 30000,
   "SalaryMax": 55000,
   "Description": "Installs, repairs, and tests electrical systems aboard a ship (lighting, power, distribution, communication or navigation). This career is in high demand as more electrical systems are being installed on ships.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/nDwSDNd6xN8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                  '<iframe width="560" height="315" src="https://www.youtube.com/embed/xCRxOKUr8yM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "32",
   "JourneyType": "Professional",
   "Category": "Skilled Trades",
   "Position": "Entry Pipefitter",
   "Skills": ["Strength","Problem-solving"],
   "Timeframe": "0-5 years",
   "SalaryMin": 30000,
   "SalaryMax": 55000,
   "Description": "Fits and installs piping systems. Like a plumber, this career requires physical strength and stamina in addition to problem-solving skills.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/xaBSJVEW070" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "33",
   "JourneyType": "Professional",
   "Category": "Skilled Trades",
   "Position": "Entry Shipfitter",
   "Skills": ["Detail","Measurement"],
   "Timeframe": "0-5 years",
   "SalaryMin": 30000,
   "SalaryMax": 55000,
   "Description": "Similar to a carpenter and framer in home construction, assembles and erects metal plates to make the structure of a ship. This skilled position requires attention to detail and good measurement skills.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/9mxKfSF2U9k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "34",
   "JourneyType": "Professional",
   "Category": "Skilled Trades",
   "Position": "Entry Machinist",
   "Skills": ["Multi-task","Machinery"],
   "Timeframe": "0-5 years",
   "SalaryMin": 30000,
   "SalaryMax": 55000,
   "Description": "Uses computer-controlled or mechanically-based equipment to manufacture products. This career requires the ability to multi-task, proper understanding of machinery and job specifications to ensure high quality work.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed": ['<iframe src="https://player.vimeo.com/video/430752393?h=eac3451ade" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "35",
   "JourneyType": "Professional",
   "Category": "Skilled Trades",
   "Position": "Entry Marine Painter",
   "Skills": ["Painting"],
   "Timeframe": "0-5 years",
   "SalaryMin": 30000,
   "SalaryMax": 55000,
   "Description": "Skilled in the craft of covering various vessels surfaces, both interior and exterior, with protective coatings using brushes, spray guns and rollers to avoid rust/corrosion of underwater portions of vessels.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/qOW2VX9-vDY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                  '<iframe src="https://player.vimeo.com/video/430751776?h=03c1c24f75" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "36",
   "JourneyType": "Professional",
   "Category": "Designer",
   "Position": "Entry Designer",
   "Skills": ["Design"],
   "Timeframe": "0-5 years",
   "SalaryMin": 40000,
   "SalaryMax": 55000,
   "Description": "Utilizes computer aided design tools to create the digital twin of components or portions of systems for manufacturing and repair.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "37",
   "JourneyType": "Professional",
   "Category": "Engineer",
   "Position": "Entry Engineer",
   "Skills": ["Problem-solving"],
   "Timeframe": "0-5 years",
   "SalaryMin": 50000,
   "SalaryMax": 70000,
   "Description": "Using math, science and problem-solving skills engineers design products and systems in various disciplines (i.e., electrical engineer, mechanical engineer, structural engineer, industrial engineer, etc.). ",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "38",
   "JourneyType": "Professional",
   "Category": "Planner",
   "Position": "Entry Planner",
   "Skills": ["Planning"],
   "Timeframe": "0-5 years",
   "SalaryMin": 25000,
   "SalaryMax": 50000,
   "Description": "Plans, prepares, issues and controls production schedules and coordinates with material requirements to ensure a controlled flow of approved materials timed to meet production requirements.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "39",
   "JourneyType": "Professional",
   "Category": "Business Analyst",
   "Position": "Entry Business Analyst",
   "Skills": ["Detail"],
   "Timeframe": "0-5 years",
   "SalaryMin": 40000,
   "SalaryMax": 60000,
   "Description": "Analyzes current business processes and works with business representatives to obtain future goals.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "40",
   "JourneyType": "Professional",
   "Category": "Contracts Analyst",
   "Position": "Entry Contracts Analyst",
   "Skills": ["Detail"],
   "Timeframe": "0-5 years",
   "SalaryMin": 40000,
   "SalaryMax": 60000,
   "Description": "Examines estimates of material, equipment services, production costs, performance requirements, and delivery schedules to ensure accuracy and completeness. Coordination and management of proposals, contracts, subcontracts and purchase orders.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "41",
   "JourneyType": "Professional",
   "Category": "Information Technology",
   "Position": "Cybersecurity Analyst",
   "Skills": ["Design","IT"],
   "Timeframe": "0-5 years",
   "SalaryMin": 50000,
   "SalaryMax": 75000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "42",
   "JourneyType": "Professional",
   "Category": "Information Technology",
   "Position": "Data Analyst",
   "Skills": ["Design","IT"],
   "Timeframe": "0-5 years",
   "SalaryMin": 50000,
   "SalaryMax": 75000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "43",
   "JourneyType": "Professional",
   "Category": "Information Technology",
   "Position": "Software Developer",
   "Skills": ["Design","IT"],
   "Timeframe": "0-5 years",
   "SalaryMin": 50000,
   "SalaryMax": 75000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "44",
   "JourneyType": "Professional",
   "Category": "Information Technology",
   "Position": "Software Architect",
   "Skills": ["Design","IT"],
   "Timeframe": "0-5 years",
   "SalaryMin": 50000,
   "SalaryMax": 75000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "45",
   "JourneyType": "Professional",
   "Category": "Administration/Support",
   "Position": "Entry Administration/Support",
   "Skills": ["Detail"],
   "Timeframe": "0-5 years",
   "SalaryMin": 25000,
   "SalaryMax": 40000,
   "Description": "Compiles, reviews and analyzes data in support of functional areas. Gathers, collects, records, tracks and verifies data and information from multiple sources.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "46",
   "JourneyType": "Professional",
   "Category": "Apprentice School",
   "Position": "Apprentice School",
   "Skills": [""],
   "Timeframe": "0-5 years",
   "SalaryMin": 37000,
   "SalaryMax": 60000,
   "Description": "Apprentice Programs are typically 4-5 years. While working and receiving salary and benefits, students graduate with a certificate of apprenticeship, Associate’s degree and/or Bachelor’s degree.",
   "EducationRequirement": "4-5 years",
   "Cost": "Application Fee",
   "Benefits": 10000
 },
 {
   "PositionId": "47",
   "JourneyType": "Professional",
   "Category": "Skilled Trades",
   "Position": "Marine Welder",
   "Skills": ["Hand-eye coordination","Detail"],
   "Timeframe": "6-15 years",
   "SalaryMin": 42000,
   "SalaryMax": 60000,
   "Description": "Permanently joins metal parts of a ship using intense heat.  Successful welders have proficient hand-eye coordination and are detailed oriented.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed":['<iframe width="560" height="315" src="https://www.youtube.com/embed/jRr6RqMhogI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                 '<iframe width="560" height="315" src="https://www.youtube.com/embed/1za-7qiYO18" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "48",
   "JourneyType": "Professional",
   "Category": "Skilled Trades",
   "Position": "Deck Electrician",
   "Skills": ["Electrical"],
   "Timeframe": "6-15 years",
   "SalaryMin": 42000,
   "SalaryMax": 60000,
   "Description": "Installs, repairs, and tests electrical systems aboard a ship (lighting, power, distribution, communication or navigation). This career is in high demand as more electrical systems are being installed on ships.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/nDwSDNd6xN8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                  '<iframe width="560" height="315" src="https://www.youtube.com/embed/xCRxOKUr8yM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "49",
   "JourneyType": "Professional",
   "Category": "Skilled Trades",
   "Position": "Pipefitter",
   "Skills": ["Strength","Problem-solving"],
   "Timeframe": "6-15 years",
   "SalaryMin": 42000,
   "SalaryMax": 60000,
   "Description": "Fits and installs piping systems. Like a plumber, this career requires physical strength and stamina in addition to problem-solving skills.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/xaBSJVEW070" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "50",
   "JourneyType": "Professional",
   "Category": "Skilled Trades",
   "Position": "Shipfitter",
   "Skills": ["Detail","Measurement"],
   "Timeframe": "6-15 years",
   "SalaryMin": 42000,
   "SalaryMax": 60000,
   "Description": "Similar to a carpenter and framer in home construction, assembles and erects metal plates to make the structure of a ship. This skilled position requires attention to detail and good measurement skills.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/9mxKfSF2U9k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "51",
   "JourneyType": "Professional",
   "Category": "Skilled Trades",
   "Position": "Machinist",
   "Skills": ["Multi-task","Machinery"],
   "Timeframe": "6-15 years",
   "SalaryMin": 42000,
   "SalaryMax": 60000,
   "Description": "Uses computer-controlled or mechanically-based equipment to manufacture products. This career requires the ability to multi-task, proper understanding of machinery and job specifications to ensure high quality work.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed": ['<iframe src="https://player.vimeo.com/video/430752393?h=eac3451ade" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "52",
   "JourneyType": "Professional",
   "Category": "Skilled Trades",
   "Position": "Marine Painter",
   "Skills": ["Painting"],
   "Timeframe": "6-15 years",
   "SalaryMin": 42000,
   "SalaryMax": 60000,
   "Description": "Skilled in the craft of covering various vessels surfaces, both interior and exterior, with protective coatings using brushes, spray guns and rollers to avoid rust/corrosion of underwater portions of vessels.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/qOW2VX9-vDY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                  '<iframe src="https://player.vimeo.com/video/430751776?h=03c1c24f75" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "53",
   "JourneyType": "Professional",
   "Category": "Management",
   "Position": "Management",
   "Skills": ["Supervising","Organization"],
   "Timeframe": "6-15 years",
   "SalaryMin": 40000,
   "SalaryMax": 125000,
   "Description": "Managers are like mothers: Everybody has one. For example rock stars answer to record labels, politicians are responsible to the public and even CEOs report to Boards of Directors. Regardless of age, occupation or industry, everyone reports to someone. Oversees and administers efficient operations by providing adequate staff, materials, equipment and training",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "54",
   "JourneyType": "Professional",
   "Category": "Designer",
   "Position": "Senior Designer",
   "Skills": ["Design"],
   "Timeframe": "6-15 years",
   "SalaryMin": 55000,
   "SalaryMax": 90000,
   "Description": "Utilizes computer aided design tools to create the digital twin of components or portions of systems for manufacturing and repair.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "55",
   "JourneyType": "Professional",
   "Category": "Engineer",
   "Position": "Senior Engineer",
   "Skills": ["Problem-solving"],
   "Timeframe": "6-15 years",
   "SalaryMin": 70000,
   "SalaryMax": 100000,
   "Description": "Using math, science and problem-solving skills engineers design products and systems in various disciplines (i.e., electrical engineer, mechanical engineer, structural engineer, industrial engineer, etc.). ",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "56",
   "JourneyType": "Professional",
   "Category": "Business Analyst",
   "Position": "Senior Business Analyst",
   "Skills": ["Detail"],
   "Timeframe": "6-15 years",
   "SalaryMin": 75000,
   "SalaryMax": 110000,
   "Description": "Analyzes current business processes and works with business representatives to obtain future goals.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "57",
   "JourneyType": "Professional",
   "Category": "Contracts Analyst",
   "Position": "Senior Contracts Analyst",
   "Skills": ["Detail"],
   "Timeframe": "6-15 years",
   "SalaryMin": 60000,
   "SalaryMax": 100000,
   "Description": "Examines estimates of material, equipment services, production costs, performance requirements, and delivery schedules to ensure accuracy and completeness. Coordination and management of proposals, contracts, subcontracts and purchase orders.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "58",
   "JourneyType": "Professional",
   "Category": "Information Technology",
   "Position": "Senior Cybersecurity Analyst",
   "Skills": ["Design","IT"],
   "Timeframe": "6-15 years",
   "SalaryMin": 75000,
   "SalaryMax": 120000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "59",
   "JourneyType": "Professional",
   "Category": "Information Technology",
   "Position": "Senior Data Analyst",
   "Skills": ["Design","IT"],
   "Timeframe": "6-15 years",
   "SalaryMin": 75000,
   "SalaryMax": 120000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "60",
   "JourneyType": "Professional",
   "Category": "Information Technology",
   "Position": "Senior Software Developer",
   "Skills": ["Design","IT"],
   "Timeframe": "6-15 years",
   "SalaryMin": 75000,
   "SalaryMax": 120000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "61",
   "JourneyType": "Professional",
   "Category": "Information Technology",
   "Position": "Senior Software Architect",
   "Skills": ["Design","IT"],
   "Timeframe": "6-15 years",
   "SalaryMin": 75000,
   "SalaryMax": 120000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "62",
   "JourneyType": "Professional",
   "Category": "Specialist",
   "Position": "Specialist",
   "Skills": [""],
   "Timeframe": "16+ years",
   "SalaryMin": 50000,
   "SalaryMax": 80000,
   "Description": "Based on production needs, skills, abilities and qualifications each trade has various specialist job functions, such as Fiber Optics Specialist, Crane Operator Specialist, Pipe Fitter Specialist, Nuclear Machinist Specialist, Shipwright Optical/Measurement Specialist, Machinist Specialist, etc.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 20000
 },
 {
   "PositionId": "63",
   "JourneyType": "Professional",
   "Category": "Management",
   "Position": "Senior Management",
   "Skills": ["Supervising","Organization"],
   "Timeframe": "16+ years",
   "SalaryMin": 80000,
   "SalaryMax": 200000,
   "Description": "Managers are like mothers: Everybody has one. For example rock stars answer to record labels, politicians are responsible to the public and even CEOs report to Boards of Directors. Regardless of age, occupation or industry, everyone reports to someone. Oversees and administers efficient operations by providing adequate staff, materials, equipment and training ",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 20000
 },
 {
   "PositionId": "64",
   "JourneyType": "Professional",
   "Category": "Principal Engineer",
   "Position": "Principal Engineer",
   "Skills": ["Problem-solving"],
   "Timeframe": "16+ years",
   "SalaryMin": 90000,
   "SalaryMax": 140000,
   "Description": "Serves as a company expert in an engineering discipline. Provides guidance and oversight to resolve unique/complex problems. These professionals are the big dogs and mama birds of the engineering world-their knowledge is utilized to support and direct their team.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 20000
 },
 {
   "PositionId": "65",
   "JourneyType": "College",
   "Category": "Skilled Trades",
   "Position": "Associate's Degree",
   "Skills": [""],
   "Timeframe": "0-5 years",
   "SalaryMin": null,
   "SalaryMax": null,
   "Description": "",
   "EducationRequirement": "2 years",
   "Cost": "45000",
   "Benefits": 10000
 },
 {
   "PositionId": "69",
   "JourneyType": "College",
   "Category": "Skilled Trades",
   "Position": "Entry Marine Welder",
   "Skills": ["Hand-eye coordination","detail-oriented"],
   "Timeframe": "0-5 years",
   "SalaryMin": 30000,
   "SalaryMax": 55000,
   "Description": "Permanently joins metal parts of a ship using intense heat.  Successful welders have proficient hand-eye coordination and are detailed oriented.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed":['<iframe width="560" height="315" src="https://www.youtube.com/embed/jRr6RqMhogI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                 '<iframe width="560" height="315" src="https://www.youtube.com/embed/1za-7qiYO18" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "70",
   "JourneyType": "College",
   "Category": "Skilled Trades",
   "Position": "Entry Deck Electrician",
   "Skills": ["Electrical"],
   "Timeframe": "0-5 years",
   "SalaryMin": 30000,
   "SalaryMax": 55000,
   "Description": "Installs, repairs, and tests electrical systems aboard a ship (lighting, power, distribution, communication or navigation). This career is in high demand as more electrical systems are being installed on ships.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/nDwSDNd6xN8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                  '<iframe width="560" height="315" src="https://www.youtube.com/embed/xCRxOKUr8yM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "71",
   "JourneyType": "College",
   "Category": "Skilled Trades",
   "Position": "Entry Pipefitter",
   "Skills": ["Strength","Problem-solving"],
   "Timeframe": "0-5 years",
   "SalaryMin": 30000,
   "SalaryMax": 55000,
   "Description": "Fits and installs piping systems. Like a plumber, this career requires physical strength and stamina in addition to problem-solving skills.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/xaBSJVEW070" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "72",
   "JourneyType": "College",
   "Category": "Skilled Trades",
   "Position": "Entry Shipfitter",
   "Skills": ["Detail","Measurement"],
   "Timeframe": "0-5 years",
   "SalaryMin": 30000,
   "SalaryMax": 55000,
   "Description": "Similar to a carpenter and framer in home construction, assembles and erects metal plates to make the structure of a ship. This skilled position requires attention to detail and good measurement skills.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/9mxKfSF2U9k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "73",
   "JourneyType": "College",
   "Category": "Skilled Trades",
   "Position": "Entry Machinist",
   "Skills": ["Multi-task","Machinery"],
   "Timeframe": "0-5 years",
   "SalaryMin": 30000,
   "SalaryMax": 55000,
   "Description": "Uses computer-controlled or mechanically-based equipment to manufacture products. This career requires the ability to multi-task, proper understanding of machinery and job specifications to ensure high quality work.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed": ['<iframe src="https://player.vimeo.com/video/430752393?h=eac3451ade" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "74",
   "JourneyType": "College",
   "Category": "Skilled Trades",
   "Position": "Entry Marine Painter",
   "Skills": ["Painting"],
   "Timeframe": "0-5 years",
   "SalaryMin": 30000,
   "SalaryMax": 55000,
   "Description": "Skilled in the craft of covering various vessels surfaces, both interior and exterior, with protective coatings using brushes, spray guns and rollers to avoid rust/corrosion of underwater portions of vessels.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/qOW2VX9-vDY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                  '<iframe src="https://player.vimeo.com/video/430751776?h=03c1c24f75" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "75",
   "JourneyType": "College",
   "Category": "Trades Support",
   "Position": "Entry Materials Support",
   "Skills": ["Math","Documentation"],
   "Timeframe": "0-5 years",
   "SalaryMin": 27000,
   "SalaryMax": 42000,
   "Description": "Issues and controls production schedules and material flow in the shipyard. Basic math skills and documentation abilities are essential in this career.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "76",
   "JourneyType": "College",
   "Category": "Trades Support",
   "Position": "Entry Facilities Maintenance",
   "Skills": ["Problem-solving"],
   "Timeframe": "0-5 years",
   "SalaryMin": 27000,
   "SalaryMax": 42000,
   "Description": "Maintains and operates facilities equipment/machinery. Excellent problem-problem-solving and critical thinking skills are important in this position. ​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "77",
   "JourneyType": "College",
   "Category": "Trades Support",
   "Position": "Entry Tool/Drawing Clerks",
   "Skills": ["Detail"],
   "Timeframe": "0-5 years",
   "SalaryMin": 27000,
   "SalaryMax": 42000,
   "Description": "Retrieves data, issues and controls tools, drawings, consumable materials and safety equipment. Important skills for this position include: organization, attention to detail and working knowledge of design software.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "78",
   "JourneyType": "College",
   "Category": "Designer",
   "Position": "Designer",
   "Skills": ["Design"],
   "Timeframe": "0-5 years",
   "SalaryMin": 40000,
   "SalaryMax": 55000,
   "Description": "Utilizes computer aided design tools to create the digital twin of components or portions of systems for manufacturing and repair.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "79",
   "JourneyType": "College",
   "Category": "Information Technology",
   "Position": "Cybersecurity Analyst",
   "Skills": ["Design","IT"],
   "Timeframe": "0-5 years",
   "SalaryMin": 50000,
   "SalaryMax": 75000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "80",
   "JourneyType": "College",
   "Category": "Information Technology",
   "Position": "Data Analyst",
   "Skills": ["Design","IT"],
   "Timeframe": "0-5 years",
   "SalaryMin": 50000,
   "SalaryMax": 75000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "81",
   "JourneyType": "College",
   "Category": "Information Technology",
   "Position": "Software Developer",
   "Skills": ["Design","IT"],
   "Timeframe": "0-5 years",
   "SalaryMin": 50000,
   "SalaryMax": 75000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "82",
   "JourneyType": "College",
   "Category": "Information Technology",
   "Position": "Software Architect",
   "Skills": ["Design","IT"],
   "Timeframe": "0-5 years",
   "SalaryMin": 50000,
   "SalaryMax": 75000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "83",
   "JourneyType": "College",
   "Category": "Engineer",
   "Position": "Bachelor's Degree",
   "Skills": [""],
   "Timeframe": "0-5 years",
   "SalaryMin": null,
   "SalaryMax": null,
   "Description": "",
   "EducationRequirement": "4 years",
   "Cost": "120000",
   "Benefits": 10000
 },
 {
   "PositionId": "87",
   "JourneyType": "College",
   "Category": "Engineer",
   "Position": "Entry Engineer",
   "Skills": ["Problem-solving"],
   "Timeframe": "0-5 years",
   "SalaryMin": 50000,
   "SalaryMax": 70000,
   "Description": "Using math, science and problem-solving skills engineers design products and systems in various disciplines (i.e., electrical engineer, mechanical engineer, structural engineer, industrial engineer, etc.). ",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "88",
   "JourneyType": "College",
   "Category": "Business Analyst",
   "Position": "Entry Business Analyst",
   "Skills": ["Detail"],
   "Timeframe": "0-5 years",
   "SalaryMin": 40000,
   "SalaryMax": 60000,
   "Description": "Analyzes current business processes and works with business representatives to obtain future goals.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "89",
   "JourneyType": "College",
   "Category": "Marine Chemist",
   "Position": "Marine Chemist",
   "Skills": ["Detail"],
   "Timeframe": "0-5 years",
   "SalaryMin": 45000,
   "SalaryMax": 65000,
   "Description": "Analyzes and tests samples for environmental impacts and ship conditions.",
   "EducationRequirement": "Bachelor's Degree",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "90",
   "JourneyType": "College",
   "Category": "Naval Architect",
   "Position": "Naval Architect",
   "Skills": ["Design"],
   "Timeframe": "0-5 years",
   "SalaryMin": 50000,
   "SalaryMax": 75000,
   "Description": "Designs, builds and maintains a variety of ships. Works on the basic design of a ship, including the form and stability of hulls.",
   "EducationRequirement": "Bachelor's Degree",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "91",
   "JourneyType": "College",
   "Category": "Skilled Trades",
   "Position": "Marine Welder",
   "Skills": ["Hand-eye coordination","Detail"],
   "Timeframe": "6-15 years",
   "SalaryMin": 42000,
   "SalaryMax": 60000,
   "Description": "Permanently joins metal parts of a ship using intense heat.  Successful welders have proficient hand-eye coordination and are detailed oriented.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed":['<iframe width="560" height="315" src="https://www.youtube.com/embed/jRr6RqMhogI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                 '<iframe width="560" height="315" src="https://www.youtube.com/embed/1za-7qiYO18" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "92",
   "JourneyType": "College",
   "Category": "Skilled Trades",
   "Position": "Deck Electrician",
   "Skills": ["Electrical"],
   "Timeframe": "6-15 years",
   "SalaryMin": 42000,
   "SalaryMax": 60000,
   "Description": "Installs, repairs, and tests electrical systems aboard a ship (lighting, power, distribution, communication or navigation). This career is in high demand as more electrical systems are being installed on ships.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/nDwSDNd6xN8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                  '<iframe width="560" height="315" src="https://www.youtube.com/embed/xCRxOKUr8yM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "93",
   "JourneyType": "College",
   "Category": "Skilled Trades",
   "Position": "Pipefitter",
   "Skills": ["Strength","Problem-solving"],
   "Timeframe": "6-15 years",
   "SalaryMin": 42000,
   "SalaryMax": 60000,
   "Description": "Fits and installs piping systems. Like a plumber, this career requires physical strength and stamina in addition to problem-solving skills.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/xaBSJVEW070" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "94",
   "JourneyType": "College",
   "Category": "Skilled Trades",
   "Position": "Shipfitter",
   "Skills": ["Detail","Measurement"],
   "Timeframe": "6-15 years",
   "SalaryMin": 42000,
   "SalaryMax": 60000,
   "Description": "Similar to a carpenter and framer in home construction, assembles and erects metal plates to make the structure of a ship. This skilled position requires attention to detail and good measurement skills.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/9mxKfSF2U9k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "95",
   "JourneyType": "College",
   "Category": "Skilled Trades",
   "Position": "Machinist",
   "Skills": ["Multi-task","Machinery"],
   "Timeframe": "6-15 years",
   "SalaryMin": 42000,
   "SalaryMax": 60000,
   "Description": "Uses computer-controlled or mechanically-based equipment to manufacture products. This career requires the ability to multi-task, proper understanding of machinery and job specifications to ensure high quality work.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed": ['<iframe src="https://player.vimeo.com/video/430752393?h=eac3451ade" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "96",
   "JourneyType": "College",
   "Category": "Skilled Trades",
   "Position": "Marine Painter",
   "Skills": ["Painting"],
   "Timeframe": "6-15 years",
   "SalaryMin": 42000,
   "SalaryMax": 60000,
   "Description": "Skilled in the craft of covering various vessels surfaces, both interior and exterior, with protective coatings using brushes, spray guns and rollers to avoid rust/corrosion of underwater portions of vessels.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/qOW2VX9-vDY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                  '<iframe src="https://player.vimeo.com/video/430751776?h=03c1c24f75" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "97",
   "JourneyType": "College",
   "Category": "Management",
   "Position": "Management",
   "Skills": ["Supervising","Organization"],
   "Timeframe": "6-15 years",
   "SalaryMin": 40000,
   "SalaryMax": 125000,
   "Description": "Oversees and administer efficient operations by providing adequate staff, materials, equipment and training. Managers are like mothers: Everybody has one. For example rock stars answer to record labels, politicians are responsible to the public and even CEOs report to Boards of Directions. Indeed, regardless of age, occupation or industry, everyone reports to someone.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "98",
   "JourneyType": "College",
   "Category": "Designer",
   "Position": "Senior Designer",
   "Skills": ["Design"],
   "Timeframe": "6-15 years",
   "SalaryMin": 55000,
   "SalaryMax": 90000,
   "Description": "Utilizes computer aided design tools to create the digital twin of components or portions of systems for manufacturing and repair.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "99",
   "JourneyType": "College",
   "Category": "Business Analyst",
   "Position": "Senior Business Analyst",
   "Skills": ["Detail"],
   "Timeframe": "6-15 years",
   "SalaryMin": 75000,
   "SalaryMax": 110000,
   "Description": "Analyzes current business processes and works with business representatives to obtain future goals.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "100",
   "JourneyType": "College",
   "Category": "Contracts Analyst",
   "Position": "Senior Contracts Analyst",
   "Skills": ["Detail"],
   "Timeframe": "6-15 years",
   "SalaryMin": 60000,
   "SalaryMax": 100000,
   "Description": "Examines estimates of material, equipment services, production costs, performance requirements, and delivery schedules to ensure accuracy and completeness. Coordination and management of proposals, contracts, subcontracts and purchase orders.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "101",
   "JourneyType": "College",
   "Category": "Information Technology",
   "Position": "Senior Cybersecurity Analyst",
   "Skills": ["Design","IT"],
   "Timeframe": "6-15 years",
   "SalaryMin": 75000,
   "SalaryMax": 120000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "102",
   "JourneyType": "College",
   "Category": "Information Technology",
   "Position": "Senior Data Analyst",
   "Skills": ["Design","IT"],
   "Timeframe": "6-15 years",
   "SalaryMin": 75000,
   "SalaryMax": 120000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "103",
   "JourneyType": "College",
   "Category": "Information Technology",
   "Position": "Senior Software Developer",
   "Skills": ["Design","IT"],
   "Timeframe": "6-15 years",
   "SalaryMin": 75000,
   "SalaryMax": 120000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "104",
   "JourneyType": "College",
   "Category": "Information Technology",
   "Position": "Senior Software Architect",
   "Skills": ["Design","IT"],
   "Timeframe": "6-15 years",
   "SalaryMin": 75000,
   "SalaryMax": 120000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "105",
   "JourneyType": "College",
   "Category": "Training Instructor",
   "Position": "Master's Degree",
   "Skills": [""],
   "Timeframe": "6-15 years",
   "SalaryMin": null,
   "SalaryMax": null,
   "Description": "",
   "EducationRequirement": "5-10 years",
   "Cost": "45000-200000",
   "Benefits": 15000
 },
 {
   "PositionId": "108",
   "JourneyType": "College",
   "Category": "Training Instructor",
   "Position": "Training Instructor",
   "Skills": ["Instructor"],
   "Timeframe": "6-15 years",
   "SalaryMin": 40000,
   "SalaryMax": 70000,
   "Description": "Designs and delivers programs to train all levels of personnel. Develops tests and visual aids, conducts training classes, and develops criteria for evaluating effectiveness of training activities.",
   "EducationRequirement": "Master's Degree",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "109",
   "JourneyType": "College",
   "Category": "Data Engineer",
   "Position": "Data Engineer",
   "Skills": ["Math","Problem-solving"],
   "Timeframe": "6-15 years",
   "SalaryMin": 45000,
   "SalaryMax": 75000,
   "Description": "Designs, builds and manages information or “big data” infrastructure. Create architecture that helps analyze and process data according to organizational needs, systems performance and accountable for gathering, collecting, storing and real-time processing.",
   "EducationRequirement": "Master's Degree",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "110",
   "JourneyType": "College",
   "Category": "Data Scientist",
   "Position": "Data Scientist",
   "Skills": ["Math","Problem-solving","Detail"],
   "Timeframe": "6-15 years",
   "SalaryMin": 75000,
   "SalaryMax": 125000,
   "Description": "Analyzes and streamlines information for interpretation. Utilizes a variety of data mining/data analysis methods, uses a variety of tools, builds and implements models, creates algorithms and runs simulations.",
   "EducationRequirement": "PhD",
   "Cost": "0",
   "Benefits": 20000
 },
 {
   "PositionId": "111",
   "JourneyType": "College",
   "Category": "Specialist",
   "Position": "Specialist",
   "Skills": [""],
   "Timeframe": "16+ years",
   "SalaryMin": 50000,
   "SalaryMax": 80000,
   "Description": "Based on production needs, skills, abilities and qualifications each trade has various specialist job functions, such as Fiber Optics Specialist, Crane Operator Specialist, Pipe Fitter Specialist, Nuclear Machinist Specialist, Shipwright Optical/Measurement Specialist, Machinist Specialist, etc.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 20000
 },
 {
   "PositionId": "112",
   "JourneyType": "College",
   "Category": "Management",
   "Position": "Senior Management",
   "Skills": ["Supervising","Organization"],
   "Timeframe": "16+ years",
   "SalaryMin": 80000,
   "SalaryMax": 200000,
   "Description": "Oversees and administer efficient operations by providing adequate staff, materials, equipment and training. Managers are like mothers: Everybody has one. For example rock stars answer to record labels, politicians are responsible to the public and even CEOs report to Boards of Directions. Indeed, regardless of age, occupation or industry, everyone reports to someone.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 20000
 },
 {
   "PositionId": "113",
   "JourneyType": "College",
   "Category": "Principal Engineer",
   "Position": "Principal Engineer",
   "Skills": ["Problem-solving"],
   "Timeframe": "16+ years",
   "SalaryMin": 90000,
   "SalaryMax": 140000,
   "Description": "Serves as a company expert in an engineering discipline. Provides guidance and oversight to resolve unique/complex problems. These professionals are the big dogs and mama birds of the engineering world-their knowledge is utilized to support and direct their team.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 20000
 },
 {
   "PositionId": "114",
   "JourneyType": "Veteran",
   "Category": "Skilled Trades",
   "Position": "Entry Marine Welder",
   "Skills": ["Hand-eye coordination","detail-oriented"],
   "Timeframe": "0-5 years",
   "SalaryMin": 32500,
   "SalaryMax": 55000,
   "Description": "Permanently joins metal parts of a ship using intense heat.  Successful welders have proficient hand-eye coordination and are detailed oriented.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed":['<iframe width="560" height="315" src="https://www.youtube.com/embed/jRr6RqMhogI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                 '<iframe width="560" height="315" src="https://www.youtube.com/embed/1za-7qiYO18" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "115",
   "JourneyType": "Veteran",
   "Category": "Skilled Trades",
   "Position": "Entry Deck Electrician",
   "Skills": ["Electrical"],
   "Timeframe": "0-5 years",
   "SalaryMin": 32500,
   "SalaryMax": 55000,
   "Description": "Installs, repairs, and tests electrical systems aboard a ship (lighting, power, distribution, communication or navigation). This career is in high demand as more electrical systems are being installed on ships.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/nDwSDNd6xN8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                  '<iframe width="560" height="315" src="https://www.youtube.com/embed/xCRxOKUr8yM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "116",
   "JourneyType": "Veteran",
   "Category": "Skilled Trades",
   "Position": "Entry Pipefitter",
   "Skills": ["Strength","Problem-solving"],
   "Timeframe": "0-5 years",
   "SalaryMin": 32500,
   "SalaryMax": 55000,
   "Description": "Fits and installs piping systems. Like a plumber, this career requires physical strength and stamina in addition to problem-solving skills.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/xaBSJVEW070" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "117",
   "JourneyType": "Veteran",
   "Category": "Skilled Trades",
   "Position": "Entry Shipfitter",
   "Skills": ["Detail","Measurement"],
   "Timeframe": "0-5 years",
   "SalaryMin": 32500,
   "SalaryMax": 55000,
   "Description": "Similar to a carpenter and framer in home construction, assembles and erects metal plates to make the structure of a ship. This skilled position requires attention to detail and good measurement skills.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/9mxKfSF2U9k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "118",
   "JourneyType": "Veteran",
   "Category": "Skilled Trades",
   "Position": "Entry Machinist",
   "Skills": ["Multi-task","Machinery"],
   "Timeframe": "0-5 years",
   "SalaryMin": 32500,
   "SalaryMax": 55000,
   "Description": "Uses computer-controlled or mechanically-based equipment to manufacture products. This career requires the ability to multi-task, proper understanding of machinery and job specifications to ensure high quality work.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed": ['<iframe src="https://player.vimeo.com/video/430752393?h=eac3451ade" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "119",
   "JourneyType": "Veteran",
   "Category": "Skilled Trades",
   "Position": "Entry Marine Painter",
   "Skills": ["Painting"],
   "Timeframe": "0-5 years",
   "SalaryMin": 32500,
   "SalaryMax": 55000,
   "Description": "Skilled in the craft of covering various vessels surfaces, both interior and exterior, with protective coatings using brushes, spray guns and rollers to avoid rust/corrosion of underwater portions of vessels.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/qOW2VX9-vDY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                  '<iframe src="https://player.vimeo.com/video/430751776?h=03c1c24f75" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "120",
   "JourneyType": "Veteran",
   "Category": "Designer",
   "Position": "Entry Designer",
   "Skills": ["Design"],
   "Timeframe": "0-5 years",
   "SalaryMin": 42500,
   "SalaryMax": 55000,
   "Description": "Utilizes computer aided design tools to create the digital twin of components or portions of systems for manufacturing and repair.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "121",
   "JourneyType": "Veteran",
   "Category": "Engineer",
   "Position": "Entry Engineer",
   "Skills": ["Problem-solving"],
   "Timeframe": "0-5 years",
   "SalaryMin": 52500,
   "SalaryMax": 70000,
   "Description": "Using math, science and problem-solving skills engineers design products and systems in various disciplines (i.e., electrical engineer, mechanical engineer, structural engineer, industrial engineer, etc.). ",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "122",
   "JourneyType": "Veteran",
   "Category": "Planner",
   "Position": "Entry Planner",
   "Skills": ["Planning"],
   "Timeframe": "0-5 years",
   "SalaryMin": 27500,
   "SalaryMax": 50000,
   "Description": "Plans, prepares, issues and controls production schedules and coordinates with material requirements to ensure a controlled flow of approved materials timed to meet production requirements.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "123",
   "JourneyType": "Veteran",
   "Category": "Business Analyst",
   "Position": "Entry Business Analyst",
   "Skills": ["Detail"],
   "Timeframe": "0-5 years",
   "SalaryMin": 42500,
   "SalaryMax": 60000,
   "Description": "Analyzes current business processes and works with business representatives to obtain future goals.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "124",
   "JourneyType": "Veteran",
   "Category": "Contracts Analyst",
   "Position": "Entry Contracts Analyst",
   "Skills": ["Detail"],
   "Timeframe": "0-5 years",
   "SalaryMin": 42500,
   "SalaryMax": 60000,
   "Description": "Examines estimates of material, equipment services, production costs, performance requirements, and delivery schedules to ensure accuracy and completeness. Coordination and management of proposals, contracts, subcontracts and purchase orders.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "125",
   "JourneyType": "Veteran",
   "Category": "Information Technology",
   "Position": "Cybersecurity Analyst",
   "Skills": ["Design","IT"],
   "Timeframe": "0-5 years",
   "SalaryMin": 52500,
   "SalaryMax": 75000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "126",
   "JourneyType": "Veteran",
   "Category": "Information Technology",
   "Position": "Data Analyst",
   "Skills": ["Design","IT"],
   "Timeframe": "0-5 years",
   "SalaryMin": 52500,
   "SalaryMax": 75000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "127",
   "JourneyType": "Veteran",
   "Category": "Information Technology",
   "Position": "Software Developer",
   "Skills": ["Design","IT"],
   "Timeframe": "0-5 years",
   "SalaryMin": 52500,
   "SalaryMax": 75000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "128",
   "JourneyType": "Veteran",
   "Category": "Information Technology",
   "Position": "Software Architect",
   "Skills": ["Design","IT"],
   "Timeframe": "0-5 years",
   "SalaryMin": 52500,
   "SalaryMax": 75000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "129",
   "JourneyType": "Veteran",
   "Category": "Administration/Support",
   "Position": "Entry Administration/Support",
   "Skills": ["Detail"],
   "Timeframe": "0-5 years",
   "SalaryMin": 27500,
   "SalaryMax": 40000,
   "Description": "Compiles, reviews and analyzes data in support of functional areas. Gathers, collects, records, tracks and verifies data and information from multiple sources.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 10000
 },
 {
   "PositionId": "130",
   "JourneyType": "Veteran",
   "Category": "Apprentice School",
   "Position": "Apprentice School",
   "Skills": [""],
   "Timeframe": "0-5 years",
   "SalaryMin": 39500,
   "SalaryMax": 60000,
   "Description": "Apprentice Programs are typically 4-5 years. While working and receiving salary and benefits, students graduate with a certificate of apprenticeship, Associate’s degree and/or Bachelor’s degree.",
   "EducationRequirement": "4-5 years",
   "Cost": "Application Fee",
   "Benefits": 10000
 },
 {
   "PositionId": "131",
   "JourneyType": "Veteran",
   "Category": "Skilled Trades",
   "Position": "Marine Welder",
   "Skills": ["Hand-eye coordination","Detail"],
   "Timeframe": "6-15 years",
   "SalaryMin": 44500,
   "SalaryMax": 60000,
   "Description": "Permanently joins metal parts of a ship using intense heat.  Successful welders have proficient hand-eye coordination and are detailed oriented.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed":['<iframe width="560" height="315" src="https://www.youtube.com/embed/jRr6RqMhogI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                 '<iframe width="560" height="315" src="https://www.youtube.com/embed/1za-7qiYO18" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "132",
   "JourneyType": "Veteran",
   "Category": "Skilled Trades",
   "Position": "Deck Electrician",
   "Skills": ["Electrical"],
   "Timeframe": "6-15 years",
   "SalaryMin": 44500,
   "SalaryMax": 60000,
   "Description": "Installs, repairs, and tests electrical systems aboard a ship (lighting, power, distribution, communication or navigation). This career is in high demand as more electrical systems are being installed on ships.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/nDwSDNd6xN8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                  '<iframe width="560" height="315" src="https://www.youtube.com/embed/xCRxOKUr8yM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "133",
   "JourneyType": "Veteran",
   "Category": "Skilled Trades",
   "Position": "Pipefitter",
   "Skills": ["Strength","Problem-solving"],
   "Timeframe": "6-15 years",
   "SalaryMin": 44500,
   "SalaryMax": 60000,
   "Description": "Fits and installs piping systems. Like a plumber, this career requires physical strength and stamina in addition to problem-solving skills.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/xaBSJVEW070" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "134",
   "JourneyType": "Veteran",
   "Category": "Skilled Trades",
   "Position": "Shipfitter",
   "Skills": ["Detail","Measurement"],
   "Timeframe": "6-15 years",
   "SalaryMin": 44500,
   "SalaryMax": 60000,
   "Description": "Similar to a carpenter and framer in home construction, assembles and erects metal plates to make the structure of a ship. This skilled position requires attention to detail and good measurement skills.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/9mxKfSF2U9k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "135",
   "JourneyType": "Veteran",
   "Category": "Skilled Trades",
   "Position": "Machinist",
   "Skills": ["Multi-task","Machinery"],
   "Timeframe": "6-15 years",
   "SalaryMin": 44500,
   "SalaryMax": 60000,
   "Description": "Uses computer-controlled or mechanically-based equipment to manufacture products. This career requires the ability to multi-task, proper understanding of machinery and job specifications to ensure high quality work.​",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed": ['<iframe src="https://player.vimeo.com/video/430752393?h=eac3451ade" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "136",
   "JourneyType": "Veteran",
   "Category": "Skilled Trades",
   "Position": "Marine Painter",
   "Skills": ["Painting"],
   "Timeframe": "6-15 years",
   "SalaryMin": 44500,
   "SalaryMax": 60000,
   "Description": "Skilled in the craft of covering various vessels surfaces, both interior and exterior, with protective coatings using brushes, spray guns and rollers to avoid rust/corrosion of underwater portions of vessels.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000,
   "VideoEmbed": ['<iframe width="560" height="315" src="https://www.youtube.com/embed/qOW2VX9-vDY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
                  '<iframe src="https://player.vimeo.com/video/430751776?h=03c1c24f75" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>']
 },
 {
   "PositionId": "137",
   "JourneyType": "Veteran",
   "Category": "Management",
   "Position": "Management",
   "Skills": ["Supervising","Organization"],
   "Timeframe": "6-15 years",
   "SalaryMin": 42500,
   "SalaryMax": 85000,
   "Description": "Managers are like mothers: Everybody has one. For example rock stars answer to record labels, politicians are responsible to the public and even CEOs report to Boards of Directors. Regardless of age, occupation or industry, everyone reports to someone. Oversees and administers efficient operations by providing adequate staff, materials, equipment and training",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "138",
   "JourneyType": "Veteran",
   "Category": "Designer",
   "Position": "Senior Designer",
   "Skills": ["Design"],
   "Timeframe": "6-15 years",
   "SalaryMin": 57500,
   "SalaryMax": 90000,
   "Description": "Utilizes computer aided design tools to create the digital twin of components or portions of systems for manufacturing and repair.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "139",
   "JourneyType": "Veteran",
   "Category": "Engineer",
   "Position": "Senior Engineer",
   "Skills": ["Problem-solving"],
   "Timeframe": "6-15 years",
   "SalaryMin": 72500,
   "SalaryMax": 100000,
   "Description": "Using math, science and problem-solving skills engineers design products and systems in various disciplines (i.e., electrical engineer, mechanical engineer, structural engineer, industrial engineer, etc.). ",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "140",
   "JourneyType": "Veteran",
   "Category": "Business Analyst",
   "Position": "Senior Business Analyst",
   "Skills": ["Detail"],
   "Timeframe": "6-15 years",
   "SalaryMin": 77500,
   "SalaryMax": 125000,
   "Description": "Analyzes current business processes and works with business representatives to obtain future goals.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "141",
   "JourneyType": "Veteran",
   "Category": "Contracts Analyst",
   "Position": "Senior Contracts Analyst",
   "Skills": ["Detail"],
   "Timeframe": "6-15 years",
   "SalaryMin": 62500,
   "SalaryMax": 100000,
   "Description": "Examines estimates of material, equipment services, production costs, performance requirements, and delivery schedules to ensure accuracy and completeness. Coordination and management of proposals, contracts, subcontracts and purchase orders.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "142",
   "JourneyType": "Veteran",
   "Category": "Information Technology",
   "Position": "Senior Cybersecurity Analyst",
   "Skills": ["Design","IT"],
   "Timeframe": "6-15 years",
   "SalaryMin": 77500,
   "SalaryMax": 120000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "143",
   "JourneyType": "Veteran",
   "Category": "Information Technology",
   "Position": "Senior Data Analyst",
   "Skills": ["Design","IT"],
   "Timeframe": "6-15 years",
   "SalaryMin": 77500,
   "SalaryMax": 120000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "144",
   "JourneyType": "Veteran",
   "Category": "Information Technology",
   "Position": "Senior Software Developer",
   "Skills": ["Design","IT"],
   "Timeframe": "6-15 years",
   "SalaryMin": 77500,
   "SalaryMax": 120000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "145",
   "JourneyType": "Veteran",
   "Category": "Information Technology",
   "Position": "Senior Software Architect",
   "Skills": ["Design","IT"],
   "Timeframe": "6-15 years",
   "SalaryMin": 77500,
   "SalaryMax": 120000,
   "Description": "Designs and develops solutions to complex applications problems, system administration issues, or network concerns. Performs systems management and integration functions. Once network systems go live, you stick around to de-kink any knotty problems and navigate new features.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 15000
 },
 {
   "PositionId": "146",
   "JourneyType": "Veteran",
   "Category": "Specialist",
   "Position": "Specialist",
   "Skills": [""],
   "Timeframe": "16+ years",
   "SalaryMin": 82500,
   "SalaryMax": 150000,
   "Description": "Based on production needs, skills, abilities and qualifications each trade has various specialist job functions, such as Fiber Optics Specialist, Crane Operator Specialist, Pipe Fitter Specialist, Nuclear Machinist Specialist, Shipwright Optical/Measurement Specialist, Machinist Specialist, etc.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 20000
 },
 {
   "PositionId": "147",
   "JourneyType": "Veteran",
   "Category": "Management",
   "Position": "Senior Management",
   "Skills": ["Supervising","Organization"],
   "Timeframe": "16+ years",
   "SalaryMin": 82500,
   "SalaryMax": 200000,
   "Description": "Oversees and administer efficient operations by providing adequate staff, materials, equipment and training. Managers are like mothers: Everybody has one. For example rock stars answer to record labels, politicians are responsible to the public and even CEOs report to Boards of Directions. Indeed, regardless of age, occupation or industry, everyone reports to someone.",
   "EducationRequirement": "None",
   "Cost": "0",
   "Benefits": 20000
 }
]

export default positions