import {toDollars} from "../assets/dataUtils"

const JourneyItem = ({position, color}) => {
    let salary = ''
    if (toDollars(position.SalaryMin) && toDollars(position.SalaryMax)) {
        salary = "$" + toDollars(position.SalaryMin) + "-$" + toDollars(position.SalaryMax) + "/year"
    } else {
        salary = "Salary not listed"
    }

    return (
        <div className={"card card-secondary"}>
            <div className="journey-item">
                <div className={"journey-item-top row"}>
                    <div className={"col-6"}>
                        <div className={"btn-success"} style={{background: color}}>
                                {position.Position}
                        </div>
                    </div>
                    <div className={"col-6"}>
                        <b>Salary Range: </b>
                        <p>{salary}</p>
                    </div>
                </div>
                <div className={"journey-item-bottom"}>
                    <b>Description: </b>
                    <p>{position.Description}</p>
                </div>
            </div>
        </div>
    );
};

export default JourneyItem;