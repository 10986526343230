import positions from "../assets/positions";
import {useLocation} from "react-router-dom";
import queryString from "query-string";

const JourneyCurrent = ({goalPosition, journeyItems, colors, exportJourney}) => {
    const {search} = useLocation()
    const {name, edu, goal, skills} = queryString.parse(search)

    let acquiredSkills = []
    let acquiredExperience = []
    let positionNames = []
    journeyItems.forEach((index) => {
        const position = positions.find(x => x.PositionId === index)

        if (!(position.Skills.includes(''))){
            acquiredSkills = acquiredSkills.concat(position.Skills)
        }
        acquiredExperience.push(position["Timeframe"])
        positionNames.push(position["Position"])
    })

    acquiredSkills = [...new Set(acquiredSkills)]

    let isMaxed = false

    function calculateExperience() {
        let totalExperience = 0
        acquiredExperience.forEach((timeframe) => {
            switch (timeframe) {
                case "16+ years":
                    totalExperience += 16
                    isMaxed = true
                    break
                case "6-15 years":
                    totalExperience += 15
                    break
                default:
                    totalExperience += 5
                    break
            }
        })

        if (isMaxed) {
            return totalExperience + "+"
        } else {
            return totalExperience
        }
    }

    let journeyType
    // converting the edu parameter into text to use for the page directions
    switch (edu) {
        case "VET":
            journeyType = "Veteran"
            break
        case "PRO":
            journeyType = "Maritime Professional"
            break
        case "CG":
            journeyType = "College Graduate"
            break
        default:
            journeyType = "High school student/Graduate"
    }

    const btnWidth = (731 / (journeyItems.length + 2) - 50).toString() + "px"
    const arrWidth = ((731 / (journeyItems.length + 2) - 50) / 2).toString() + "px"

    return (
        <div className={"printout-page"} id={"userJourneyHTML"}>
            <div className={"printout-header"}>
                <img src={"Color.png"}
                     style={{"height": "70px", "float": "left", "marginLeft": "40px", "marginTop": "10px"}}/>
                <h3 className={"printout-header-text"}
                    style={{"fontFamily": "'Oswald', sans-serif font-size"}}>
                    <b>
                        {name}'s Career Development Plan
                    </b>
                </h3>
            </div>
            <div className={"card printout-content"}>
                <div className={"card-body"} style={{"height": "100%"}}>
                    <p><u>Current Career:</u> {journeyType}</p>
                    <p><u>Goal Career:</u> {goalPosition.Position}</p>
                    <p><u>Description:</u> {goalPosition.Description}</p>

                    <p style={{"marginLeft": "190px", "marginTop": "80px"}}>When you reach your Goal Career, you will
                        have:</p>

                    <div className={"row justify-content-center"} style={{"height": "200px"}}>
                        <div className={"col-4"}>
                            <div className={"card printout-result-card"}>
                                <p className={"printout-result-text-title"}><u>Skills</u></p>
                                <ul>
                                    {acquiredSkills.map((skill) => (
                                        <li>
                                            {skill}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className={"col-4"}>
                            <div className={"card printout-result-card"}>
                                <p className={"printout-result-text-title"} style={{"marginLeft": "62px"}}>
                                    <u>Education</u>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={"row justify-content-center"}
                         style={{"height": "200px", "marginTop": "40px", "marginBottom": "70px"}}>
                        <div className={"col-4"}>
                            <div className={"card printout-result-card"}>
                                <p className={"printout-result-text-title"} style={{"marginLeft": "60px"}}>
                                    <u>Experience</u></p>
                                <ul>
                                    <li>{calculateExperience()} Years</li>
                                </ul>
                            </div>
                        </div>
                        <div className={"col-4"}>
                            <div className={"card printout-result-card"}>
                                <p className={"printout-result-text-title"} style={{"marginLeft": "50px"}}>
                                    <u>Certifications</u></p>
                            </div>
                        </div>
                    </div>
                    <div className={"row printout-button-footer justify-content-between"}>
                        <div className={"col"}>
                            <button className={"btn printout-button"} style={{
                                "background":"white",
                                "width": btnWidth,
                                "display": "inline-block",
                                "fontSize": "12px",
                                "borderColor": "black",
                                "borderWidth": "3px",
                                "marginLeft":"15px"
                            }}><b>{name}</b></button>
                            {
                                positionNames.map((name, index) => (
                                    <>
                                        {
                                            index === 0 &&
                                            <>
                                                <i className="fas fa-arrow-right"
                                                   style={{"width": arrWidth, "marginLeft": arrWidth}}></i>
                                            </>
                                        }
                                        <button className={"btn printout-button"} style={{
                                            "background": colors[index],
                                            "width": btnWidth,
                                            "display": "inline-block",
                                            "fontSize": "12px",
                                            "borderColor": "black",
                                            "borderWidth": "3px"
                                        }}><b>{name}</b></button>
                                        {
                                            index < journeyItems.length - 1 &&
                                            <>
                                                <i className="fas fa-arrow-right"
                                                   style={{"width": arrWidth, "marginLeft": arrWidth}}></i>
                                            </>
                                        }
                                    </>
                                ))
                            }
                        </div>
                    </div>
                    <div className={"row justify-content-center"}>
                        <div className={"col"}>
                            <button onClick={exportJourney} className={"btn download-button"}><b>Download My Journey</b> <i
                                className="fas fa-download"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JourneyCurrent;