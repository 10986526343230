import React, {useEffect, useState, useRef} from "react";
import {MapContainer, Marker, TileLayer, Popup, Circle} from "react-leaflet";
import locationsMark from '../assets/shipyardLocations';
import Background from "./Background";
import Header from "./Header";
import Footer from "./Footer";

const Resources = () => {
    const apiKey = "cbf6f6c87bc2837e24259f5b67e7b267"
    const pointData = locationsMark
    const [keyword, setKeyWord] = useState("");
    const [map, setMap] = useState();
    const [error, setError] = useState(null);
    const [userMarker, setUserMarker] = useState(null);

    function fetchingHelper() {
        const myUrl = new URL("http://api.positionstack.com/v1/forward")
        myUrl.searchParams.append("access_key", apiKey)
        myUrl.searchParams.append("query", keyword)

        fetch(myUrl.href)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error || !result.data[0]) {
                        setError("Error getting address");
                    } else {
                        const latitude = result.data[0].latitude;
                        const longitude = result.data[0].longitude;
                        const region = result.data[0].locality;

                        moveMapToPos(latitude, longitude, region);
                        setError(null);
                    }
                },
            )
    }

    function moveMapToPos(latitude, longitude, popupText) {
        map.flyTo([latitude, longitude], 6);
        setUserMarker(<Circle center={[latitude, longitude]} radius={40000} color="green"/>);
    }

    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((pos) => {
                console.log(pos);
                moveMapToPos(pos.coords.latitude, pos.coords.longitude, "You");
            });
        } else {
            setError("Cannot get location from browser.");
        }
    }

    const ShipyardLocations = () => {
        const markerList = [];
        for (let index = 1; index <= Object.keys(pointData).length; index++) {
            const isMaritimeSchool = pointData[index].ShipyardType === "Maritime School"
            markerList.push(<Marker
                key={pointData[index]}
                position={[pointData[index].Latitude, pointData[index].Longitude]}>
                <Popup>
                    <div className="shipyard-popup">
                    <div className="shipyard-popup-row">
                     <div className="shipyard-popup-key">Shipyard: </div>
                     <div className="shipyard-popup-val">
                     {pointData[index].ShipyardName}
                     {isMaritimeSchool && <span className="shipyard-maritime-school"> Maritime School </span>}
                     </div>
                    </div>
                    <div className="shipyard-popup-row">
                     <div className="shipyard-popup-key">Address: </div>
                     <div className="shipyard-popup-val">
                        {pointData[index].Address !== undefined ? pointData[index].Address : "Not available"}
                     </div>
                    </div>
                    <div className="shipyard-popup-row">
                     <div className="shipyard-popup-key">Phone: </div>
                     <div className="shipyard-popup-val">
                        {pointData[index].Phone !== undefined ? pointData[index].Phone : "Not available"}
                     </div>
                    </div>
                    </div>
                </Popup>
            </Marker>)
        }
        return markerList
    }

    return (
        <div className={"home box"}>
            <Background/>
            <Header/>
            <div className="resource-page-container">
                <div className='resource-page-info'>
                    On this page, find shipyards near you! Enter in your address, or click the <span className="location-character">&#8982;</span> button, to move
                    the
                    map to your area and see what shipyards are nearby.
                    Click on a pin to see more info about the shipyard.
                    Scroll below to see more video resources about maritime professions.
                </div>
                <div className={'row resource-input-row'} style={{width: '80vw'}}>
                    <div className={'col-auto no-padding'}><input
                        type="search"
                        value={keyword}
                        onChange={e => setKeyWord(e.target.value)}
                        placeholder={"Search Location"}
                        className={'form-control resource-input'}
                        style={{width: '300px'}}
                    /></div>
                    <div className={'col-auto resource-btween no-padding'}></div>
                    <div className={'col-auto no-padding'}>
                        <button className={'btn btn-secondary resource-search-btn'}
                                onClick={fetchingHelper}
                                style={{width: '150px', fontSize: '16px'}}>Search
                        </button>
                    </div>
                    <div className={'col-auto no-padding'} style={{marginLeft: '20px'}}>
                        <button onClick={getLocation}
                                className={'btn btn-secondary resource-target-btn'}>
                            <b>&#8982;</b></button>
                    </div>

                </div>
                <div className="map-container">
                    {error !== null && <p style={{width: '400px', color:'red', marginLeft: 'calc((100vw - 400px)/2)'}}>{error}</p>}
                    <MapContainer center={[39.8283, -98.5795]} zoom={2} whenCreated={setMap} id="map">
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <ShipyardLocations/>
                        {userMarker !== null && userMarker}
                    </MapContainer>
                </div>

                <div id='video-resources'>
                    <div className={"resource-one resource-link"}>
                        <h3>2 in 1 | Powerful Cardboard Tank With An Unusual Blaster</h3>
                        <a href="https://www.youtubekids.com/watch?v=RLmgYnb_oMo">Visit link here</a>
                    </div>

                    <br/>

                    <div className={"resource-two resource-link"}>
                        <h3>3D Printing with Sand and Glue</h3>
                        <a href="https://www.youtubekids.com/watch?v=OF5UTmlY4cY">Visit link here</a>
                    </div>

                    <br/>

                    <div className={"resource-three resource-link"}>
                        <h3>How Submarines Work | How Things Work with Kamri Noel</h3>
                        <a href="https://www.youtubekids.com/watch?v=Jo6rcffwQLc">Visit link here</a>
                    </div>

                    <br/>

                    <div className={"resource-four resource-link"}>
                        <h3>How 3D Printers Work | How Things Work with Kamri Noel</h3>
                        <a href="https://www.youtubekids.com/watch?v=HlvK6DLwCz4">Visit link here</a>
                    </div>

                    <br/>

                    <div className={"resource-five resource-link"}>
                        <h3>How To Make Recycle Homemade DIY ROBOT for Kids</h3>
                        <a href="https://www.youtubekids.com/watch?v=ugfVmsXp5sU">Visit link here</a>
                    </div>

                    <br/>

                    <div className={"resource-six resource-link"}>
                        <h3> LEGO BOOST Robot Unboxing - The Build Zone</h3>
                        <a href="https://www.youtubekids.com/watch?v=pxukTU_xHEE">Visit link here</a>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Resources;
