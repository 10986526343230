import {useState} from "react";
import positionStories from "../assets/positionStories";
import positions from "../assets/positions";
import $ from "jquery";

const CustomCarousel = ({selectedPosition, iconOrder, stories}) => {
    const [carouselIndex, setCarouselIndex] = useState(0)
    const position_name = (positions.find(x => x.PositionId === selectedPosition)).Position
    let numStories = stories.length

    const shuffledIconList = []
    iconOrder.forEach((num) => {
        shuffledIconList.push("Ellipse " + num + ".png")
    })

    function goBack() {
        let toIndex = carouselIndex - 1
        if (toIndex < 0) {
            toIndex += numStories
        }
        setCarouselIndex(toIndex)
    }

    function goForward() {
        let toIndex = carouselIndex + 1
        if (toIndex > numStories + 1) {
            toIndex = 0
        }

        setCarouselIndex(toIndex)
    }

    return (
        <div className={"popup-box"}>
            <div className={"carousel-custom card"}>
                <div className={"row carousel-title"}>
                    <h3>Journeys to {position_name}</h3>
                </div>
                {/*<div className={"carousel-background"}></div>*/}
                <div className={"card-body carousel-content"}>
                    <div className={"row carousel-content-upper justify-content-center carousel-object"}>
                        <>
                            {
                                carouselIndex % (numStories + 1) === 0 &&
                                <>
                                    {shuffledIconList.slice(0, numStories).map((i) => (<img key={i} src={i}/>))}
                                </>
                            }
                            {
                                carouselIndex % (numStories + 1) !== 0 &&
                                <div className={"row story-info"}>
                                    <div className={"col-3 story-info"}>
                                        <img src={shuffledIconList[carouselIndex - 1]}
                                            className={"example-journey-person"}/>
                                    </div>
                                    <div className={"col-9 story-info"}>
                                        <b>{stories[carouselIndex - 1]["name"]}</b>,
                                        Age {stories[carouselIndex - 1]["age"]}
                                        <br/>
                                        <b>{stories[carouselIndex - 1]["company"]}</b>
                                    </div>
                                </div>
                            }
                        </>
                    </div>
                    <div className={"row carousel-content-description"}>
                        <>
                            {
                                carouselIndex % (numStories + 1) === 0 &&
                                <>
                                    <p>Every career pathway in the shipbuilding industry is a little bit different. The
                                        following narratives provide representative experiences based on journeys from
                                        the industry professionals who have achieved the types of goals you are
                                        targeting.</p>
                                    <p>Click the arrow below to explore these narratives.</p>
                                </>
                            }
                            {
                                carouselIndex % (numStories + 1) !== 0 &&
                                <>
                                    <p>{stories[carouselIndex - 1]["story"]}</p>
                                </>
                            }
                        </>
                    </div>
                    <div className={"carousel-content-bottom"}>
                        <>
                            {
                                carouselIndex % (numStories + 1) > 0 &&
                                <div>
                                    <button onClick={goBack} className={"left-arrow"}>
                                        <img src={"Polygon 7.png"}/>
                                    </button>
                                </div>
                            }

                            {
                                carouselIndex < (numStories) &&
                                <div>
                                    <button onClick={goForward} className={"right-arrow"}>
                                        <img src={"Polygon 3.png"}/>
                                    </button>
                                </div>
                            }
                        </>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomCarousel;