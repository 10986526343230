import Background from "./Background";
import Header from "./Header";
import Filler from "./Filler";
import Footer from "./Footer";
import BLink from "./BLink";
import {useHistory, useLocation} from "react-router-dom";
import queryString from "query-string";
import $ from "jquery";

const QuizName = () => {

    const {search} = useLocation()
    const {name} = queryString.parse(search)

    const h = useHistory();

    // throw error if user does not type a name in, else navigate to the next page when clicking "next" button
    const nextPage = function () {
        if ($("#first_name").val()) {
            h.push("/quiz2?name=" + $("#first_name").val())
        } else {
            $("#first_name, textarea").addClass('error');
        }
    }

    return (
        <div className={"home box"}>
            <Background/>
            <Header/>
            {/*previously bubble name*/}
            <div className='row'>
                <div className={"col-3"}>
                    <img src={"Talking Head.svg"} id={"head"} alt={"talking head"}/>
                    <img src={"Polygon 2.svg"} id={"polygon-2"} alt={"polygon 2"}/>
                </div>

                <div className={"col-6"}>
                    <div className='card card-primary col-7 main_text_view'>
                        <div className='card-body row justify-content-center'>
                            <h3>
                                Let's get started! First, what is your name?
                            </h3>
                            <label>
                                <input type="text" name="name" placeholder={"Name"} id={"first_name"} defaultValue={name}/>
                            </label>
                        </div>
                        <div className={"row button-bar"}>
                            <BLink text={"Back"} loc={"/"} nav={"left"}/>
                            <BLink text={"Next"} nav={"right"} onClick={nextPage}/>
                        </div>
                    </div>
                </div>
            </div>
            <Filler/>
            <Footer/>
        </div>
    );
};

export default QuizName;