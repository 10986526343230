import positions from "../assets/positions";
import $ from "jquery";
import {useLocation} from "react-router-dom";
import queryString from "query-string";
import {toDollars} from "../assets/dataUtils";

const JourneyStep = ({step, color, exportJourney, htmlIndex}) => {
    const {search} = useLocation()
    const {name, edu, goal, skills} = queryString.parse(search)

    const positionObj = positions.find(x => x.PositionId === step)

    let journeyType
    // converting the edu parameter into text to use for the page directions
    switch (edu) {
        case "VET":
            journeyType = "Veteran"
            break
        case "PRO":
            journeyType = "Maritime Professional"
            break
        case "CG":
            journeyType = "College Graduate"
            break
        default:
            journeyType = "High school student/Graduate"
    }

    console.log(positionObj.Skills)
    const salaryRange = "$" + toDollars(positionObj.SalaryMin) + "-$" + toDollars(positionObj.SalaryMax) + "/year"
    const benefits = "Approximately $" + toDollars(positionObj.Benefits)
    let requiredSkills

    if (positionObj.Skills.includes('')){
        requiredSkills = "Skills not listed."
    } else {
        requiredSkills = "Successful " + positionObj.Position + "s have the following skills: " + positionObj.Skills.join(", ")
    }

    return (
        <div className={"printout-page"} id={"userJourneyHTML-" + htmlIndex}>
            <div className={"printout-header"}>
                <img src={"Color.png"}
                     style={{"height": "70px", "float": "left", "marginLeft": "40px", "marginTop": "10px"}}/>
                <h3 className={"printout-header-text"}
                    style={{"fontFamily": "'Oswald', sans-serif font-size"}}>
                    <b>
                        {name}'s Career Development Plan
                    </b>
                </h3>
            </div>
            <div className={"card printout-content"}>
                <div className={"card-body"} style={{"height": "100%"}}>
                    <div className={"row"} style={{"height": "5%", "textAlign": "center"}}>
                        <div className={"col"}>
                            <p><u>Current Career:</u> {journeyType}</p>
                        </div>
                        <div className={"col"}>
                            <p><u>Goal Career:</u> {positionObj.Position}</p>
                        </div>
                    </div>
                    <hr/>
                    <button className={"btn printout-button"} style={{
                        "background": color,
                        "min-width": "100px",
                        "max-width": "300px",
                        "display": "inline-block",
                        "fontSize": "12px",
                        "borderColor": "black",
                        "borderWidth": "3px",
                        "marginLeft": "300px",
                        "marginBottom": "50px",
                        "marginTop": "30px"
                    }}><b>{positionObj.Position}</b></button>
                    <br/>
                    <div style={{"marginBottom": "5px"}}>
                        <b>Description:</b> {positionObj.Description}
                    </div>
                    <br/>
                    <div style={{"marginBottom": "5px"}}>
                        <b>Salary Range:</b> {salaryRange}
                    </div>
                    <br/>
                    <div style={{"marginBottom": "5px"}}>
                        <b>Benefits:</b> {benefits}
                    </div>
                    <br/>
                    <div style={{"marginBottom": "5px"}}>
                        <b>Education Requirement:</b> {positionObj.EducationRequirement}
                    </div>
                    <br/>
                    <div style={{"marginBottom": "5px"}}>
                        <b>Skills:</b> {requiredSkills}
                    </div>

                    <div className={"row justify-content-center"}>
                        <div className={"col"}>
                            <button onClick={exportJourney} className={"btn download-button"}
                                    style={{"marginTop": "50%"}}><b>Download My Journey</b> <i
                                className="fas fa-download"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JourneyStep;