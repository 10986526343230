import Background from "./Background";
import Header from "./Header";
import Filler from "./Filler";
import Footer from "./Footer";
import BLink from "./BLink";
import {useLocation} from "react-router-dom";
import queryString from "query-string";
import React from "react";

const QuizConfirm = () => {

    const {search} = useLocation()
    const {name, edu, goal, skills} = queryString.parse(search)

    return (
        <div className={"home box"}>
            <Background/>
            <Header/>
            <div className='row'>
                <div className={"col-3"}>
                    <img src={"Talking Head.svg"} id={"head"} alt={"talking head"}/>
                    <img src={"Polygon 2.svg"} id={"polygon-2"} alt={"polygon 2"}/>
                </div>

                <div className={"col-6"}>
                    <div className='card card-primary col-7 main_text_view'>
                        <div className='card-body'>
                            <h3>
                                Thanks, {name}! Are you ready to explore your career pathways?
                            </h3>
                        </div>
                        <div className={"row button-bar"}>
                            <BLink text={"Back"} nav={"left"} loc={"/quiz4?name=" + name + "&edu=" + edu +
                                "&goal=" + goal + "&skills=" + skills}/>
                        </div>
                        <BLink text={"Pathways"} nav={"split1"} loc={"/pathways?name=" + name +
                            "&edu=" + edu +
                            "&goal=" + goal +
                            "&skills=" + skills +
                            "&category=Skilled Trades"}/>

                        <BLink text={"Position Comparison"} nav={"split2"} loc={"/comparison?name=" + name +
                            "&edu=" + edu +
                            "&goal=" + goal +
                            "&skills=" + skills}/>
                    </div>
                </div>
            </div>
            <Filler/>
            <Footer/>
        </div>
    );
};

export default QuizConfirm;