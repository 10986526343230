const locationsMark = {
   "1": {
      "ShipyardName": "Lyon Shipyard",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "1818 Brown Ave, Norfolk, VA 23504",
      "Latitude": 36.842064,
      "Longitude": -76.271416,
      "Number": 1818,
      "Street": "Brown Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Norfolk",
      "State": "VA",
      "County": "Norfolk City",
      "Zip": 23504,
      "Country": "US",
      "Source": "City of Norfolk",
      "Phone": "7576224661"
   },
   "2": {
      "ShipyardName": "Norfolk Naval Shipyard",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "Portsmouth, VA 23709",
      "Latitude": 36.812752,
      "Longitude": -76.305458,
      "Number": null,
      "Street": "",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Portsmouth",
      "State": "VA",
      "County": "Portsmouth City",
      "Zip": 23709,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "7573961796"
   },
   "3": {
      "ShipyardName": "Colonna’s Shipyard, Inc.",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "400 East Indian River Road Norfolk, VA 23523",
      "Latitude": 36.834672,
      "Longitude": -76.275874,
      "Number": 400,
      "Street": "E Indian River Rd",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Norfolk",
      "State": "VA",
      "County": "Norfolk City",
      "Zip": 23523,
      "Country": "US",
      "Source": "City of Norfolk",
      "Phone": ""
   },
   "4": {
      "ShipyardName": "Blount Shipyard",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "461 Water Street, PO Box 368, 02885 Warren, Rl, USA, 02885 Warren, United States",
      "Latitude": 41.724674,
      "Longitude": -71.284971,
      "Number": 461,
      "Street": "Water St",
      "Unit Type": "",
      "Unit Number": "368",
      "City": "Warren",
      "State": "RI",
      "County": "Bristol County",
      "Zip": 2885,
      "Country": "US",
      "Source": "Statewide RI",
      "Phone": "4012458300"
   },
   "5": {
      "ShipyardName": "Bayonne Dry Dock & Repair Corp",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "100 Military Ocean Term St, Bayonne, NJ 07002",
      "Latitude": 40.670858,
      "Longitude": -74.109486,
      "Number": null,
      "Street": "",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Bayonne",
      "State": "NJ",
      "County": "Hudson County",
      "Zip": 7002,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "2018239295"
   },
   "6": {
      "ShipyardName": "Gmd Shipyard Corp",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "63 Flushing Ave, Brooklyn, NY 11205",
      "Latitude": 40.698228,
      "Longitude": -73.975163,
      "Number": 63,
      "Street": "Flushing Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Brooklyn",
      "State": "NY",
      "County": "Kings County",
      "Zip": 11205,
      "Country": "US",
      "Source": "City of New York",
      "Phone": "7182609200"
   },
   "7": {
      "ShipyardName": "Philly Shipyard",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "2100 Kitty Hawk Ave, Philadelphia, PA 19112",
      "Latitude": 39.891888,
      "Longitude": -75.154409,
      "Number": null,
      "Street": "Kitty Hawk Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Philadelphia",
      "State": "PA",
      "County": "Philadelphia County",
      "Zip": 19112,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "2158752600"
   },
   "8": {
      "ShipyardName": "Newport News Shipbuilding",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "4101 Washington Av, 23607 Newport News, VA, USA, 23607 Newport News, United States",
      "Latitude": 36.992027,
      "Longitude": -76.434034,
      "Number": 4101,
      "Street": "Washington Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Newport News",
      "State": "VA",
      "County": "Newport News City",
      "Zip": 23607,
      "Country": "US",
      "Source": "City of Newport News",
      "Phone": "7573802000"
   },
   "9": {
      "ShipyardName": "Detyens Shipyards Inc (Dsi)",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "1670 Drydock Ave, Bldg 236, Suite 200, 29405-2121 North Charleston, SC, USA, 29405 North Charleston, United States",
      "Latitude": 32.860936,
      "Longitude": -79.963965,
      "Number": 1670,
      "Street": "Dry Dock Ave",
      "Unit Type": "Bldg",
      "Unit Number": "236",
      "City": "North Charleston",
      "State": "SC",
      "County": "Charleston County",
      "Zip": 29405,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "8433088000"
   },
   "10": {
      "ShipyardName": "Sheridan Shipyard",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "618-704 Old U.S. 52, South Point, OH 45680",
      "Latitude": 38.463419,
      "Longitude": -82.600751,
      "Number": 618,
      "Street": "Old Us Hwy 52",
      "Unit Type": "",
      "Unit Number": "",
      "City": "South Point",
      "State": "OH",
      "County": "Lawrence County",
      "Zip": 45680,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "7403774391"
   },
   "11": {
      "ShipyardName": "Mcnational Paducah, Kentucky Shipyard",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "P.O. Box 3159 4350 Clarks River Road Paducah, KY 42002, 42002 Paducah, United States",
      "Latitude": 37.085511,
      "Longitude": -88.71247,
      "Number": 3159,
      "Street": "PO Box",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Paducah",
      "State": "KY",
      "County": "McCracken County",
      "Zip": 42002,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "270.898.7392"
   },
   "12": {
      "ShipyardName": "James Marine, Inc.",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "4500 Clarks River Rd P.O. Box 2305 Paducah KY 42003",
      "Latitude": 37.040188,
      "Longitude": -88.541951,
      "Number": 4500,
      "Street": "Clarks River Rd",
      "Unit Type": "",
      "Unit Number": "2305",
      "City": "Paducah",
      "State": "KY",
      "County": "McCracken County",
      "Zip": 42003,
      "Country": "US",
      "Source": "Mccracken",
      "Phone": "2708987392"
   },
   "13": {
      "ShipyardName": "Excell Marine Corparation",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "501 Virginia St, Paducah, KY 42003",
      "Latitude": 37.046632,
      "Longitude": -88.560039,
      "Number": 501,
      "Street": "Virginia St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Paducah",
      "State": "KY",
      "County": "McCracken County",
      "Zip": 42003,
      "Country": "US",
      "Source": "Mccracken",
      "Phone": "2704435377"
   },
   "14": {
      "ShipyardName": "Austal Usa",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "100 Dunlap Drive, 36602 Mobile, AL, USA, 36602 Mobile, United States",
      "Latitude": 30.692368,
      "Longitude": -88.032643,
      "Number": 100,
      "Street": "Dunlap Dr",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Mobile",
      "State": "AL",
      "County": "Mobile County",
      "Zip": 36602,
      "Country": "US",
      "Source": "City of Mobile",
      "Phone": "6.10894E+11"
   },
   "15": {
      "ShipyardName": "Tampa Ship Llc",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "1130 McCloskey Boulevard, Tampa 33605, United States",
      "Latitude": 27.925942,
      "Longitude": -82.441651,
      "Number": 1130,
      "Street": "McClosky Blvd",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Tampa",
      "State": "FL",
      "County": "Hillsborough County",
      "Zip": 33605,
      "Country": "US",
      "Source": "Hillsborough",
      "Phone": "8132489310"
   },
   "16": {
      "ShipyardName": "International Ship Repair & Marine Services Inc.",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "1616 Penny St. Tampa, FL 33605",
      "Latitude": 27.954428,
      "Longitude": -82.44122,
      "Number": 1616,
      "Street": "Penny St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Tampa",
      "State": "FL",
      "County": "Hillsborough County",
      "Zip": 33605,
      "Country": "US",
      "Source": "Hillsborough",
      "Phone": "8132471118"
   },
   "17": {
      "ShipyardName": "Bollinger Algiers Llc",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "434 Powder St, New Orleans, LA 70114",
      "Latitude": 29.950569,
      "Longitude": -90.054845,
      "Number": 434,
      "Street": "Powder St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "New Orleans",
      "State": "LA",
      "County": "Orleans Parish",
      "Zip": 70114,
      "Country": "US",
      "Source": "Orleans Parish",
      "Phone": "5043627960"
   },
   "18": {
      "ShipyardName": "Midship Marine",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "1029 Destrehan Ave, Harvey, LA 70058",
      "Latitude": 29.897031,
      "Longitude": -90.080037,
      "Number": 1029,
      "Street": "Destrehan Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Harvey",
      "State": "LA",
      "County": "Jefferson Parish",
      "Zip": 70058,
      "Country": "US",
      "Source": "Jefferson Parish",
      "Phone": "5043414359"
   },
   "19": {
      "ShipyardName": "Bollinger Quick Repair",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "615 Destrehan Ave, Harvey, LA 70058",
      "Latitude": 29.902652,
      "Longitude": -90.083416,
      "Number": 615,
      "Street": "Destrehan Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Harvey",
      "State": "LA",
      "County": "Jefferson Parish",
      "Zip": 70058,
      "Country": "US",
      "Source": "Jefferson Parish",
      "Phone": "5043400621"
   },
   "20": {
      "ShipyardName": "Ingalls Shipbuilding",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "1000 Access Road Pascagoula, MS 39568",
      "Latitude": 30.441072,
      "Longitude": -88.634482,
      "Number": null,
      "Street": "",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Pascagoula",
      "State": "MS",
      "County": "Jackson County",
      "Zip": 39568,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "8778712058"
   },
   "21": {
      "ShipyardName": "National Maintenance And Repair",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "401 W Hawthorne Ave, Hartford, IL 62048",
      "Latitude": 38.833718,
      "Longitude": -90.096823,
      "Number": null,
      "Street": "W Hawthorne St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Hartford",
      "State": "IL",
      "County": "Madison County",
      "Zip": 62048,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "6182547451"
   },
   "22": {
      "ShipyardName": "Bollinger Lockport Repair",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "8365 LA-308, Lockport, LA 70374",
      "Latitude": 29.614159,
      "Longitude": -90.491592,
      "Number": 8365,
      "Street": "Hwy 308",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Lockport",
      "State": "LA",
      "County": "Lafourche Parish",
      "Zip": 70374,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "9855322554"
   },
   "23": {
      "ShipyardName": "Bollinger Larose Llc",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "1515 LA-24, Larose, LA 70373",
      "Latitude": 29.544431,
      "Longitude": -90.399354,
      "Number": 1515,
      "Street": "State Rte 24",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Larose",
      "State": "LA",
      "County": "Lafourche Parish",
      "Zip": 70373,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "9856937002"
   },
   "24": {
      "ShipyardName": "Bollinger Shipyards South Fourchon Yard",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "106 Norman Doucet Dr., 70357 Golden Meadow, LA, USA, 70357 LA, United States",
      "Latitude": 29.127393,
      "Longitude": -90.209762,
      "Number": 106,
      "Street": "Norman Doucet Dr",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Golden Meadow",
      "State": "LA",
      "County": "Lafourche Parish",
      "Zip": 70357,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "9853962366"
   },
   "25": {
      "ShipyardName": "Bollinger Amelia Repair",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "606 Ford Industrial Rd, Amelia, LA 70340",
      "Latitude": 29.642708,
      "Longitude": -91.11277,
      "Number": 606,
      "Street": "Ford Industrial Rd",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Morgan City",
      "State": "LA",
      "County": "St. Mary Parish",
      "Zip": 70380,
      "Country": "US",
      "Source": "St Mary",
      "Phone": "9856312020"
   },
   "26": {
      "ShipyardName": "Bollinger Morgan City Llc",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "806 Bollinger Ln, Amelia, LA 70340",
      "Latitude": 29.647113,
      "Longitude": -91.108785,
      "Number": 806,
      "Street": "Bollinger Ln",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Morgan City",
      "State": "LA",
      "County": "St. Mary Parish",
      "Zip": 70380,
      "Country": "US",
      "Source": "St Mary",
      "Phone": "9856313600"
   },
   "27": {
      "ShipyardName": "Gulf Craft. Llc",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "320 Borough Ln, Franklin, LA 70538",
      "Latitude": 29.79604,
      "Longitude": -91.5015,
      "Number": null,
      "Street": "",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Franklin",
      "State": "LA",
      "County": "St. Mary Parish",
      "Zip": 70538,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "3378282580"
   },
   "28": {
      "ShipyardName": "Bollinger Texas City",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "2201 Dock Rd Texas City, TX 77590 United States, 77590 Texas City, United States",
      "Latitude": 29.376057,
      "Longitude": -94.921474,
      "Number": null,
      "Street": "",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Texas City",
      "State": "TX",
      "County": "Galveston County",
      "Zip": 77590,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": ""
   },
   "29": {
      "ShipyardName": "General Dynamics Nassco-norfolk",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "200 Ligon St, Norfolk, VA 23523",
      "Latitude": 36.837615,
      "Longitude": -76.288768,
      "Number": 200,
      "Street": "Ligon St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Norfolk",
      "State": "VA",
      "County": "Norfolk City",
      "Zip": 23523,
      "Country": "US",
      "Source": "City of Norfolk",
      "Phone": "7575436801"
   },
   "30": {
      "ShipyardName": "General Dynamics Nassco Portsmouth",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "2 Harper Rd, Portsmouth, VA 23707",
      "Latitude": 36.851687,
      "Longitude": -76.315031,
      "Number": 2,
      "Street": "Harper Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Portsmouth",
      "State": "VA",
      "County": "Portsmouth City",
      "Zip": 23707,
      "Country": "US",
      "Source": "Virginia Geographic Information Network (VGIN)",
      "Phone": "7573971039"
   },
   "31": {
      "ShipyardName": "General Dynamics Nassco",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "2798 East Harbor Drive San Diego, CA 92113",
      "Latitude": 32.690828,
      "Longitude": -117.133776,
      "Number": 2798,
      "Street": "E Harbor Dr",
      "Unit Type": "",
      "Unit Number": "",
      "City": "San Diego",
      "State": "CA",
      "County": "San Diego County",
      "Zip": 92113,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "6195443400"
   },
   "32": {
      "ShipyardName": "Swan Island Shipyard",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "5555 N Channel Ave, Portland, OR 97217",
      "Latitude": 45.562108,
      "Longitude": -122.72063,
      "Number": 5555,
      "Street": "N Channel Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Portland",
      "State": "OR",
      "County": "Multnomah County",
      "Zip": 97217,
      "Country": "US",
      "Source": "Portland",
      "Phone": "5032471777"
   },
   "33": {
      "ShipyardName": "Foss Maritime Company",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "450 Alaskan Way S., Suite 706 Seattle, WA 98104",
      "Latitude": 47.598777,
      "Longitude": -122.335196,
      "Number": 450,
      "Street": "Alaskan Way S",
      "Unit Type": "Ste",
      "Unit Number": "706",
      "City": "Seattle",
      "State": "WA",
      "County": "King County",
      "Zip": 98104,
      "Country": "US",
      "Source": "King",
      "Phone": "8004262885"
   },
   "34": {
      "ShipyardName": "Washington Marine Repair",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "202 N Cedar St, Port Angeles, WA 98363",
      "Latitude": 48.123814,
      "Longitude": -123.443135,
      "Number": 202,
      "Street": "N Cedar St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Port Angeles",
      "State": "WA",
      "County": "Clallam County",
      "Zip": 98363,
      "Country": "US",
      "Source": "Clallam",
      "Phone": "3604578470"
   },
   "35": {
      "ShipyardName": "Vigor Marine Llc",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "410 Ida St W, Bremerton, WA 98312",
      "Latitude": 47.561836,
      "Longitude": -122.690538,
      "Number": 410,
      "Street": "Ida St W",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Bremerton",
      "State": "WA",
      "County": "Kitsap County",
      "Zip": 98312,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "3603739171"
   },
   "36": {
      "ShipyardName": "Vigor Industrial-ketchikan",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "3801 Tongass Ave, Ketchikan, AK 99901",
      "Latitude": 55.355493,
      "Longitude": -131.692034,
      "Number": 3801,
      "Street": "Tongass Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Ketchikan",
      "State": "AK",
      "County": "Ketchikan Gateway Borough",
      "Zip": 99901,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "9072257199"
   },
   "37": {
      "ShipyardName": "Fincantieri Bay Shipbuilding (Fbs)",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "605 N 3rd Ave, Sturgeon Bay, WI 54235",
      "Latitude": 44.841622,
      "Longitude": -87.380977,
      "Number": 605,
      "Street": "N 3rd Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Sturgeon Bay",
      "State": "WI",
      "County": "Door County",
      "Zip": 54235,
      "Country": "US",
      "Source": "Door",
      "Phone": "9207435524"
   },
   "38": {
      "ShipyardName": "Southwest Shipyard",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "1002 Texas Clipper Rd, Galveston, TX 77554",
      "Latitude": 29.311479,
      "Longitude": -94.780342,
      "Number": 1000,
      "Street": "Texas Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Galveston",
      "State": "TX",
      "County": "Galveston County",
      "Zip": 77550,
      "Country": "US",
      "Source": "Galveston",
      "Phone": "4097403755"
   },
   "39": {
      "ShipyardName": "Port Bolivar Marine Service Inc",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "1436 John Wayne Rd, Port Bolivar, TX 77650",
      "Latitude": 29.404753,
      "Longitude": -94.74231,
      "Number": 1436,
      "Street": "John Wayne Rd",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Port Bolivar",
      "State": "TX",
      "County": "Galveston County",
      "Zip": 77650,
      "Country": "US",
      "Source": "Galveston",
      "Phone": "4095999653"
   },
   "40": {
      "ShipyardName": "Texas Marine Shipyard Ltd",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "5150 27th St, San Leon, TX 77539",
      "Latitude": 29.468704,
      "Longitude": -94.962764,
      "Number": 5200,
      "Street": "27th St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Dickinson",
      "State": "TX",
      "County": "Galveston County",
      "Zip": 77539,
      "Country": "US",
      "Source": "Galveston",
      "Phone": "2813392363"
   },
   "41": {
      "ShipyardName": "Hawaii Shipyards Inc",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "Bldg 1444, Pearl Harbor, HI 96860",
      "Latitude": 21.349028,
      "Longitude": -157.93508,
      "Number": null,
      "Street": "",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Pearl Harbor",
      "State": "HI",
      "County": "Honolulu County",
      "Zip": 96860,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "8084210479"
   },
   "42": {
      "ShipyardName": "Pacific Shipyards International Llc",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "705 N Nimitz Hwy, Honolulu, HI 96817",
      "Latitude": 21.312838,
      "Longitude": -157.870775,
      "Number": 705,
      "Street": "N Nimitz Hwy",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Honolulu",
      "State": "HI",
      "County": "Honolulu County",
      "Zip": 96817,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "8088486211"
   },
   "43": {
      "ShipyardName": "Bludworth Marine Llc",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "3502 Broadway St, Houston, TX 77017",
      "Latitude": 29.69114,
      "Longitude": -95.278087,
      "Number": 3502,
      "Street": "Broadway St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Houston",
      "State": "TX",
      "County": "Harris County",
      "Zip": 77017,
      "Country": "US",
      "Source": "Greater Houston",
      "Phone": "7136441595"
   },
   "44": {
      "ShipyardName": "Seabrook Marina & Shipyard",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "1900 Shipyard Dr, Seabrook, TX 77586",
      "Latitude": 29.552962,
      "Longitude": -95.025785,
      "Number": 1900,
      "Street": "Shipyard Dr",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Seabrook",
      "State": "TX",
      "County": "Harris County",
      "Zip": 77586,
      "Country": "US",
      "Source": "Greater Houston",
      "Phone": "2814742586"
   },
   "45": {
      "ShipyardName": "John Bludworth Shipyard",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "3101 Navigation Blvd, Corpus Christi, TX 78402",
      "Latitude": 27.80455,
      "Longitude": -97.453631,
      "Number": null,
      "Street": "Navigation Blvd",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Corpus Christi",
      "State": "TX",
      "County": "Nueces County",
      "Zip": 78408,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "3618877981"
   },
   "46": {
      "ShipyardName": "Westport Orange Shipyard Llc",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "91 W Front Ave, Orange, TX 77630",
      "Latitude": 30.091128,
      "Longitude": -93.728342,
      "Number": 91,
      "Street": "W Front Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Orange",
      "State": "TX",
      "County": "Orange County",
      "Zip": 77630,
      "Country": "US",
      "Source": "Statewide TX",
      "Phone": "4092915321"
   },
   "47": {
      "ShipyardName": "Conrad Shipyard Llc",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "1501 Front St, Morgan City, LA 70380",
      "Latitude": 29.706101,
      "Longitude": -91.216556,
      "Number": 1501,
      "Street": "Front St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Morgan City",
      "State": "LA",
      "County": "St. Mary Parish",
      "Zip": 70380,
      "Country": "US",
      "Source": "St Mary",
      "Phone": "9853843060"
   },
   "48": {
      "ShipyardName": "Alabama Shipyard, Llc",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "660 Dunlap Dr, Mobile, AL 36602",
      "Latitude": 30.674118,
      "Longitude": -88.026853,
      "Number": 660,
      "Street": "Dunlap Dr",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Mobile",
      "State": "AL",
      "County": "Mobile County",
      "Zip": 36602,
      "Country": "US",
      "Source": "City of Mobile",
      "Phone": "2512306341"
   },
   "49": {
      "ShipyardName": "Delphinus Engineering Inc. Delphinus Engineering Inc. | Marine Services Division",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "7437 Central Business Park Drive Norfolk, VA 23513",
      "Latitude": 36.910935,
      "Longitude": -76.249008,
      "Number": 7437,
      "Street": "Central Business Park Dr",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Norfolk",
      "State": "VA",
      "County": "Norfolk City",
      "Zip": 23513,
      "Country": "US",
      "Source": "City of Norfolk",
      "Phone": "7575888364"
   },
   "50": {
      "ShipyardName": "Philadelphia Navy Yard",
      "ShipyardType": "Shipyard/Shipbuilding",
      "Address": "4701 Intrepid Ave, Philadelphia, PA 19112",
      "Latitude": 39.892213,
      "Longitude": -75.179623,
      "Number": null,
      "Street": "",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Philadelphia",
      "State": "PA",
      "County": "Philadelphia County",
      "Zip": 19112,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": ""
   },
   "51": {
      "ShipyardName": "Cal Maritime",
      "ShipyardType": "Maritime School",
      "Address": "200 Maritime Academy Dr, Vallejo, CA 94590",
      "Latitude": 38.069769,
      "Longitude": -122.230785,
      "Number": 200,
      "Street": "Maritime Academy Dr",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Vallejo",
      "State": "CA",
      "County": "Solano County",
      "Zip": 94590,
      "Country": "US",
      "Source": "Solano",
      "Phone": "7076541000"
   },
   "52": {
      "ShipyardName": "Suny Maritime College",
      "ShipyardType": "Maritime School",
      "Address": "6 Pennyfield Ave, Bronx, NY 10465",
      "Latitude": 40.809196,
      "Longitude": -73.801199,
      "Number": 6,
      "Street": "Pennyfield Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Bronx",
      "State": "NY",
      "County": "Bronx County",
      "Zip": 10465,
      "Country": "US",
      "Source": "City of New York",
      "Phone": "7184097200"
   },
   "53": {
      "ShipyardName": "United States Merchant Marine Academy",
      "ShipyardType": "Maritime School",
      "Address": "300 Steamboat Rd, Kings Point, NY 11024",
      "Latitude": 40.813764,
      "Longitude": -73.763616,
      "Number": 300,
      "Street": "Steamboat Rd",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Great Neck",
      "State": "NY",
      "County": "Nassau County",
      "Zip": 11024,
      "Country": "US",
      "Source": "NYS GIS Program Office, State Geographic Information Officer (GIO)",
      "Phone": "5167265800"
   },
   "54": {
      "ShipyardName": "Maine Maritime Academy",
      "ShipyardType": "Maritime School",
      "Address": "1 Pleasant St, Castine, ME 04420",
      "Latitude": 44.386978,
      "Longitude": -68.797731,
      "Number": 1,
      "Street": "Pleasant St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Castine",
      "State": "ME",
      "County": "Hancock County",
      "Zip": 4421,
      "Country": "US",
      "Source": "Maine Office of GIS",
      "Phone": "2073264311"
   },
   "55": {
      "ShipyardName": "Massachusetts Maritime Academy",
      "ShipyardType": "Maritime School",
      "Address": "101 Academy Dr, Buzzards Bay, MA 02532",
      "Latitude": 41.739849,
      "Longitude": -70.624348,
      "Number": 101,
      "Street": "Academy Dr",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Buzzards Bay",
      "State": "MA",
      "County": "Barnstable County",
      "Zip": 2532,
      "Country": "US",
      "Source": "Office of Geographic Information (MassGIS), Commonwealth of Massachusetts, MassIT",
      "Phone": "5088305000"
   },
   "56": {
      "ShipyardName": "Suny Maritime College",
      "ShipyardType": "Maritime School",
      "Address": "6 Pennyfield Avenue Fort Schuyler, NY 10465-4198",
      "Latitude": 40.809196,
      "Longitude": -73.801199,
      "Number": 6,
      "Street": "Pennyfield Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Bronx",
      "State": "NY",
      "County": "Bronx County",
      "Zip": 10465,
      "Country": "US",
      "Source": "City of New York",
      "Phone": "7184097200"
   },
   "57": {
      "ShipyardName": "Texas A&m Maritime Academy",
      "ShipyardType": "Maritime School",
      "Address": "200 Seawolf Parkway P.O. Box 1675 Galveston, TX 77553-1675",
      "Latitude": 29.319562,
      "Longitude": -94.812059,
      "Number": null,
      "Street": "Seawolf Pkwy",
      "Unit Type": "",
      "Unit Number": "1675",
      "City": "Galveston",
      "State": "TX",
      "County": "Galveston County",
      "Zip": 77554,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "4097404411"
   },
   "58": {
      "ShipyardName": "Great Lakes Maritime Academy",
      "ShipyardType": "Maritime School",
      "Address": "Northwestern Michigan College 1701 East Front Street Traverse City, MI 49686-3061",
      "Latitude": 44.765293,
      "Longitude": -85.584552,
      "Number": 1701,
      "Street": "E Front St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Traverse City",
      "State": "MI",
      "County": "Grand Traverse County",
      "Zip": 49686,
      "Country": "US",
      "Source": "Grand Traverse",
      "Phone": "2319951200"
   },
   "59": {
      "ShipyardName": "California State University Maritime Academy",
      "ShipyardType": "Maritime School",
      "Address": "200 Maritime Academy Drive Vallejo, CA 94590",
      "Latitude": 38.069769,
      "Longitude": -122.230785,
      "Number": 200,
      "Street": "Maritime Academy Dr",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Vallejo",
      "State": "CA",
      "County": "Solano County",
      "Zip": 94590,
      "Country": "US",
      "Source": "Solano",
      "Phone": "7076541000"
   },
   "60": {
      "ShipyardName": "San Jacinto College Maritime Technology & Training Center",
      "ShipyardType": "Maritime School",
      "Address": "3700 Old Highway 146 La Porte, TX 77571",
      "Latitude": 29.759954,
      "Longitude": -94.928783,
      "Number": 3712,
      "Street": "N Highway 146",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Baytown",
      "State": "TX",
      "County": "Harris County",
      "Zip": 77520,
      "Country": "US",
      "Source": "Greater Houston",
      "Phone": "2814595483"
   },
   "61": {
      "ShipyardName": "Seattle Maritime Academy (Part Of Seattle Central College)",
      "ShipyardType": "Maritime School",
      "Address": "4455 Shilshole Ave NW, Seattle, WA 98107",
      "Latitude": 47.660852,
      "Longitude": -122.374638,
      "Number": 4455,
      "Street": "Shilshole Ave NW",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Seattle",
      "State": "WA",
      "County": "King County",
      "Zip": 98107,
      "Country": "US",
      "Source": "King",
      "Phone": "2067822647"
   },
   "62": {
      "ShipyardName": "Northeast Maritime Institute - College Of Maritime Science",
      "ShipyardType": "Maritime School",
      "Address": "32 Washington St, Fairhaven, MA 02719",
      "Latitude": 41.637613,
      "Longitude": -70.903569,
      "Number": 32,
      "Street": "Washington St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Fairhaven",
      "State": "MA",
      "County": "Bristol County",
      "Zip": 2719,
      "Country": "US",
      "Source": "Office of Geographic Information (MassGIS), Commonwealth of Massachusetts, MassIT",
      "Phone": "5089924025"
   },
   "63": {
      "ShipyardName": "Atlantis Maritime Academy, Inc.",
      "ShipyardType": "Maritime School",
      "Address": "109 N Black Horse Pike #4a, Blackwood, NJ 08012",
      "Latitude": 39.803939,
      "Longitude": -75.06589,
      "Number": 109,
      "Street": "N Black Horse Pike",
      "Unit Type": "Ste",
      "Unit Number": "4",
      "City": "Blackwood",
      "State": "NJ",
      "County": "Camden County",
      "Zip": 8012,
      "Country": "US",
      "Source": "NJ Office of Information Technology (NJOIT), Office of Geographic Information Systems (OGIS)",
      "Phone": "8567402278"
   },
   "64": {
      "ShipyardName": "Chapman School Of Seamanship",
      "ShipyardType": "Maritime School",
      "Address": "4343 SE St Lucie Blvd, Stuart, FL 34997",
      "Latitude": 27.150966,
      "Longitude": -80.20026,
      "Number": 4343,
      "Street": "SE St Lucie Blvd",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Stuart",
      "State": "FL",
      "County": "Martin County",
      "Zip": 34997,
      "Country": "US",
      "Source": "Martin",
      "Phone": "7722838130"
   },
   "65": {
      "ShipyardName": "Fremont Maritime Services",
      "ShipyardType": "Maritime School",
      "Address": "1900 W Nickerson St #200, Seattle, WA 98119",
      "Latitude": 47.655543,
      "Longitude": -122.381156,
      "Number": 1900,
      "Street": "W Nickerson St",
      "Unit Type": "Ste",
      "Unit Number": "200",
      "City": "Seattle",
      "State": "WA",
      "County": "King County",
      "Zip": 98119,
      "Country": "US",
      "Source": "King",
      "Phone": "2067824308"
   },
   "66": {
      "ShipyardName": "Gulf Coast Maritime Academy",
      "ShipyardType": "Maritime School",
      "Address": "1601 Sahlman Dr, Tampa, FL 33605",
      "Latitude": 27.953312,
      "Longitude": -82.441231,
      "Number": 1601,
      "Street": "Sahlman Dr",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Tampa",
      "State": "FL",
      "County": "Hillsborough County",
      "Zip": 33605,
      "Country": "US",
      "Source": "Hillsborough",
      "Phone": "8884214262"
   },
   "67": {
      "ShipyardName": "Mid Atlantic Maritime Academy",
      "ShipyardType": "Maritime School",
      "Address": "5301 Robin Hood Rd suite 100, Norfolk, VA 23513",
      "Latitude": 36.878902,
      "Longitude": -76.223822,
      "Number": 5301,
      "Street": "Robin Hood Rd",
      "Unit Type": "Ste",
      "Unit Number": "100",
      "City": "Norfolk",
      "State": "VA",
      "County": "Norfolk City",
      "Zip": 23513,
      "Country": "US",
      "Source": "City of Norfolk",
      "Phone": "7574646008"
   },
   "68": {
      "ShipyardName": "Maritime Institute Of Technology And Graduate Studies (Mitags)",
      "ShipyardType": "Maritime School",
      "Address": "692 Maritime Blvd, Linthicum Heights, MD 21090",
      "Latitude": 39.213152,
      "Longitude": -76.671893,
      "Number": 692,
      "Street": "Maritime Blvd",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Linthicum Heights",
      "State": "MD",
      "County": "Anne Arundel County",
      "Zip": 21090,
      "Country": "US",
      "Source": "Anne Arundel",
      "Phone": "4108595700"
   },
   "69": {
      "ShipyardName": "Paul Hall Center For Maritime Training And Education",
      "ShipyardType": "Maritime School",
      "Address": "45353 St. George's Avenue, Piney Point, MD 20674",
      "Latitude": 38.146984,
      "Longitude": -76.507433,
      "Number": 45353,
      "Street": "St Georges Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Piney Point",
      "State": "MD",
      "County": "St. Mary's County",
      "Zip": 20674,
      "Country": "US",
      "Source": "St Marys",
      "Phone": "3019940010"
   },
   "70": {
      "ShipyardName": "Sea School",
      "ShipyardType": "Maritime School",
      "Address": "14180 Beach Blvd, Jacksonville, FL 32250",
      "Latitude": 30.287195,
      "Longitude": -81.443159,
      "Number": 14180,
      "Street": "Beach Blvd",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Jacksonville Beach",
      "State": "FL",
      "County": "Duval County",
      "Zip": 32250,
      "Country": "US",
      "Source": "Duval",
      "Phone": "9047221400"
   },
   "71": {
      "ShipyardName": "Star Center",
      "ShipyardType": "Maritime School",
      "Address": "2 West Dixie Highway Dania Beach, FL 33004",
      "Latitude": 26.04097,
      "Longitude": -80.14345,
      "Number": 2,
      "Street": "W Dixie Hwy",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Dania",
      "State": "FL",
      "County": "Broward County",
      "Zip": 33004,
      "Country": "US",
      "Source": "Statewide2",
      "Phone": "9549217254"
   },
   "72": {
      "ShipyardName": "Training Resources Maritime Institute",
      "ShipyardType": "Maritime School",
      "Address": "3980 Sherman St Suite 100, San Diego, CA 92110",
      "Latitude": 32.756044,
      "Longitude": -117.207255,
      "Number": 3980,
      "Street": "Sherman St",
      "Unit Type": "Ste",
      "Unit Number": "100",
      "City": "San Diego",
      "State": "CA",
      "County": "San Diego County",
      "Zip": 92110,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "6192631638"
   },
   "73": {
      "ShipyardName": "Maritime Professional Training",
      "ShipyardType": "Maritime School",
      "Address": "1915 S Andrews Ave, Fort Lauderdale, FL 33316",
      "Latitude": 26.097508,
      "Longitude": -80.141877,
      "Number": 1915,
      "Street": "S Andrews Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Fort Lauderdale",
      "State": "FL",
      "County": "Broward County",
      "Zip": 33316,
      "Country": "US",
      "Source": "City of Fort Lauderdale",
      "Phone": "9545251014"
   },
   "74": {
      "ShipyardName": "Bluewater Maritime School",
      "ShipyardType": "Maritime School",
      "Address": "1719 Blanding Blvd, Jacksonville, FL 32210",
      "Latitude": 30.288105,
      "Longitude": -81.723317,
      "Number": 1719,
      "Street": "Blanding Blvd",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Jacksonville",
      "State": "FL",
      "County": "Duval County",
      "Zip": 32210,
      "Country": "US",
      "Source": "Duval",
      "Phone": "9047664797"
   },
   "75": {
      "ShipyardName": "Captain School Key West",
      "ShipyardType": "Maritime School",
      "Address": "1107 Truman Ave, Key West, FL 33040",
      "Latitude": 24.555773,
      "Longitude": -81.792104,
      "Number": 1107,
      "Street": "Truman Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Key West",
      "State": "FL",
      "County": "Monroe County",
      "Zip": 33040,
      "Country": "US",
      "Source": "Loveland",
      "Phone": "3054816981"
   },
   "76": {
      "ShipyardName": "Fmtc Safety",
      "ShipyardType": "Maritime School",
      "Address": "103 Simmons Dr, Houma, LA 70363",
      "Latitude": 29.589555,
      "Longitude": -90.710486,
      "Number": 103,
      "Street": "Simmons Dr",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Houma",
      "State": "LA",
      "County": "Terrebonne Parish",
      "Zip": 70363,
      "Country": "US",
      "Source": "Terrebonne",
      "Phone": "9852179284"
   },
   "77": {
      "ShipyardName": "Resolve Maritime Academy",
      "ShipyardType": "Maritime School",
      "Address": "1510 SE 17th St #301, Fort Lauderdale, FL 33316",
      "Latitude": 26.099867,
      "Longitude": -80.127036,
      "Number": 1510,
      "Street": "SE 17th St",
      "Unit Type": "#",
      "Unit Number": "301",
      "City": "Fort Lauderdale",
      "State": "FL",
      "County": "Broward County",
      "Zip": 33316,
      "Country": "US",
      "Source": "City of Fort Lauderdale",
      "Phone": "9544639195"
   },
   "78": {
      "ShipyardName": "Seven Seas Preparatory Academy",
      "ShipyardType": "Maritime School",
      "Address": "4850 Millenia Blvd, Orlando, FL 32839",
      "Latitude": 28.481543,
      "Longitude": -81.441539,
      "Number": 4850,
      "Street": "Millenia Blvd",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Orlando",
      "State": "FL",
      "County": "Orange County",
      "Zip": 32839,
      "Country": "US",
      "Source": "Orange County",
      "Phone": "8777732772"
   },
   "79": {
      "ShipyardName": "The Northwest Maritime Academy",
      "ShipyardType": "Maritime School",
      "Address": "301 30th St Suite C, Anacortes, WA 98221",
      "Latitude": 48.497247,
      "Longitude": -122.601736,
      "Number": 301,
      "Street": "30th St",
      "Unit Type": "Ste",
      "Unit Number": "C",
      "City": "Anacortes",
      "State": "WA",
      "County": "Skagit County",
      "Zip": 98221,
      "Country": "US",
      "Source": "Skagit",
      "Phone": "2066597178"
   },
   "80": {
      "ShipyardName": "Chesapeake Marine Training Institute",
      "ShipyardType": "Maritime School",
      "Address": "3566 George Washington Memorial Hwy, Hayes, VA 23072",
      "Latitude": 37.30524,
      "Longitude": -76.513347,
      "Number": 3566,
      "Street": "George Washington Memorial Hwy",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Hayes",
      "State": "VA",
      "County": "Gloucester County",
      "Zip": 23072,
      "Country": "US",
      "Source": "Virginia Geographic Information Network (VGIN)",
      "Phone": "8046420123"
   },
   "81": {
      "ShipyardName": "Quality Maritime Training",
      "ShipyardType": "Maritime School",
      "Address": "1510 Penman Rd, Jacksonville Beach, FL 32250",
      "Latitude": 30.300993,
      "Longitude": -81.406948,
      "Number": 1510,
      "Street": "Penman Rd",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Jacksonville Beach",
      "State": "FL",
      "County": "Duval County",
      "Zip": 32250,
      "Country": "US",
      "Source": "Duval",
      "Phone": "9046831985"
   },
   "82": {
      "ShipyardName": "Marine Mechanics Institute",
      "ShipyardType": "Maritime School",
      "Address": "2202 W Taft Vineland Rd, Orlando, FL 32837",
      "Latitude": 28.41965,
      "Longitude": -81.40837,
      "Number": 2202,
      "Street": "W Taft Vineland Rd",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Orlando",
      "State": "FL",
      "County": "Orange County",
      "Zip": 32837,
      "Country": "US",
      "Source": "Statewide2",
      "Phone": "4072402422"
   },
   "83": {
      "ShipyardName": "Florida Maritime Training Academy",
      "ShipyardType": "Maritime School",
      "Address": "18 Fishermans Wharf, Fort Pierce, FL 34950",
      "Latitude": 27.457421,
      "Longitude": -80.324117,
      "Number": 18,
      "Street": "Fishermans Wharf",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Fort Pierce",
      "State": "FL",
      "County": "St. Lucie County",
      "Zip": 34950,
      "Country": "US",
      "Source": "St Lucie",
      "Phone": "7724688181"
   },
   "84": {
      "ShipyardName": "Aidt Maritime Welding Center",
      "ShipyardType": "Maritime School",
      "Address": "360 Addsco Rd, Mobile, AL 36603",
      "Latitude": 30.69117,
      "Longitude": -88.057773,
      "Number": null,
      "Street": "",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Mobile",
      "State": "AL",
      "County": "Mobile County",
      "Zip": 36603,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "2514058698"
   },
   "85": {
      "ShipyardName": "Ja Moody Company",
      "ShipyardType": "Ship Repair Company",
      "Address": "4900 Bainbridge Blvd Chesapeake, VA 23320",
      "Latitude": 36.760965,
      "Longitude": -76.288424,
      "Number": 4900,
      "Street": "Bainbridge Blvd",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Chesapeake",
      "State": "VA",
      "County": "Chesapeake City",
      "Zip": 23320,
      "Country": "US",
      "Source": "City of Chesapeake",
      "Phone": "6106473810"
   },
   "86": {
      "ShipyardName": "Ncp Coatings Inc",
      "ShipyardType": "Ship Repair Company",
      "Address": "225 Fort St, Niles, MI 49120",
      "Latitude": 41.832805,
      "Longitude": -86.230825,
      "Number": null,
      "Street": "",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Niles",
      "State": "MI",
      "County": "Berrien County",
      "Zip": 49120,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "2696833377"
   },
   "87": {
      "ShipyardName": "Merco Tape",
      "ShipyardType": "Ship Repair Company",
      "Address": "201 NY-59, Hillburn, NY 10931",
      "Latitude": 41.12375,
      "Longitude": -74.161282,
      "Number": 201,
      "Street": "State Rte 59",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Hillburn",
      "State": "NY",
      "County": "Rockland County",
      "Zip": 10931,
      "Country": "US",
      "Source": "Rockland",
      "Phone": "8453573699"
   },
   "88": {
      "ShipyardName": "Bay Ship & Yacht Co.",
      "ShipyardType": "Ship Repair Company",
      "Address": "2900 Main St #2100, Alameda, CA 94501",
      "Latitude": 37.787564,
      "Longitude": -122.291673,
      "Number": 2900,
      "Street": "Main St",
      "Unit Type": "Ste",
      "Unit Number": "2100",
      "City": "Alameda",
      "State": "CA",
      "County": "Alameda County",
      "Zip": 94501,
      "Country": "US",
      "Source": "Alameda",
      "Phone": "5103379122"
   },
   "89": {
      "ShipyardName": "Randive, Inc. Of New Jersey",
      "ShipyardType": "Ship Repair Company",
      "Address": "25 Industrial Dr, Keyport, NJ 07735",
      "Latitude": 40.447038,
      "Longitude": -74.242764,
      "Number": 25,
      "Street": "Industrial Dr",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Keyport",
      "State": "NJ",
      "County": "Middlesex County",
      "Zip": 7735,
      "Country": "US",
      "Source": "NJ Office of Information Technology (NJOIT), Office of Geographic Information Systems (OGIS)",
      "Phone": "7323241144"
   },
   "90": {
      "ShipyardName": "Bludworth Marine Llc",
      "ShipyardType": "Ship Repair Company",
      "Address": "320 77th St, Galveston, TX 77554",
      "Latitude": 29.290137,
      "Longitude": -94.857442,
      "Number": 320,
      "Street": "77th St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Galveston",
      "State": "TX",
      "County": "Galveston County",
      "Zip": 77554,
      "Country": "US",
      "Source": "Galveston",
      "Phone": "7132341731"
   },
   "91": {
      "ShipyardName": "Gulf Copper Dry Dock & Rig Repair",
      "ShipyardType": "Ship Repair Company",
      "Address": "2920 Todd Rd, Galveston, TX 77554",
      "Latitude": 29.315981,
      "Longitude": -94.803142,
      "Number": 2920,
      "Street": "Todd Rd",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Galveston",
      "State": "TX",
      "County": "Galveston County",
      "Zip": 77554,
      "Country": "US",
      "Source": "Galveston",
      "Phone": "2817524835"
   },
   "92": {
      "ShipyardName": "Malin International Ship Repair & Drydock, Inc.",
      "ShipyardType": "Ship Repair Company",
      "Address": "7701 Bayside Avenue, Galveston, TX 77554",
      "Latitude": 29.28989,
      "Longitude": -94.85883,
      "Number": 7701,
      "Street": "Bayside Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Galveston",
      "State": "TX",
      "County": "Galveston County",
      "Zip": 77554,
      "Country": "US",
      "Source": "Galveston",
      "Phone": "4097403314"
   },
   "93": {
      "ShipyardName": "Dfd Inc.",
      "ShipyardType": "Ship Repair Company",
      "Address": "2019 SW 20th St #110, Fort Lauderdale, FL 33315",
      "Latitude": 26.097113,
      "Longitude": -80.166278,
      "Number": 2019,
      "Street": "SW 20th St",
      "Unit Type": "Ste",
      "Unit Number": "110",
      "City": "Fort Lauderdale",
      "State": "FL",
      "County": "Broward County",
      "Zip": 33315,
      "Country": "US",
      "Source": "City of Fort Lauderdale",
      "Phone": "9548008900"
   },
   "94": {
      "ShipyardName": "Bae Systems San Diego Ship Repair",
      "ShipyardType": "Ship Repair Company",
      "Address": "2205 East Belt, Foot of Sampson Street, San Diego, California, 92113, United States of America",
      "Latitude": 32.693266,
      "Longitude": -117.143862,
      "Number": 2205,
      "Street": "E Belt St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "San Diego",
      "State": "CA",
      "County": "San Diego County",
      "Zip": 92113,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "6192381000"
   },
   "95": {
      "ShipyardName": "Bae Systems Norfolk Ship Repair",
      "ShipyardType": "Ship Repair Company",
      "Address": "750 W Berkley Ave, Norfolk, VA 23523",
      "Latitude": 36.831732,
      "Longitude": -76.289676,
      "Number": 750,
      "Street": "W Berkley Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Norfolk",
      "State": "VA",
      "County": "Norfolk City",
      "Zip": 23523,
      "Country": "US",
      "Source": "City of Norfolk",
      "Phone": "7574944000"
   },
   "96": {
      "ShipyardName": "Pacific Ship Repair And Fabrication",
      "ShipyardType": "Ship Repair Company",
      "Address": "1625 Rigel St, San Diego, CA 92113",
      "Latitude": 32.690451,
      "Longitude": -117.121343,
      "Number": 1625,
      "Street": "Rigel St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "San Diego",
      "State": "CA",
      "County": "San Diego County",
      "Zip": 92113,
      "Country": "US",
      "Source": "San Diego Geographic Information Source - JPA",
      "Phone": "6192323200"
   },
   "97": {
      "ShipyardName": "Sea Time Marine",
      "ShipyardType": "Ship Repair Company",
      "Address": "6996-A Mission Gorge Rd, San Diego, CA 92120",
      "Latitude": 32.800743,
      "Longitude": -117.091284,
      "Number": 6996,
      "Street": "Mission Gorge Rd",
      "Unit Type": "",
      "Unit Number": "",
      "City": "San Diego",
      "State": "CA",
      "County": "San Diego County",
      "Zip": 92120,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "6197875403"
   },
   "98": {
      "ShipyardName": "Nielsen Beaumont Marine Inc",
      "ShipyardType": "Ship Repair Company",
      "Address": "2420 Shelter Island Dr, San Diego, CA 92106",
      "Latitude": 32.719697,
      "Longitude": -117.225095,
      "Number": 2420,
      "Street": "Shelter Island Dr",
      "Unit Type": "",
      "Unit Number": "",
      "City": "San Diego",
      "State": "CA",
      "County": "San Diego County",
      "Zip": 92106,
      "Country": "US",
      "Source": "San Diego Geographic Information Source - JPA",
      "Phone": "6192232628"
   },
   "99": {
      "ShipyardName": "Continental Maritime Of San Diego",
      "ShipyardType": "Ship Repair Company",
      "Address": "1995 Bay Front St, San Diego, CA 92113",
      "Latitude": 32.695629,
      "Longitude": -117.146538,
      "Number": 1995,
      "Street": "Bay Front St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "San Diego",
      "State": "CA",
      "County": "San Diego County",
      "Zip": 92113,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "6192348851"
   },
   "100": {
      "ShipyardName": "Miller Marine Inc",
      "ShipyardType": "Ship Repair Company",
      "Address": "2275 Manya St, San Diego, CA 92154",
      "Latitude": 32.59314,
      "Longitude": -117.085915,
      "Number": 2275,
      "Street": "Manya St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "San Diego",
      "State": "CA",
      "County": "San Diego County",
      "Zip": 92154,
      "Country": "US",
      "Source": "San Diego Geographic Information Source - JPA",
      "Phone": "6197911500"
   },
   "101": {
      "ShipyardName": "Driscoll Boat Works",
      "ShipyardType": "Ship Repair Company",
      "Address": "2500 Shelter Island Dr, San Diego, CA 92106",
      "Latitude": 32.71986,
      "Longitude": -117.225652,
      "Number": 2500,
      "Street": "Shelter Island Dr",
      "Unit Type": "",
      "Unit Number": "",
      "City": "San Diego",
      "State": "CA",
      "County": "San Diego County",
      "Zip": 92106,
      "Country": "US",
      "Source": "San Diego Geographic Information Source - JPA",
      "Phone": "6192262500"
   },
   "102": {
      "ShipyardName": "Explorer Marine Services",
      "ShipyardType": "Ship Repair Company",
      "Address": "2818 CaÃ±on St, San Diego, CA 92106",
      "Latitude": 32.746471,
      "Longitude": -117.133154,
      "Number": 2818,
      "Street": "Gunn St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "San Diego",
      "State": "CA",
      "County": "San Diego County",
      "Zip": 92104,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "6196799584"
   },
   "103": {
      "ShipyardName": "Marine Group Boat Works Chula Vista, Llc",
      "ShipyardType": "Ship Repair Company",
      "Address": "997 G St, Chula Vista, CA 91910",
      "Latitude": 32.629993,
      "Longitude": -117.107056,
      "Number": 997,
      "Street": "G St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Chula Vista",
      "State": "CA",
      "County": "San Diego County",
      "Zip": 91910,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "6194276767"
   },
   "104": {
      "ShipyardName": "Coast To Coast Marine Service",
      "ShipyardType": "Ship Repair Company",
      "Address": "1257 Rosecrans St, San Diego, CA 92106",
      "Latitude": 32.723138,
      "Longitude": -117.230047,
      "Number": 1257,
      "Street": "Rosecrans St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "San Diego",
      "State": "CA",
      "County": "San Diego County",
      "Zip": 92106,
      "Country": "US",
      "Source": "San Diego Geographic Information Source - JPA",
      "Phone": "8448892248"
   },
   "105": {
      "ShipyardName": "Consolidated Ship Repair",
      "ShipyardType": "Ship Repair Company",
      "Address": "3916 Holland Blvd, Chesapeake, VA 23323",
      "Latitude": 36.777997,
      "Longitude": -76.372319,
      "Number": 3916,
      "Street": "Holland Blvd",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Chesapeake",
      "State": "VA",
      "County": "Chesapeake City",
      "Zip": 23323,
      "Country": "US",
      "Source": "City of Chesapeake",
      "Phone": "7579661443"
   },
   "106": {
      "ShipyardName": "Subsea Global Solutions, Llc (Sgs Houston)",
      "ShipyardType": "Ship Repair Company",
      "Address": "706 77th St, Galveston, TX 77554",
      "Latitude": 29.289424,
      "Longitude": -94.857583,
      "Number": 706,
      "Street": "77th St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Galveston",
      "State": "TX",
      "County": "Galveston County",
      "Zip": 77554,
      "Country": "US",
      "Source": "Galveston",
      "Phone": "8322404234"
   },
   "107": {
      "ShipyardName": "Conrad Shipyard Llc",
      "ShipyardType": "Ship Repair Company",
      "Address": "9725 LA-182, Morgan City, LA 70380",
      "Latitude": 29.668022,
      "Longitude": -91.129436,
      "Number": 9725,
      "Street": "Hwy 182",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Morgan City",
      "State": "LA",
      "County": "St. Mary Parish",
      "Zip": 70380,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "9856312395"
   },
   "108": {
      "ShipyardName": "Vessel Repair Inc",
      "ShipyardType": "Ship Repair Company",
      "Address": "5848 Procter Ext, Port Arthur, TX 77642",
      "Latitude": 29.922485,
      "Longitude": -93.887863,
      "Number": 5848,
      "Street": "Procter Exn",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Port Arthur",
      "State": "TX",
      "County": "Jefferson County",
      "Zip": 77642,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "4099621302"
   },
   "109": {
      "ShipyardName": "Houston Ship Repair Inc.",
      "ShipyardType": "Ship Repair Company",
      "Address": "8303 Millet St, Houston, TX 77012",
      "Latitude": 29.707976,
      "Longitude": -95.275257,
      "Number": 8303,
      "Street": "Millet St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Houston",
      "State": "TX",
      "County": "Harris County",
      "Zip": 77012,
      "Country": "US",
      "Source": "Greater Houston",
      "Phone": "2814525841"
   },
   "110": {
      "ShipyardName": "Buck Kreihs Marine Repair, Llc",
      "ShipyardType": "Ship Repair Company",
      "Address": "2225 Tchoupitoulas St, New Orleans, LA 70130",
      "Latitude": 29.92334,
      "Longitude": -90.072443,
      "Number": 2225,
      "Street": "Tchoupitoulas St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "New Orleans",
      "State": "LA",
      "County": "Orleans Parish",
      "Zip": 70130,
      "Country": "US",
      "Source": "Orleans Parish",
      "Phone": "5045247681"
   },
   "111": {
      "ShipyardName": "International Ship Repair & Marine Services Inc.",
      "ShipyardType": "Ship Repair Company",
      "Address": "1616 Penny St, Tampa, FL 33605",
      "Latitude": 27.954428,
      "Longitude": -82.44122,
      "Number": 1616,
      "Street": "Penny St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Tampa",
      "State": "FL",
      "County": "Hillsborough County",
      "Zip": 33605,
      "Country": "US",
      "Source": "Hillsborough",
      "Phone": "8132471118"
   },
   "112": {
      "ShipyardName": "Fairlead Ship Repair",
      "ShipyardType": "Ship Repair Company",
      "Address": "99 Jefferson Ave, Newport News, VA 23607",
      "Latitude": 36.965826,
      "Longitude": -76.411711,
      "Number": 99,
      "Street": "Jefferson Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Newport News",
      "State": "VA",
      "County": "Newport News City",
      "Zip": 23607,
      "Country": "US",
      "Source": "City of Newport News",
      "Phone": "7573841957"
   },
   "113": {
      "ShipyardName": "Gulf Copper And Manufacturing Corporation",
      "ShipyardType": "Ship Repair Company",
      "Address": "5700 Procter St, Port Arthur, TX 77642",
      "Latitude": 29.919633,
      "Longitude": -93.890043,
      "Number": 5700,
      "Street": "Procter Street Ext",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Port Arthur",
      "State": "TX",
      "County": "Jefferson County",
      "Zip": 77642,
      "Country": "US",
      "Source": "Statewide TX",
      "Phone": "4099890300"
   },
   "114": {
      "ShipyardName": "Jrf Ship Repairs Llc",
      "ShipyardType": "Ship Repair Company",
      "Address": "660 Mt Vernon Ave, Portsmouth, VA 23707",
      "Latitude": 36.843017,
      "Longitude": -76.336505,
      "Number": 660,
      "Street": "Mt Vernon Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Portsmouth",
      "State": "VA",
      "County": "Portsmouth City",
      "Zip": 23707,
      "Country": "US",
      "Source": "Virginia Geographic Information Network (VGIN)",
      "Phone": "7576734794"
   },
   "115": {
      "ShipyardName": "Gulf Marine Repair",
      "ShipyardType": "Ship Repair Company",
      "Address": "1800 Grant St, Tampa, FL 33605",
      "Latitude": 27.94391,
      "Longitude": -82.440716,
      "Number": 1800,
      "Street": "Grant St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Tampa",
      "State": "FL",
      "County": "Hillsborough County",
      "Zip": 33605,
      "Country": "US",
      "Source": "Hillsborough",
      "Phone": "8132473153"
   },
   "116": {
      "ShipyardName": "Integrated Marine Services, Inc.",
      "ShipyardType": "Ship Repair Company",
      "Address": "2320 Main St, Chula Vista, CA 91911",
      "Latitude": 32.595527,
      "Longitude": -117.083289,
      "Number": 2320,
      "Street": "Main St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Chula Vista",
      "State": "CA",
      "County": "San Diego County",
      "Zip": 91911,
      "Country": "US",
      "Source": "San Diego Geographic Information Source - JPA",
      "Phone": "6194290300"
   },
   "117": {
      "ShipyardName": "Driscoll Boat Works",
      "ShipyardType": "Ship Repair Company",
      "Address": "2500 Shelter Island Dr, San Diego, CA 92106",
      "Latitude": 32.71986,
      "Longitude": -117.225652,
      "Number": 2500,
      "Street": "Shelter Island Dr",
      "Unit Type": "",
      "Unit Number": "",
      "City": "San Diego",
      "State": "CA",
      "County": "San Diego County",
      "Zip": 92106,
      "Country": "US",
      "Source": "San Diego Geographic Information Source - JPA",
      "Phone": "6192262500"
   },
   "118": {
      "ShipyardName": "Affordable Marine Services Inc",
      "ShipyardType": "Ship Repair Company",
      "Address": "1215 Beardsley St, San Diego, CA 92113",
      "Latitude": 32.699965,
      "Longitude": -117.148327,
      "Number": 1215,
      "Street": "Beardsley St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "San Diego",
      "State": "CA",
      "County": "San Diego County",
      "Zip": 92113,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "6195785979"
   },
   "119": {
      "ShipyardName": "Marine Group Boat Works",
      "ShipyardType": "Ship Repair Company",
      "Address": "997 G St, Chula Vista, CA 91910",
      "Latitude": 32.629993,
      "Longitude": -117.107056,
      "Number": 997,
      "Street": "G St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Chula Vista",
      "State": "CA",
      "County": "San Diego County",
      "Zip": 91910,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "6194276767"
   },
   "120": {
      "ShipyardName": "Bay City Marine",
      "ShipyardType": "Ship Repair Company",
      "Address": "1625 Cleveland Ave, National City, CA 91950",
      "Latitude": 32.666526,
      "Longitude": -117.112239,
      "Number": 1625,
      "Street": "Cleveland Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "National City",
      "State": "CA",
      "County": "San Diego County",
      "Zip": 91950,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "6194773991"
   },
   "121": {
      "ShipyardName": "Seanergy Marine",
      "ShipyardType": "Ship Repair Company",
      "Address": "",
      "Latitude": 32.82480839,
      "Longitude": -117.1089158,
      "Number": null,
      "Street": "",
      "Unit Type": "",
      "Unit Number": "",
      "City": "",
      "State": "",
      "County": "",
      "Zip": null,
      "Country": "",
      "Source": "",
      "Phone": "6197170102"
   },
   "122": {
      "ShipyardName": "Walashek Industrial & Marine, Inc",
      "ShipyardType": "Ship Repair Company",
      "Address": "1428 McKinley Ave, National City, CA 91950",
      "Latitude": 32.66788,
      "Longitude": -117.112083,
      "Number": 1428,
      "Street": "McKinley Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "National City",
      "State": "CA",
      "County": "San Diego County",
      "Zip": 91950,
      "Country": "US",
      "Source": "San Diego Geographic Information Source - JPA",
      "Phone": "6194981711"
   },
   "123": {
      "ShipyardName": "S&b Marine Technical Llc",
      "ShipyardType": "Ship Repair Company",
      "Address": "3465 Westminster Ave, Norfolk, VA 23504",
      "Latitude": 36.84294,
      "Longitude": -76.242772,
      "Number": 3465,
      "Street": "Westminster Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Norfolk",
      "State": "VA",
      "County": "Norfolk City",
      "Zip": 23504,
      "Country": "US",
      "Source": "TIGER/LineÂ® dataset from the US Census Bureau",
      "Phone": "7175125981"
   },
   "124": {
      "ShipyardName": "Fairlead Ship Repair",
      "ShipyardType": "Ship Repair Company",
      "Address": "99 Jefferson Ave, Newport News, VA 23607",
      "Latitude": 36.965826,
      "Longitude": -76.411711,
      "Number": 99,
      "Street": "Jefferson Ave",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Newport News",
      "State": "VA",
      "County": "Newport News City",
      "Zip": 23607,
      "Country": "US",
      "Source": "City of Newport News",
      "Phone": "7573841957"
   },
   "125": {
      "ShipyardName": "Harbor Industrial Services, Inc.",
      "ShipyardType": "Ship Repair Company",
      "Address": "528 Lincoln St, Portsmouth, VA 23704",
      "Latitude": 36.825806,
      "Longitude": -76.302187,
      "Number": 528,
      "Street": "Lincoln St",
      "Unit Type": "",
      "Unit Number": "",
      "City": "Portsmouth",
      "State": "VA",
      "County": "Portsmouth City",
      "Zip": 23704,
      "Country": "US",
      "Source": "Virginia Geographic Information Network (VGIN)",
      "Phone": "7579565162"
   },
   "126": {
      "ShipyardName": "A-team Ship Repair Inc",
      "ShipyardType": "Ship Repair Company",
      "Address": "809 Live Oak Drive Suite 19 Chesapeake, VA 23320",
      "Latitude": 36.796358,
      "Longitude": -76.232132,
      "Number": 809,
      "Street": "Live Oak Dr",
      "Unit Type": "Ste",
      "Unit Number": "19",
      "City": "Chesapeake",
      "State": "VA",
      "County": "Chesapeake City",
      "Zip": 23320,
      "Country": "US",
      "Source": "City of Chesapeake",
      "Phone": "7574248400"
   },
   "127": {
      "ShipyardName": "Supervisor Of Shipbuilding, Conversion & Repair, Usn",
      "ShipyardType": "Ship Repair Company",
      "Address": "4101 Washington Avenue Bldg 2 Newport News, VA 23607-2787",
      "Latitude": 36.992027,
      "Longitude": -76.434034,
      "Number": 4101,
      "Street": "Washington Ave",
      "Unit Type": "Bldg",
      "Unit Number": "2",
      "City": "Newport News",
      "State": "VA",
      "County": "Newport News City",
      "Zip": 23607,
      "Country": "US",
      "Source": "City of Newport News",
      "Phone": "7573804221"
   }
}

export default locationsMark;