import Background from "./Background";
import Header from "./Header";
import $ from 'jquery';
import Footer from "./Footer";
import React, {useEffect, useState} from "react";
import queryString from "query-string";
import {useHistory, useLocation} from "react-router-dom";
import positions from "../assets/positions";
import MonteCarlo from "./MonteCarlo";
import ComparePositionCarousel from "./ComparePositionCarousel";

const Comparison = () => {

    const {search} = useLocation()
    const {name, edu, goal, skills} = queryString.parse(search)

    const [eduSelected, setEduSelected] = useState(edu)
    const [positionList, setPositionList] = useState(null)
    const [position1, setPosition1] = useState(null)
    const [position2, setPosition2] = useState(null)
    const [isCarouselOpen, setIsCarouselOpen] = useState(false)

    // on mount
    useEffect(() => {
        document.getElementById('edu-dropdown').addEventListener('change', function () {
            setEduSelected(this.value)
        });

        if (eduSelected !== '') {
            // *** code to select edu from dropdown
            $('#edu-dropdown').val(edu)
            addPositions()
        } else {
            $('#edu-dropdown').val('none')
        }

        if (document.querySelector('[data-title="Autoscale"]')) {
            document.querySelector('[data-title="Autoscale"]').click()
        }
    }, [])

    useEffect(() => {
        // used to detect when button pressed in carousel component
        $('.carousel-position-selected').removeClass('carousel-position-selected')
        if (isCarouselOpen) {
            document.getElementById("close-popup-btn").addEventListener('click', () => {
                setIsCarouselOpen(false)
            })

            document.getElementById('add-as-pos1').addEventListener('click', function () {
                $('#dropdown1').val($('#invisible-pos').val())
                $('#add-as-pos1').addClass('carousel-position-selected')
            })

            document.getElementById('add-as-pos2').addEventListener('click', function () {
                $('#dropdown2').val($('#invisible-pos').val()).addClass('carousel-position-selected')
                $('#add-as-pos2').addClass('carousel-position-selected')
            })
        }
    }, [isCarouselOpen])

    // when user selects new education
    useEffect(() => {
        $('#dropdown1').empty()
        $('#dropdown2').empty()
        if (eduSelected !== '' && eduSelected !== 'none') {
            addPositions()
        }
    }, [eduSelected])

    // populate dropdown1 and dropdown2 with positions
    useEffect(() => {
        if (positionList !== null) {
            let dropdownOptions = `<option value="none">Select a position</option>`
            positionList.forEach(positionID => {
                const positionOBJ = positions.filter(item => item['PositionId'] === positionID)[0]
                dropdownOptions += `<option value=${positionID}>${positionOBJ['Position']}</option>`
            })
            $('#dropdown1').append(dropdownOptions)
            $('#dropdown2').append(dropdownOptions)
        }
    }, [positionList])

    // add the positions specified by the education to the two dropdowns
    const addPositions = () => {
        const currentEdu = eduSelected
        let eduString = ''
        const newPositionList = []

        switch (currentEdu) {
            case 'HSG':
                eduString = 'High School'
                break
            case 'PRO':
                eduString = 'Professional'
                break
            case 'CG':
                eduString = 'College'
                break
            case 'VET':
                eduString = 'Veteran'
                break
        }

        positions.forEach(p => {
            if (p['JourneyType'] === eduString) {
                newPositionList.push(p['PositionId'])
            }
        })

        setPositionList(newPositionList)
    }

    const h = useHistory();

    const navigateBack = () => {
        h.push("/quiz5?name=" + name + "&edu=" + edu + "&goal=" + goal + "&skills=" + skills)
    }

    const selectPositions = () => {
        const pos1 = $('#dropdown1').val()
        const pos2 = $('#dropdown2').val()
        $('#compare-error').empty()

        if ((eduSelected === 'none' || eduSelected === '' || eduSelected === null)) {
            $('#compare-error').append('select an education level, then select two different positions to compare')
        } else if ((pos1 === pos2) || (pos1 === 'none') || (pos2 === 'none')) {
            $('#compare-error').append('select two different positions to compare')
        } else {
            updateTable(pos1, pos2)
        }
    }

    const updateTable = (leftPositionID, rightPositionID) => {
        if (leftPositionID) {
            setPosition1(positions.filter(item => item['PositionId'] === leftPositionID)[0])
        }

        if (rightPositionID) {
            setPosition2(positions.filter(item => item['PositionId'] === rightPositionID)[0])
        }
    }

    return (
        <>
            <div className={"home box"}>
                <Background/>
                <Header/>
                <div className={'compare-container'}>
                    <button className={'btn btn-primary compare-back-button'}
                            style={{'height': '40px', 'width': '80px'}}
                            onClick={navigateBack}>Back
                    </button>
                    {
                        isCarouselOpen && <ComparePositionCarousel idList={positionList}/>
                    }
                    <div className={'row comparison-dropdown-row'}>
                        <div className={'col-3 form-group'}>
                            <label htmlFor="edu-dropdown" className={'dropdown-label-text'}>Journey Type: </label>
                            <div className={'row'} style={{'padding': '0', 'margin': '0'}}>
                                <select name="edu-dropdown" id="edu-dropdown" className={'form-control'}
                                        defaultValue={'none'}>
                                    <option value="HSG">High school student/graduate</option>
                                    <option value="PRO">Maritime professional</option>
                                    <option value="CG">College graduate</option>
                                    <option value="VET">Veteran</option>
                                    <option value="none">Select an education level</option>
                                </select>

                                {
                                    positionList && <div className={'compare-car-svg'} onClick={() => {
                                        setIsCarouselOpen(true)
                                    }}>
                                        <img src={'CarouselButton.svg'}/>
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            (eduSelected !== '' && eduSelected !== null && eduSelected !== 'none' && (
                                <>
                                    <div className={'col-3 form-group'}>
                                        <label htmlFor="dropdown1" className={'dropdown-label-text'}>Position
                                            1: </label>
                                        <select name="dropdown1" id="dropdown1" className={'form-control'}>
                                            <option value="none">Select a position</option>
                                        </select>
                                    </div>
                                    <div className={'col-3 form-group'}>
                                        <label htmlFor="dropdown2" className={'dropdown-label-text'}>Position
                                            2: </label>
                                        <select name="dropdown2" id="dropdown2" className={'form-control'}>
                                            <option value="none">Select a different position</option>
                                        </select>
                                    </div>
                                </>
                            )) || (
                                <>
                                    <div className={'col-3'}/>
                                    <div className={'col-3'}/>
                                </>
                            )
                        }
                        <div className={'col-3'}>
                            <button className={'btn btn-secondary select-btn'} onClick={selectPositions}>Select</button>
                        </div>
                    </div>
                    <div className={'row compare-error-row'} id={'compare-error'}></div>
                    {
                        position1 && position2 && (
                            <>
                                <div className={'row comparison-table'}>
                                    <div className={'row comparison-table-row'}>
                                        <div className={'col-4'}/>
                                        <div className={'col-4 pName-text'}>{position1['Position']}</div>
                                        <div className={'col-4 pName-text'}>{position2['Position']}</div>
                                    </div>
                                    <div className={'row comparison-table-row'}>
                                        <div className={'col-4 pTitle-text'}>Journey Type</div>
                                        <div className={'col-4 p1-text'}>{position1['JourneyType']}</div>
                                        <div className={'col-4 p2-text'}>{position2['JourneyType']}</div>
                                    </div>
                                    <div className={'row comparison-table-row'}>
                                        <div className={'col-4 pTitle-text'}>Category</div>
                                        <div className={'col-4 p1-text'}>{position1['Category']}</div>
                                        <div className={'col-4 p2-text'}>{position2['Category']}</div>
                                    </div>
                                    <div className={'row comparison-table-row'}>
                                        <div className={'col-4 pTitle-text'}>Skills</div>
                                        <div className={'col-4 p1-text'}>{position1['Skills'].join(', ')}</div>
                                        <div className={'col-4 p2-text'}>{position2['Skills'].join(', ')}</div>
                                    </div>
                                    <div className={'row comparison-table-row'}>
                                        <div className={'col-4 pTitle-text'}>Timeframe</div>
                                        <div className={'col-4 p1-text'}>{position1['Timeframe']}</div>
                                        <div className={'col-4 p2-text'}>{position2['Timeframe']}</div>
                                    </div>
                                    <div className={'row comparison-table-row'}>
                                        <div className={'col-4 pTitle-text'}>Minimum Salary</div>
                                        <div className={'col-4 p1-text'}>${position1['SalaryMin']}</div>
                                        <div className={'col-4 p2-text'}>${position2['SalaryMin']}</div>
                                    </div>
                                    <div className={'row comparison-table-row'}>
                                        <div className={'col-4 pTitle-text'}>Maximum Salary</div>
                                        <div className={'col-4 p1-text'}>${position1['SalaryMax']}</div>
                                        <div className={'col-4 p2-text'}>${position2['SalaryMax']}</div>
                                    </div>
                                    <div className={'row comparison-table-row'}>
                                        <div className={'col-4 pTitle-text'}>Position Description</div>
                                        <div className={'col-4 p1-text'}>{position1['Description']}</div>
                                        <div className={'col-4 p2-text'}>{position2['Description']}</div>
                                    </div>
                                    <div className={'row comparison-table-row'}>
                                        <div className={'col-4 pTitle-text'}>Education Requirement</div>
                                        <div className={'col-4 p1-text'}>{position1['EducationRequirement']}</div>
                                        <div className={'col-4 p2-text'}>{position2['EducationRequirement']}</div>
                                    </div>
                                    <div className={'row comparison-table-row'}>
                                        <div className={'col-4 pTitle-text'}>Cost</div>
                                        <div className={'col-4 p1-text'}>${position1['Cost']}</div>
                                        <div className={'col-4 p2-text'}>${position2['Cost']}</div>
                                    </div>
                                    <div className={'row comparison-table-row'}>
                                        <div className={'col-4 pTitle-text'}>Benefits</div>
                                        <div className={'col-4 p1-text'}>${position1['Benefits']}</div>
                                        <div className={'col-4 p2-text'}>${position2['Benefits']}</div>
                                    </div>
                                </div>
                                <div className={'row comparison-monte-carlo'} id={'compare-graph'}>
                                    <MonteCarlo idList={[position1['PositionId'], position2['PositionId']]}
                                                isDouble={true}/>
                                </div>
                            </>
                        )
                    }
                </div>
                <Footer/>
            </div>
        </>
    )
}

export default Comparison