import ReactFlow, {getIncomers, getOutgoers, isEdge, isNode} from 'react-flow-renderer';
import {useLocation} from "react-router-dom";
import queryString from "query-string";
import Background from "./Background";
import Header from "./Header";
import Filler from "./Filler";
import Footer from "./Footer";
import React, {useState} from "react";
import $ from "jquery";
import filterData from "../assets/filterData";
import positions from "../assets/positions";
import positionsAdjacency from "../assets/positionsAdjacency";
import {getEducationPositions, getNonEduEntryPositons, getMiddlePositions, getEndPositions} from "../assets/dataUtils";
import Popup from "./Popup";
import JourneyItemList from "./JourneyItemList";
import {toDollars} from "../assets/dataUtils"
import CustomCarousel from "./CustomCarousel";
import positionStories from "../assets/positionStories";
import JourneyPrintout from "./JourneyPrintout";
import RightPopup from "./RightPopup";
import LeftPopup from "./LeftPopup";
import LowerLogo from "./LowerLogo";
import {Elements} from "react-flow-renderer";
import MonteCarlo from "./MonteCarlo";

const Pathways = () => {
    const {search} = useLocation()
    // get info from the URL params
    const {name, edu, goal, skills, category, min, max, times, journey} = queryString.parse(search)
    // parse info to send to initial journey
    const initialSelectedSkills = skills.split(',').map(e => e.split(" ").join("-"));
    const initialSelectedCats = category;

    const eduPositons = getEducationPositions(positions)
    const entryPositions = getNonEduEntryPositons(positions)
    const middlePositions = getMiddlePositions(positions)
    const endPositions = getEndPositions(positions)
    const colorPallete = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#ea474a', '#e6beff', '#aaffc3', '#808000', '#ffd8b1', '#808080', '#ffffff', '#000000', '#800000', '#fffac8', '#9a6324', '#008080']
    const categoryColorAssignments = getCategoryColorAssignments()
    const [positionColors, setPositionColors] = useState({})

    // interpreting education into journeyType variable
    let journeyType
    switch (edu) {
        case "VET":
            journeyType = "Veteran"
            break
        case "PRO":
            journeyType = "Professional"
            break
        case "CG":
            journeyType = "College"
            break
        default:
            journeyType = "High School"
    }

    // list of integer ids to reference when building the user personal journey section
    const [currentJourneyList, setCurrentJourneyList] = useState([])

    // minimum salary set by the user
    const [minimum, setMinimum] = useState(() => {
        if (min) {
            return min
        } else {
            return 0
        }
        // return 0
    })

    // maximum salary set by the user
    const [maximum, setMaximum] = useState(() => {
        if (max) {
            return max
        } else {
            return 300000
        }
    })

    const salary = [minimum, maximum]

    // current nodes in career graph
    const [elements, setElements] = useState(() => {
        // *NOTE* replaced user quiz input skills -> [skills]   with all skills -> []
        return getFilterJourney(initialSelectedCats, salary, skills.split(','), [], journeyType)
    })

    // currently selected filter buttons
    const [selected, setSelected] = useState(() => {
        let selectedList = [].concat(initialSelectedSkills).concat(category.split(',').map(e => e.split(" ").join("-")))
        // no times provided so add them all
        if (!times) {
            const tempTimes = ["0-5-years", "6-15-years", "16-years"]
            for (const t in tempTimes) {
                selectedList.push(tempTimes[t])
            }
        } else {
            if (times.split(",").length > 0) {
                for (const t in times.split(",")) {
                    selectedList.push(times.split(",")[t])
                }
            }
        }
        return selectedList
        // "0-5-years", "6-15-years", "16-years" ASDF
    })

    // popup status, shown or hidden
    const [isOpen, setIsOpen] = useState(false);

    // popup flipped status
    const [isFront, setIsFront] = useState(true);

    // current selected job position node
    const [currentNode, setCurrentNode] = useState('')

    // carousel status, shown or hidden
    const [isCarouselOpen, setIsCarouselOpen] = useState(false)
    const [isPrintoutOpen, setIsPrintoutOpen] = useState(false)
    const [showLeftPopup, setShowLeftPopup] = useState(true)
    const [showRightPopup, setShowRightPopup] = useState(true)

    // determines if this is first load for the pathways page
    const [isFirstLoad, setIsFirstLoad] = useState(true)

    // change the color of a filter button when clicked
    function selectButton(buttonID) {
        $("#" + buttonID).toggleClass("selected-filter");
    }

    // apply changes to HTML when page is loaded
    $(document).ready(function () {
        $("#filter-category")
            .empty()
        $("#filter-skills")
            .empty()
        $("#filter-timeframe")
            .empty()
        generateFilters()

        $("#min-salary").attr("value", minimum);
        $("#max-salary").attr("value", maximum);

        for (const i in selected) {
            let btnID = selected[i]
            if (btnID.includes('/')) {
                btnID = btnID.replaceAll('/','-')
            }
            $("#" + btnID).addClass("selected-filter")
        }

        if (isFirstLoad) {
            if (journey && journey !== []) {
                setCurrentJourneyList(journey.split(","))
            }
            applyFilters();
            setIsFirstLoad(false);
        }
    })

    function getFilters() {
        let selectedButtons = []

        let cat_selected = []
        $('#filter-category > button').each(function () {
            if ((this.className).includes("selected-filter")) {
                if (!(cat_selected.includes(this.innerText))) {
                    cat_selected.push(this.innerText)
                    selectedButtons.push(this.id)
                }
            }
        });

        const skills_selected = []
        $('#filter-skills > button').each(function () {
            if ((this.className).includes("selected-filter")) {
                if (!(skills_selected.includes(this.innerText))) {
                    skills_selected.push(this.innerText)
                    selectedButtons.push(this.id)
                }
            }
        });

        let timeframe_selected = []
        $('#filter-timeframe > button').each(function () {
            if ((this.className).includes("selected-filter")) {
                if (!(timeframe_selected.includes(this.innerText))) {
                    timeframe_selected.push(this.innerText)
                    selectedButtons.push(this.id)
                }
            }
        });

        let myMin = 0
        let myMax = 300000

        if ($("#min-salary").val()) {
            myMin = parseInt($("#min-salary").val())
        }
        setMinimum(myMin)

        if ($("#max-salary").val()) {
            myMax = parseInt($("#max-salary").val())
        }
        setMaximum(myMax)

        const salary = [myMin, myMax]

        return [cat_selected, skills_selected, timeframe_selected, selectedButtons, salary]
    }

    // set React-Render graph elements based on filters
    function applyFilters() {
        const curFilters = getFilters();
        const cat_selected = curFilters[0];
        const skills_selected = curFilters[1];
        const timeframe_selected = curFilters[2];
        const selectedButtons = curFilters[3];
        const salary = curFilters[4];

        // get path based on selected filters
        setElements(getFilterJourney(cat_selected, salary, skills_selected, timeframe_selected, journeyType))
        setSelected(selectedButtons)
        if (showRightPopup && !isFirstLoad) {
            setShowRightPopup(false)
        }

        const time_selected = timeframe_selected.join(">").split(" ").join("-").split("+").join("").split(">")

        const newURL = "/pathways?name=" + name +
            "&edu=" + edu +
            "&goal=" + goal +
            "&skills=" + skills_selected +
            "&category=" + cat_selected +
            "&min=" + salary[0] +
            "&max=" + salary[1] +
            "&times=" + time_selected +
            "&journey=" + currentJourneyList;
        window.history.pushState({}, null, newURL);

        if (!time_selected.includes('')) {
            for (const t in time_selected) {
                $("#" + time_selected[t]).addClass("selected-filter")
            }
        }
    }

    function getNodeStyle(color) {
        return {
            background: color,
            "boxSizing": "border-box",
            "boxShadow": "0px 4px 4px rgba(0, 0, 0, 0.25)"
        }
    }

    function resetFilter(){
       $(".filter-button").addClass("selected-filter")
       $("#min-salary").attr("value", 0)
       $("#max-salary").attr("value", 300000)
       applyFilters()
    }



    // assign location for each graph node
    function setXYCoords(positionElements) {
        let nodeMap = {}
        let edgeMap = {}
        let edges = []
        for (const elem of positionElements) {
            if (elem["id"].startsWith("e")) {
                if (!(elem["source"] in edgeMap)) {
                    edgeMap[elem["source"]] = []
                }
                edgeMap[elem["source"]].push(elem["target"])
                edges.push(elem)
            } else {

                nodeMap[elem["id"]] = elem
            }
        }

        let givenCoords = {}
        let colorAssingments = {}
        let nextColor = 0
        let toReturn = edges

        let xCoord = 100
        const xCoordStep = 160
        const userYCoord = 0
        const yCoordAssocRow = 150
        const yCoordFirstRow = 250
        const yCoordEduRow = 350
        const yCoordSecondRow = 450
        const yCoordThirdRow = 650

        // If present Associate's Degree gets special handling
        const assocNode = positionElements
            .filter(p => "data" in p)
            .filter(p => p["data"]["label"] === "Associate's Degree")
        if (assocNode.length > 0) {
            assocNode[0]["position"] = {x: xCoord, y: yCoordAssocRow}
            const color = categoryColorAssignments[assocNode[0]["category"]]
            assocNode[0]["style"] = getNodeStyle(color)
            colorAssingments[assocNode[0]["id"]] = color
            toReturn.push(assocNode[0])
            givenCoords[assocNode[0]["id"]] = 1
        }

        // Education positions on upper left
        const curEduPositions = positionElements
            .filter(p => eduPositons.includes(p["id"]))
            .filter(p => p["data"]["label"] !== "Associate's Degree")
        for (const position of curEduPositions) {
            position["position"] = {x: xCoord, y: yCoordFirstRow}
            const color = categoryColorAssignments[position["category"]]
            position["style"] = getNodeStyle(color)
            colorAssingments[position["id"]] = color
            toReturn.push(position)
            givenCoords[position["id"]] = 1

            if (position["data"]["label"].includes("Degree")) {
                const targetNodes = edgeMap[position["id"]]
                if (targetNodes !== undefined) {
                    for (const targetId of targetNodes) {
                        let targetNode = nodeMap[targetId]
                        targetNode["position"] = {x: xCoord, y: yCoordEduRow}
                        targetNode["style"] = getNodeStyle(color)
                        colorAssingments[targetNode["id"]] = color
                        toReturn.push(targetNode)
                        givenCoords[targetNode["id"]] = 1
                        xCoord += xCoordStep
                    }
                }
            } else {
                xCoord += xCoordStep
            }
        }

        // Entry positions on upper row after education positions
        const curEntryPositions = positionElements
            .filter(p => entryPositions.includes(p["id"]))
            .filter(p => !(p["id"] in givenCoords))
        for (const position of curEntryPositions) {
            position["position"] = {x: xCoord, y: yCoordFirstRow}
            const color = categoryColorAssignments[position["category"]]
            position["style"] = getNodeStyle(color)
            colorAssingments[position["id"]] = color
            toReturn.push(position)
            givenCoords[position["id"]] = 1

            // Entry positions that have a link whose target is the same category
            // will be directly below the intery position
            if (position["id"] in edgeMap) {
                for (const target of edgeMap[position["id"]]) {
                    let targetNode = nodeMap[target]
                    const sourceNode = nodeMap[position["id"]]
                    if (sourceNode["category"] === targetNode["category"]) {
                        targetNode["position"] = {x: xCoord, y: yCoordSecondRow}
                        const tColor = categoryColorAssignments[targetNode["category"]]
                        targetNode["style"] = getNodeStyle(tColor)
                        colorAssingments[targetNode["id"]] = tColor
                        toReturn.push(targetNode)
                        givenCoords[targetNode["id"]] = 1
                        break
                    }
                }
            }

            xCoord += xCoordStep
        }

        // Mid-level positions that were not added with entry
        const curMidPositions = positionElements
            .filter(p => middlePositions.includes(p["id"]))
            .filter(p => !(p["id"] in givenCoords))
        for (const position of curMidPositions) {
            position["position"] = {x: xCoord, y: yCoordSecondRow}
            const color = categoryColorAssignments[position["category"]]
            colorAssingments[position["id"]] = color
            position["style"] = getNodeStyle(color)
            toReturn.push(position)
            xCoord += xCoordStep

        }

        // End positions are roughly centerd on third row
        const curEndPositions = positionElements.filter(p => endPositions.includes(p["id"]))
        const maxXCoord = xCoord
        xCoord = (xCoord + xCoordStep) * (2.0 / 3.0)
        for (const position of curEndPositions) {
            position["position"] = {x: xCoord, y: yCoordThirdRow}
            const color = categoryColorAssignments[position["category"]]
            colorAssingments[position["id"]] = color
            position["style"] = getNodeStyle(color)
            toReturn.push(position)
            xCoord -= xCoordStep
        }

        // Add User node
        toReturn.push({
            id: "user",
            data: {label: name},
            position: {x: maxXCoord / 2, y: userYCoord},
            style: {
                background: "#FEEBEA",
                border: "1px solid #DE1306",
                "boxSizing": "border-box",
                "boxShadow": "4px 4px 0px #F3A39E"
            }
        })
        const userEntryNodes = curEduPositions.concat(curEntryPositions).concat(assocNode)
        for (const entryNode of userEntryNodes) {
            const edgeId = "eUser-" + entryNode["id"]
            toReturn.push({
                id: edgeId,
                source: "user",
                target: entryNode["id"]
            });
        }

        setPositionColors(colorAssingments)

        return toReturn;
    }

    // determine if a position's salary is within a user-specified range
    function isInSalaryRange(inputs, position) {
        const minSalIn = inputs[0]
        const maxSalIn = inputs[1]

        const posMin = position['SalaryMin']
        const posMax = position['SalaryMax']

        if (!posMin || !posMax) {
            return true
        } else {
            return (posMin >= minSalIn && posMax <= maxSalIn)
        }
    }

    // getting the path root nodes from filters
    function getFilterJourney(category, salary, skills, timeframe, journeytype) {
        // *NOTE*
        // if using multiple skills, we would need to parse them individually from the skills parameter string here

        // setting default filters if none are selected
        if (skills.length === 0) {
            let printSkills = []
            positions.filter(x => x.JourneyType === journeyType).forEach((pos) => {
                printSkills = printSkills.concat(pos.Skills)
            })
            skills = [...new Set(printSkills)]
        }

        let newTimeframe = []
        if (timeframe.length === 0 && times) {
            const mytimeframe = times.split(",")
            if (mytimeframe.includes("0-5-years")) {
                newTimeframe.push("0-5 years")
            }
            if (mytimeframe.includes("6-15-years")) {
                newTimeframe.push("6-15 years")
            }
            if (mytimeframe.includes("16-years")) {
                newTimeframe.push("16+ years")
            }

        }
        // FAILSAFE

        let myElements = []

        if (timeframe.length !== 0) {
            newTimeframe = timeframe
        }

        // for each position, find job positions where filters are met
        for (const pos of positions) {
            // need to figure out salary min and max based on filter inputs
            let xPos = 100

            if (pos["JourneyType"] === journeytype
                && category.includes(pos["Category"])
                && isInSalaryRange(salary, pos)
                && newTimeframe.some(r => pos["Timeframe"] === r)
                && skills.some(r => pos["Skills"].includes(r))
            ) {
                let mapNode = {
                    id: pos["PositionId"],
                    data: {label: pos["Position"]},
                    position: {x: xPos, y: 500},
                    category: pos["Category"]
                }

                if (!(mapNode in myElements)) {
                    myElements.push(mapNode)
                    xPos = xPos + 10
                }
            }
        }

        if (myElements.length === 0) {
            myElements.push({
                id: '10000',
                data: {label: "Journey path empty, use filters to create your path."},
                position: {x: $(window).width() / 3, y: $(window).height() / 3}
            })
        } else {
            // traverse the adjacency matrix and add nodes to the graph that lead to this
            for (const i of myElements) {
                findChildNodes(i["id"], myElements)
            }
        }

        myElements = setXYCoords(myElements)
        return myElements
    }

    // find nodes preceding goal nodes
    function findChildNodes(positionID, currentList) {
        // get children from adjacency list
        if (positionID.toString() in positionsAdjacency) {
            // children is an array of integer children id's
            let children = positionsAdjacency[positionID.toString()]
            // add nodes of children
            for (const i of children) {
                // if this node has not already been added to the react-flow elements already, add it
                if (!(currentList.find(x => x.id === i))) {
                    let node = positions.find(x => x.PositionId === i)

                    // add child node
                    currentList.push({
                        id: node["PositionId"],
                        data: {label: node["Position"]},
                        position: {x: 200, y: 200},
                        category: node["Category"]
                    })
                }

                // add edge to parent if not already added
                let edgeID = 'e' + i.toString() + "-" + positionID.toString()
                if (!(currentList.find(x => x.id === edgeID))) {
                    currentList.push({id: edgeID, source: i.toString(), target: positionID.toString()})
                }

                findChildNodes(i, currentList)
            }
        }
    }

    function getCategoryColorAssignments() {
        let categories;
        let skills;
        let colorAssignments = {}

        switch (edu) {
            case "HSG":
                skills = filterData["HighSchool"]["Skills"]
                categories = filterData["HighSchool"]["Category"]
                break
            case "CG":
                skills = filterData["College"]["Skills"]
                categories = filterData["College"]["Category"]
                break
            case "PRO":
                skills = filterData["Professional"]["Skills"]
                categories = filterData["Professional"]["Category"]
                break
            default:
                skills = filterData["Veteran"]["Skills"]
                categories = filterData["Veteran"]["Category"]
                break
        }

        let i = 0
        for (let cat of categories) {
            colorAssignments[cat] = colorPallete[i]
            i += 1
        }
        return colorAssignments
    }

    // generate filters based on user inputs
    function generateFilters() {
        let categories;
        let skills;

        switch (edu) {
            case "HSG":
                skills = filterData["HighSchool"]["Skills"]
                categories = filterData["HighSchool"]["Category"]
                break
            case "CG":
                skills = filterData["College"]["Skills"]
                categories = filterData["College"]["Category"]
                break
            case "PRO":
                skills = filterData["Professional"]["Skills"]
                categories = filterData["Professional"]["Category"]
                break
            default:
                skills = filterData["Veteran"]["Skills"]
                categories = filterData["Veteran"]["Category"]
                break
        }

        for (let cat of categories) {
            const calcWidth = "width: max(calc(" + (cat.length).toString() + " * .8vw), 100px);"
            let cat_altered = cat.split(" ").join("-");
            cat_altered = cat_altered.split("/").join("-");
            $("#filter-category").append(`<button id="${cat_altered}">${cat}</button>`);
            $("#" + cat_altered).on("click", function (e) {
                selectButton(cat_altered)
            })
                .addClass("filter-button btn-primary")
                .css("background", categoryColorAssignments[cat])
                .bind('mouseleave', function () {
                    this.blur();
                });
            const curStyle = $("#" + cat_altered).attr("style");
            $("#" + cat_altered).attr("style", curStyle + calcWidth);
        }

        for (let skill of skills) {
            // const calcWidth = "width: calc(" + (skill.length).toString() + " * 1.05vw);"
            const calcWidth = "width: max(calc(" + (skill.length).toString() + " * .7vw), 140px);"
            let skill_altered = skill.split(" ").join("-");
            skill_altered = skill_altered.split("/").join("-");
            $("#filter-skills").append(`<button id="${skill_altered}">${skill}</button>`);
            $("#" + skill_altered).on("click", function (e) {
                $("#" + skill_altered).toggleClass("selected-filter");
            })
                .addClass("filter-button btn-primary")
                .bind('mouseleave', function () {
                    this.blur();
                }).attr("style", calcWidth);
        }

        const time_frames = [
            "0-5 years",
            "6-15 years",
            "16+ years"]

        for (const tf of time_frames) {
            const calcWidth = "width: max(calc(" + (tf.length).toString() + " * .9vw), 80px);"
            let button_id = tf.split(" ").join("-").split("+").join("");
            $("#filter-timeframe").append(`<button id="${button_id}">${tf}</button>`);
            $("#" + button_id).on("click", function (e) {
                // $("#" + button_id).toggleClass("selected-filter"); IMPORTANT
                selectButton(button_id)
            })
                .addClass("filter-button btn-primary")
                .bind('mouseleave', function () {
                    this.blur();
                }).attr("style", calcWidth);
        }
    }

    function openPopup(event, element) {
        if (element.id === "user") {
            return
        }

        $("#right-popup").attr("hidden", true)
        setShowLeftPopup(false)

        setCurrentNode(element.id)
        const pos = positions.find(x => x.PositionId === element.id)
        setIsOpen(true)
        $("#position-text").text(pos.Position)
        $("#popup-color-box").css("background", positionColors[pos.PositionId])

        if (pos.Description !== '') {
            $("#description-text").text(pos.Description)
        } else {
            $("#description-text").text("Description not listed")
        }
        if (pos.SalaryMin && pos.SalaryMax) {
            $("#salary-text").text("$" + toDollars(pos.SalaryMin) + "-$" + toDollars(pos.SalaryMax) + "/year")
        } else {
            $("#salary-text").text("Salary not listed")
        }
        if (pos.Benefits) {
            $("#benefits-text").text("Approximately $" + toDollars(pos.Benefits))
        } else {
            $("#salary-text").text("Benefits not listed")
        }
        if (pos.EducationRequirement) {
            $("#education-text").text(pos.EducationRequirement)
        } else {
            $("#salary-text").text("Education Requirements not listed")
        }
        if (!(pos.Skills.includes(''))) {
            $("#skills-text").text(pos.Position + "s have the following skills: " + pos.Skills.join(", "))
        } else {
            $("#skills-text").text("Skills not listed")
        }
        if(pos.hasOwnProperty("VideoEmbed")) {
            var videoHtmls = ""
            for (const ind in pos.VideoEmbed) {
                videoHtmls += pos.VideoEmbed[ind]
            }
            $("#video-text").html(videoHtmls)
        } else {
            $("#video-text").text("There are no videos available for this position.")
        }
    }

    function closePopup() {
        setCurrentNode('')
        setIsOpen(false)
        $("#right-popup").attr("hidden", false)
        setIsFront(true)
    }

    function flipPopup() {
        if (isFront) {
            document.getElementById('popup-flip-card').style.transform = "rotateY(180deg)"
            setTimeout(() => {
                if (document.querySelector('[data-title="Autoscale"]')) {
                    document.querySelector('[data-title="Autoscale"]').click()
                }
            }, 100)
        } else {
            document.getElementById('popup-flip-card').style.transform = "rotateY(0deg)"
        }
        setIsFront(!isFront)
    }

    function openCarousel() {
        setIsCarouselOpen(true)
    }

    function closeCarousel() {
        setIsCarouselOpen(false)
    }

    function openPrintout() {
        setIsPrintoutOpen(true)
    }

    function closePrintout() {
        setIsPrintoutOpen(false)
    }

    // add selected node to user personal journey
    function addToJourney() {
        let newList = currentJourneyList.concat([currentNode])
        newList = [...new Set(newList)]
        setCurrentJourneyList(newList)

        // UPDATING URL FOR NEW JOURNEY
        const curFilters = getFilters();
        const cat_selected = curFilters[0];
        const skills_selected = curFilters[1];
        const timeframe_selected = curFilters[2];
        const salary = curFilters[4];

        const time_selected = timeframe_selected.join(">").split(" ").join("-").split("+").join("").split(">")

        const newURL = "/pathways?name=" + name +
            "&edu=" + edu +
            "&goal=" + goal +
            "&skills=" + skills_selected +
            "&category=" + cat_selected +
            "&min=" + salary[0] +
            "&max=" + salary[1] +
            "&times=" + time_selected +
            "&journey=" + newList;
        window.history.pushState({}, null, newURL);

        closePopup()
    }

    // fetch stories for current journey to send to carousel
    function getStories() {
        const stories = positionStories[currentJourneyList.at(-1)]
        let selectedStories = []

        // shuffle and then select 4 or less
        const shuffled = stories.sort(() => Math.random() - 0.5)
        if (stories.length > 3) {
            selectedStories = shuffled.slice(0, 4)
        } else {
            selectedStories = shuffled.slice(0, stories.length)
        }

        return selectedStories
    }

    function deleteJourney() {
        setCurrentJourneyList([])
        $("#delete-journey").blur()
        const newURL = search.split("journey")[0] + "journey="
        window.history.pushState({}, null, newURL);
    }

    const getAllIncomers = (node: Node, elements: Elements): Node[] => {
        return getIncomers(node, elements).reduce(
            (memo, incomer) => [...memo, incomer, ...getAllIncomers(incomer, elements)],
            []
        )
    }

    const getAllOutgoers = (node: Node, elements: Elements): Node[] => {
        return getOutgoers(node, elements).reduce(
            (memo, outgoer) => [...memo, outgoer, ...getAllOutgoers(outgoer, elements)],
            []
        )
    }

    const highlightPath = (
        node: Node,
        elements?: Elements,
        selection?: boolean
    ) => {
        if (node && elements) {
            const allIncomers = getAllIncomers(node, elements)
            const allOutgoers = getAllOutgoers(node, elements)

            setElements((prevElements) => {
                return prevElements?.map((elem) => {
                    const incomerIds = allIncomers.map((i) => i.id)
                    const outgoerIds = allOutgoers.map((o) => o.id)

                    if (
                        isNode(elem) &&
                        (allOutgoers.length > 0 || allIncomers.length > 0)
                    ) {
                        const highlight =
                            elem.id === node.id ||
                            incomerIds.includes(elem.id) ||
                            outgoerIds.includes(elem.id)

                        elem.style = {
                            ...elem.style,
                            opacity: highlight ? 1 : 0.25,
                        }
                    }

                    if (isEdge(elem)) {
                        const animated =
                            (incomerIds.includes(elem.source) &&
                                (incomerIds.includes(elem.target) || node.id === elem.target)) ||
                            (outgoerIds.includes(elem.target) &&
                                (outgoerIds.includes(elem.source) || node.id === elem.source))

                        elem.animated = animated

                        elem.style = {
                            ...elem.style,
                            strokeWidth: animated ? 3 : 1,
                            opacity: animated ? 1 : 0.25,
                        }
                    }

                    return elem
                })
            })
        }
    }

    const resetNodeStyles = () => {
        setElements((prevElements) => {
            return prevElements?.map((elem) => {
                if (isNode(elem)) {
                    elem.style = {
                        ...elem.style,
                        opacity: 1,
                    }
                } else {
                    elem.animated = false
                    elem.style = {
                        ...elem.style,
                        stroke: "#b1b1b7",
                        strokeWidth: 1,
                        opacity: 1,
                    }
                }

                return elem
            })
        })
    }
    
    return (
        <div className={"home box"}>
            <Background/>
            <Header/>
            <div className='pathways'>
                <div className={"pathways-section"} id={"flow-container"}>
                    <ReactFlow
                        elements={elements}
                        id={"userJourney"}
                        onElementClick={openPopup}
                        nodesConnectable={false}
                        onNodeMouseEnter={(_event, node) => highlightPath(node, elements)}
                        onNodeMouseLeave={() => resetNodeStyles()}/>
                </div>
                <div className={"side-bar"}>
                    <div style={{"height": "calc(50% + 55px)", "borderStyle": "solid", "borderWidth": "2px"}}>
                        <div className={"row filter-top-panel"}>
                            
                            <img src={"Vector.svg"} alt={"filter-icon"}></img>
                            <h5 style={{"textAlign": "center"}}>Filter</h5>
                            <button className={"btn-secondary"} id={"apply_button"} onClick={applyFilters}
                                    style={{"width": "28%", "height": "70%"}}>Apply Filters
                            </button>
                            <button className={"btn-secondary"} onClick={resetFilter} 
                            style={{"width": "28%", "height": "70%", "margin": "1%"}} > Reset Filters 
                            </button>
                        <div>
                            <button className={"btn-secondary"} onClick={resetFilter} 
                            style={{"width": "43%", "height": "70%"}} > resetFilter 
                            </button>
                         </div>
                        </div>
                        <div className={"row filter"}>
                            <h5 style={{"fontSize": "min(2.3vh, 20px)"}}>Category</h5>
                            <div className={"row"} id={"filter-category"}></div>
                            <h5 style={{"fontSize": "min(2.3vh, 20px)"}}>Salary Range</h5>
                            <div className={"row"} id={"filter-salary"}>
                            
                                <div className={"col-5"}>
                                    <input type="number" name="min-salary" placeholder=" Min" id={"min-salary"}
                                           className={"round-input"} style={{"position": "unset", "width": "100%"}}/>
                                </div>
                                <div className={"col-2"}/>
                                <div className={"col-5"}>
                                    <input type="number" name="max-salary" placeholder=" Max" id={"max-salary"}
                                           className={"round-input"} style={{"position": "unset", "width": "100%"}}/>
                                </div>
                            </div>
                            <h5 style={{"fontSize": "min(2.3vh, 20px)"}}>Skills</h5>
                            <div className={"row"} id={"filter-skills"}></div>
                            <h5 style={{"fontSize": "min(2.3vh, 20px)"}}>Timeframe</h5>
                            <div className={"row"} id={"filter-timeframe"}></div>
                        </div>
                    </div>
                    <div className={"row"} style={{
                        "height": "calc(47% - 55px)",
                        "marginTop": "3%",
                        "borderStyle": "solid",
                        "borderWidth": "2px",
                        "marginLeft": "0",
                        "marginRight": "0",
                        "background": "#EBE6E6",
                        "alignContent": "center"
                    }}>
                        <div className={"row journey-header"}>
                            <h6 id="journey-user-name">{name.length <= 17 ? name : name.substring(0, 16) + "..."}'s
                                Journey</h6>
                            <>
                                {
                                    currentJourneyList.length <= 0 &&
                                    <button className={"btn btn-danger delete-journey-button"} disabled>Clear
                                        Journey</button>
                                }
                            </>
                            <>
                                {
                                    currentJourneyList.length > 0 &&
                                    <button className={"btn btn-danger delete-journey-button"} onClick={deleteJourney}
                                            id={"delete-journey"}>Clear Journey</button>
                                }
                            </>
                        </div>
                        <div className={"journey-container"}>
                            <JourneyItemList itemIdList={currentJourneyList} idToColor={positionColors}/>
                        </div>
                        <div className={"row"} style={{
                            "height": "23%",
                            "width": "100%",
                            "marginLeft": "0",
                            "marginTop": "2%",
                            "marginBottom": "2%"
                        }}>
                            <>
                                {
                                    currentJourneyList.length <= 0 &&
                                    <div className={"col pathways-button-bar"}>
                                        <button className={"btn open-carousel-btn"}
                                                onClick={openPrintout} disabled style={{"float": "left"}}>My Journey <i
                                            className="fas fa-download"></i>
                                        </button>
                                        <button className={"btn open-carousel-btn"}
                                                onClick={openCarousel} disabled style={{"float": "right"}}>Example
                                            Journeys
                                        </button>
                                    </div>
                                }

                                {
                                    currentJourneyList.length > 0 &&
                                    <div className={"col pathways-button-bar"}>
                                        <button className={"btn open-carousel-btn"} onClick={openPrintout}
                                                style={{"float": "left"}}>My Journey <i
                                            className="fas fa-download"></i></button>
                                        <button className={"btn open-carousel-btn"} onClick={openCarousel}
                                                style={{"float": "right"}}>Example Journeys
                                        </button>
                                    </div>
                                }
                            </>
                        </div>
                    </div>
                </div>
            </div>
            <Filler/>
            <LowerLogo/>
            <Footer/>

            {
                isOpen && <Popup
                    content={
                        <>
                            <div className={"row popup-header"}>
                                <div className={"col-4"}>
                                    <div class="color-box" id="popup-color-box"></div>
                                    <b id={"position-text"}></b>
                                </div>
                                <div className={'col-4'}>
                                    {
                                        (isFront && <button className={"btn-secondary"} onClick={flipPopup}
                                                                  style={{"marginLeft": "calc(100% - 180px)", 'fontSize':'14px'}}><b>Learn More</b>
                                        </button>) || (<button className={"btn-secondary"} onClick={flipPopup}
                                                               style={{"marginLeft": "calc(100% - 180px)", 'fontSize':'14px'}}><b>Go Back</b>
                                        </button>)
                                    }

                                </div>
                                <div className={"col-4"}>
                                    <button className={"btn-info"} onClick={addToJourney}
                                            style={{"marginLeft": "calc(100% - 180px)"}}><b>Add to My Journey</b>
                                    </button>
                                </div>
                            </div>
                            <div className={'flip-card'} id={'popup-flip-card'}>
                                <div className={"card-body popup-body flip-card-front"}>
                                    <div className={"flip-card-front-inner"}>
                                        <div>
                                            <b>Description: </b>
                                            <p id={"description-text"} className={"popup-description-text"}></p>
                                        </div>
                                        <div>
                                            <b>Salary Range: </b>
                                            <p id={"salary-text"} className={"popup-text"}></p>
                                        </div>
                                        <div>
                                            <b>Benefits: </b>
                                            <p id={"benefits-text"} className={"popup-text"}></p>
                                        </div>
                                        <div>
                                            <b>Education Requirement: </b>
                                            <p id={"education-text"} className={"popup-text"}></p>
                                        </div>
                                        <div>
                                            <b>Skills: </b>
                                            <p id={"skills-text"} className={"popup-text"}></p>
                                        </div>
                                        <div>
                                            <b>Video(s): </b>
                                            <p id={"video-text"} className={"popup-text"}></p>
                                        </div>
                                    </div>
                                </div>
                                <div className={"card-body popup-body flip-card-back"}>
                                    <MonteCarlo idList={[currentNode]}/>
                                </div>
                            </div>
                        </>}
                    handleClose={closePopup}
                />
            }

            {
                showLeftPopup &&
                <>
                    <LeftPopup/>
                </>
            }
            {
                showRightPopup &&
                <>
                    <RightPopup/>
                </>
            }

            {
                isCarouselOpen && currentJourneyList && currentJourneyList.at(-1) &&
                <CustomCarousel selectedPosition={currentJourneyList.at(-1)}
                                iconOrder={[7, 8, 9, 10, 11, 12].sort(() => Math.random() - 0.5)}
                                stories={getStories()}
                />
            }
            {
                isCarouselOpen && currentJourneyList && currentJourneyList.at(-1) &&
                <button onClick={closeCarousel} className={"carousel-close-button"}>X</button>
            }
            {
                isPrintoutOpen && currentJourneyList && currentJourneyList.at(-1) &&
                <JourneyPrintout journey={currentJourneyList} colors={positionColors}/>
            }
            {
                isPrintoutOpen && currentJourneyList && currentJourneyList.at(-1) &&
                <button onClick={closePrintout} className={"printout-close-button"}>X</button>
            }
        </div>
    );
}

export default Pathways;