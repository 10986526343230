import JourneyItem from "./JourneyItem";
import positions from "../assets/positions";

const JourneyItemList = props => {
    let positionObjectArray = []
    const indexes = props.itemIdList
    for (const index of indexes) {
        positionObjectArray.push(positions.find(x => x.PositionId === index))
    }

  return (
      <>
          {positionObjectArray.map((pos)=>(
              <JourneyItem key={pos.PositionId} position={pos} color={props.idToColor[pos.PositionId]}/>
          ))}
      </>
  )
}

export default JourneyItemList;