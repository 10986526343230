import Background from "./Background";
import Header from "./Header";
import Filler from "./Filler";
import Footer from "./Footer";
import BLink from "./BLink";

const Home = () => {
    return (
        <div className={"home box"}>
            <Background/>
            <Header/>
            <div className='row'>
                <div className={"col-3"}>
                    <img src={"Talking Head.svg"} id={"head"} alt={"talking head"}/>
                    <img src={"Polygon 2.svg"} id={"polygon-2"} alt={"polygon 2"}/>
                </div>

                <div className={"col-6"}>
                    <div className='card card-primary col-7 main_text_view'>
                        <div className='card-body'>
                            <h3>
                                Hi! Welcome to the MIBE career pathways Explorer, where you can explore your journey in maritime skilled trades careers.
                            </h3>
                            <h3>
                                After answering a few short questions, you’ll have the chance to explore different career paths, build a downloadable career developement plan, and run career simulations.
                            </h3>
                        </div>
                        <div className={"row button-bar"}>
                            <BLink text={"Explore"} loc={"/quiz1"}/>
                        </div>
                    </div>
                </div>
            </div>
            <Filler/>
            <Footer/>
        </div>
    );
};

export default Home;