const positionsAdjacency = {
   "12":[
      "1",
      "10",
      "11"
   ],
   "24":[
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11"
   ],
   "25":[
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11"
   ],
   "13":[
      "2",
      "10",
      "11"
   ],
   "14":[
      "3",
      "10",
      "11"
   ],
   "15":[
      "4",
      "10",
      "11"
   ],
   "16":[
      "5",
      "10",
      "11"
   ],
   "17":[
      "6",
      "10",
      "11"
   ],
   "18":[
      "7",
      "10",
      "11"
   ],
   "19":[
      "8",
      "10",
      "11"
   ],
   "20":[
      "9",
      "10",
      "11"
   ],
   "21":[
      "10",
      "11"
   ],
   "22":[
      "10",
      "11"
   ],
   "23":[
      "10",
      "11"
   ],
   "26":[
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23"
   ],
   "27":[
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23"
   ],
   "28":[
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23"
   ],
   "29":[
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23"
   ],
   "47":[
      "30"
   ],
   "53":[
      "30",
      "31",
      "32",
      "33",
      "34",
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46"
   ],
   "48":[
      "31"
   ],
   "49":[
      "32"
   ],
   "50":[
      "33"
   ],
   "51":[
      "34"
   ],
   "52":[
      "35"
   ],
   "54":[
      "36"
   ],
   "55":[
      "37"
   ],
   "56":[
      "39"
   ],
   "57":[
      "40"
   ],
   "58":[
      "41"
   ],
   "59":[
      "42"
   ],
   "60":[
      "43"
   ],
   "61":[
      "44"
   ],
   "62":[
      "47",
      "48",
      "49",
      "50",
      "51",
      "52",
      "53",
      "54",
      "55",
      "56",
      "57",
      "58",
      "59",
      "60",
      "61"
   ],
   "63":[
      "47",
      "48",
      "49",
      "50",
      "51",
      "52",
      "53",
      "54",
      "55",
      "56",
      "57",
      "58",
      "59",
      "60",
      "61"
   ],
   "64":[
      "55"
   ],
   "69":[
      "65"
   ],
   "70":[
      "65"
   ],
   "71":[
      "65"
   ],
   "72":[
      "65"
   ],
   "73":[
      "65"
   ],
   "74":[
      "65"
   ],
   "75":[
      "65"
   ],
   "76":[
      "65"
   ],
   "77":[
      "65"
   ],
   "78":[
      "65"
   ],
   "79":[
      "65"
   ],
   "80":[
      "65"
   ],
   "81":[
      "65"
   ],
   "82":[
      "65"
   ],
   "91":[
      "69"
   ],
   "97":[
      "69",
      "70",
      "71",
      "72",
      "73",
      "74",
      "75",
      "76",
      "77",
      "78",
      "79",
      "80",
      "81",
      "82",
      "87",
      "88",
      "89",
      "90"
   ],
   "92":[
      "70"
   ],
   "93":[
      "71"
   ],
   "94":[
      "72"
   ],
   "95":[
      "73"
   ],
   "96":[
      "74"
   ],
   "98":[
      "88"
   ],
   "101":[
      "79"
   ],
   "102":[
      "80"
   ],
   "103":[
      "81"
   ],
   "104":[
      "82"
   ],
   "87":[
      "83"
   ],
   "88":[
      "83"
   ],
   "89":[
      "83"
   ],
   "90":[
      "83"
   ],
   "99":[
      "88"
   ],
   "111":[
      "87",
      "88",
      "89",
      "90",
      "91",
      "92",
      "93",
      "94",
      "95",
      "96",
      "97",
      "98",
      "99",
      "100",
      "101",
      "102",
      "103",
      "104",
      "108",
      "109",
      "110"
   ],
   "113":[
      "87"
   ],
   "112":[
      "91",
      "92",
      "93",
      "94",
      "95",
      "96",
      "97",
      "98",
      "99",
      "100",
      "101",
      "102",
      "103",
      "104",
      "108",
      "109",
      "110"
   ],
   "108":[
      "105"
   ],
   "109":[
      "105"
   ],
   "110":[
      "105"
   ],
   "131":[
      "114",
      "130"
   ],
   "137":[
      "114",
      "115",
      "116",
      "117",
      "118",
      "119",
      "120",
      "121",
      "122",
      "123",
      "124",
      "125",
      "126",
      "127",
      "128",
      "129",
      "130"
   ],
   "132":[
      "115",
      "130"
   ],
   "133":[
      "116",
      "130"
   ],
   "134":[
      "117",
      "130"
   ],
   "135":[
      "118",
      "130"
   ],
   "136":[
      "119",
      "130"
   ],
   "138":[
      "120",
      "130"
   ],
   "139":[
      "121",
      "130"
   ],
   "140":[
      "123",
      "130"
   ],
   "141":[
      "124",
      "130"
   ],
   "142":[
      "125",
      "130"
   ],
   "143":[
      "126",
      "130"
   ],
   "144":[
      "127",
      "130"
   ],
   "145":[
      "128",
      "130"
   ],
   "146":[
      "131",
      "132",
      "133",
      "134",
      "135",
      "136",
      "137",
      "138",
      "139",
      "140",
      "141",
      "142",
      "143",
      "144",
      "145"
   ],
   "147":[
      "131",
      "132",
      "133",
      "134",
      "135",
      "136",
      "137",
      "138",
      "139",
      "140",
      "141",
      "142",
      "143",
      "144",
      "145"
   ]
}

export default positionsAdjacency;