import Backdrop from "./Backdrop";
import LogoWhite from "./LogoWhite";

const Background = () => {
    return (
        <div>
            <div id={"gradient_2"}/>
            <Backdrop/>
            <LogoWhite/>
        </div>
    );
};

export default Background;