import positions from "../assets/positions";
import JourneyStep from "./JourneyStep";
import JourneyCurrent from "./JourneyCurrent";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas'
import {useLocation} from "react-router-dom";
import queryString from "query-string";
import $ from "jquery"

const JourneyPrintout = ({journey, colors}) => {
    const {search} = useLocation()
    const {name, edu, goal, skills} = queryString.parse(search)

    const goalPosition = positions.find(x => x.PositionId === journey.at(-1))

    let journeyColors = []
    journey.forEach((i) => {
        journeyColors.push(colors[i])
    })

    function printJourney() {
        $(".download-button").attr("hidden", true)
      
        let allPromises = []
        
        const input = document.getElementById('userJourneyHTML')
        allPromises.push(html2canvas(input))

        for (const i of journey) {
            const indexValue = 'userJourneyHTML-' + journey.indexOf(i)
            const input = document.getElementById(indexValue)
            allPromises.push(html2canvas(input))
        }

        Promise.all(allPromises).then((canvases) => {
            const pdf = new jsPDF('p', 'in', [12,15])
            let first = true
            for (const canvas of canvases) {
                if (!first) {
                    pdf.addPage()
                }
                const imgData = canvas.toDataURL('image/png')
                pdf.addImage(imgData, 'JPEG', 0, 0)
                first = false
            }
            pdf.save(name + "Journey.pdf");
        });

        $(".download-button").attr("hidden", false)
    }

    return (
        <div className={"popup-box"}>
            <div className={"printout card"}>
                <JourneyCurrent goalPosition={goalPosition} journeyItems={journey} colors={journeyColors}
                                exportJourney={printJourney}/>
                {journey.map((item, index) => (
                    <>
                        <JourneyStep step={item} color={journeyColors[index]} exportJourney={printJourney} htmlIndex={index}/>
                    </>
                ))}
            </div>
        </div>
    );
};

export default JourneyPrintout;