const filterData = {
  "HighSchool": {
    "Category": [
      "Skilled Trades",
      "Trades Support",
      "Apprentice School",
      "Community College Pre-Hire",
      "Construction Management",
      "Degreed Careers",
      "Professional",
      "Specialist",
      "Management"
    ],
    "Skills": [
      "Hand-eye coordination",
      "Detail",
      "Electrical",
      "Strength",
      "Problem-solving",
      "Measurement",
      "Multi-task",
      "Machinery",
      "Painting",
      "Math",
      "Documentation",
      "Supervising",
      "Organization",
      "Leadership"
    ],
    "TimeFrame": [
      "0-5 years",
      "6-15 years",
      "16+ years"
    ]
  },
  "College": {
    "Category": [
      "Skilled Trades",
      "Trades Support",
      "Designer",
      "Information Technology",
      "Engineer",
      "Business Analyst",
      "Marine Chemist",
      "Naval Architect",
      "Management",
      "Contracts Analyst",
      "Training Instructor",
      "Data Engineer",
      "Data Scientist",
      "Specialist",
      "Principal Engineer"
    ],
    "Skills": [
      "Hand-eye coordination",
      "Detail",
      "Electrical",
      "Strength",
      "Problem-solving",
      "Measurement",
      "Multi-task",
      "Machinery",
      "Painting",
      "Math",
      "Documentation",
      "Design",
      "IT",
      "Supervising",
      "Organization",
      "Instructor"
    ],
    "TimeFrame": [
      "0-5 years",
      "6-15 years",
      "16+ years"
    ]
  },
  "Professional": {
    "Category": [
      "Skilled Trades",
      "Designer",
      "Engineer",
      "Planner",
      "Business Analyst",
      "Contracts Analyst",
      "Information Technology",
      "Administration/Support",
      "Apprentice School",
      "Management",
      "Specialist",
      "Principal Engineer"
    ],
    "Skills": [
      "Hand-eye coordination",
      "Detail",
      "Electrical",
      "Strength",
      "Problem-solving",
      "Measurement",
      "Multi-task",
      "Machinery",
      "Painting",
      "Design",
      "Planning",
      "IT",
      "Supervising",
      "Organization"
    ],
    "TimeFrame": [
      "0-5 years",
      "6-15 years",
      "16+ years"
    ]
  },
  "Veteran": {
    "Category": [
      "Skilled Trades",
      "Designer",
      "Engineer",
      "Planner",
      "Business Analyst",
      "Contracts Analyst",
      "Information Technology",
      "Administration/Support",
      "Apprentice School",
      "Management",
      "Specialist"
    ],
    "Skills": [
      "Hand-eye coordination",
      "Detail",
      "Electrical",
      "Strength",
      "Problem-solving",
      "Measurement",
      "Multi-task",
      "Machinery",
      "Painting",
      "Design",
      "Planning",
      "IT",
      "Supervising",
      "Organization"
    ],
    "TimeFrame": [
      "0-5 years",
      "6-15 years",
      "16+ years"
    ]
  }
};

export default filterData;