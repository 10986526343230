import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import "../styles.css";
import Home from './Home';
import About from "./About";
import Resources from "./Resources";
import Contact from "./Contact";
import QuizName from "./QuizName";
import QuizEducation from "./QuizEducation";
import QuizGoal from "./QuizGoal";
import QuizSkills from "./QuizSkills";
import QuizConfirm from "./QuizConfirm";
import Pathways from "./Pathways";
import Comparison from "./Comparison";

// using react-router, the site navigates to a specified component using the path in the Route element
export default function App() {
    return (
        <Router>
            <Switch>
                <Route path="/" exact component={Home}/>
                <Route path="/about" component={About}/>
                <Route path="/resources" component={Resources}/>
                <Route path="/contact" component={Contact}/>
                <Route path="/quiz1" component={QuizName}/>
                <Route path="/quiz2" component={QuizEducation}/>
                <Route path="/quiz3" component={QuizGoal}/>
                <Route path="/quiz4" component={QuizSkills}/>
                <Route path="/quiz5" component={QuizConfirm}/>
                <Route path="/comparison" component={Comparison}/>
                <Route path="/pathways" component={Pathways}/>
            </Switch>
        </Router>
    );
}
