import Background from "./Background";
import Header from "./Header";
import Filler from "./Filler";
import Footer from "./Footer";
import BLink from "./BLink";
import React from "react";
import {useLocation} from "react-router-dom";
import queryString from "query-string";

const QuizEducation = () => {
    // getting the url parameters
    // in this instance it is getting the parameter associated with the key "name"
    const {search} = useLocation()
    const {name} = queryString.parse(search)

    return (
        <div className={"home box"}>
            <Background/>
            <Header/>
            {/*previously bubble education*/}
            <div className='row'>
                <div className={"col-3"}>
                    <img src={"Talking Head.svg"} id={"head"} alt={"talking head"}/>
                    <img src={"Polygon 2.svg"} id={"polygon-2"} alt={"polygon 2"}/>
                </div>

                <div className={"col-6"}>
                    <div className='card card-primary col-7 main_text_view'>
                        <div className='card-body row justify-content-center'>
                            <h3>Thanks, {name}! Are you a...</h3>
                            <BLink text={"High school student/graduate"} nav={"selection"}
                                   loc={"/quiz3?name=" + name + "&edu=HSG"}/>
                            <BLink text={"Maritime professional"} nav={"selection"}
                                   loc={"/quiz3?name=" + name + "&edu=PRO"}/>
                            <BLink text={"College graduate"} nav={"selection"}
                                   loc={"/quiz3?name=" + name + "&edu=CG"}/>
                            <BLink text={"Veteran"} nav={"selection"}
                                   loc={"/quiz3?name=" + name + "&edu=VET"}/>
                        </div>
                        <div className={"row button-bar"}>
                            <BLink text={"Back"} nav={"left"} loc={"/quiz1?name=" + name}/>
                        </div>
                    </div>
                </div>
            </div>
            <Filler/>
            <Footer/>
        </div>
    );
};

export default QuizEducation;