import {Link} from "react-router-dom";

const Header = () => {
    return (
        <div className={'row justify-content-center'}>
            <div className="navigation col-lg-4 col-md-12 col-sm-12">
                <h5 className={"header_text"}>
                    <Link to="/">Home</Link>|
                    <Link to="/about">About</Link>|
                    <Link to="/resources">Resources</Link>|
                    <Link to="/contact">Contact</Link>
                </h5>
            </div>
            <div className={"seperator"}/>
        </div>
    );
};

export default Header;