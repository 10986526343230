import Plotly from 'plotly.js-basic-dist-min';
import createPlotlyComponent from "react-plotly.js/factory";
import {useEffect, useState} from "react";
import $ from "jquery";
import positions from "../assets/positions";

const MonteCarlo = ({idList, isDouble}) => {
        const Plot = createPlotlyComponent(Plotly);

        const setTraces = (ids, startingSalary, years, increaseRange) => {
            const traces = []

            let salaryList

            let minIncrease = 0
            let maxIncrease = 0.07
            let diffIncrease = 0.07

            // salary provided, update min and max increases
            if (increaseRange) {
                minIncrease = Math.min(...increaseRange)
                maxIncrease = Math.max(...increaseRange)
                diffIncrease = maxIncrease - minIncrease
            }

            // no salary provided, fetch the smallest salary
            if (!startingSalary) {
                salaryList = getStartingSalaries(ids)
            } else {
                if (ids.length === 1) {
                    salaryList = [startingSalary]
                } else {
                    salaryList = startingSalary
                }
            }

            salaryList.forEach((salary, index) => {
                let arrayCount = 0
                const dict = {}

                while (arrayCount < years) {
                    if (arrayCount === 0) {
                        dict['0'] = [salary, 0]
                    } else {
                        let simulationNumber = 0
                        const values = []
                        const previousAverage = dict[(arrayCount - 1).toString()][0]

                        // make array of 30 simulated wage increases
                        while (simulationNumber < 30) {
                            // raise of 2% - 5% of the previous salary each year
                            const raise = (Math.random() * diffIncrease + minIncrease) * previousAverage
                            values.push(previousAverage + raise)

                            simulationNumber += 1
                        }

                        // add mean and standard deviation of simulation to dictionary
                        const n = values.length
                        const mean = values.reduce((a, b) => a + b) / n
                        const sd = Math.sqrt(values.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)

                        dict[arrayCount.toString()] = [Math.round(mean), Math.round(sd)]
                    }
                    arrayCount += 1
                }

                const trace1xValues = []
                const trace1yValues = []
                const trace2xValues = []
                const trace2yValues = []
                const trace3xValues = []
                const trace3yValues = []

                for (const [key, value] of Object.entries(dict)) {
                    const mean = value[0]
                    const stdDev = value[1]

                    trace1xValues.push(parseInt(key))
                    trace1yValues.push(mean)

                    trace2xValues.push(parseInt(key))
                    trace2yValues.push(mean - stdDev)
                }

                for (const [key, value] of Object.entries(dict)) {
                    const mean = value[0]
                    const stdDev = value[1]

                    trace3xValues.push(parseInt(key))
                    trace3yValues.push(mean + stdDev)
                }

                let mainLineColor = "rgb(0,100,80)"
                let altLineColor = "rgba(0,100,80,0.2)"

                if (index === 1) {
                    mainLineColor = "rgb(225,75,14)"
                    altLineColor = "rgba(225,75,14,0.2)"
                }

                const posName = Object.entries(positions).find(pos => pos[1]['PositionId'] === ids[index])[1]['Position']

                traces.push({
                    x: trace2xValues,
                    y: trace2yValues,
                    type: 'scatter',
                    mode: 'lines',
                    line: {color: 'transparent'},
                    showlegend: false
                })

                traces.push({
                    x: trace3xValues,
                    y: trace3yValues,
                    type: 'scatter',
                    fill: 'tonexty',
                    fillcolor: altLineColor,
                    line: {color: 'transparent'},
                    name: '+/- 1 standard deviation',
                    mode: 'lines'
                })

                traces.push({
                    x: trace1xValues,
                    y: trace1yValues,
                    mode: "lines",
                    name: posName,
                    type: "scatter",
                    line: {color: mainLineColor}
                })
            })

            return traces
        }

        // takes in a list of ids corresponding to positions
        const getStartingSalaries = (ids) => {
            const salaries = []

            ids.forEach(positionId => {
                const result = positions.find(obj => {
                    return obj.PositionId === positionId
                })

                if (result.SalaryMin) {
                    salaries.push(result.SalaryMin)
                } else {
                    salaries.push(0)
                }
            })

            return salaries
        }

        const [traceList, setTraceList] = useState(setTraces(idList, null, 11, null))

        const graphInputs = () => {
            let min = parseInt($('#input-min').val()) * 0.01
            let max = parseInt($('#input-max').val()) * 0.01
            let start = parseInt($('#input-salary').val())
            let years = 11

            if (!min) {
                min = 0.02
                $('#input-min').val('2')
            }

            if (!max) {
                max = 0.07
                $('#input-max').val('7')
            }

            if (!start) {
                start = getStartingSalaries(idList)[0]
                $('#input-salary').val(start.toString())
            }

            if ($('#btnradio1').attr('class').split(' ').includes('selected-radio-btn')) {
                years = 6
            } else if ($('#btnradio3').attr('class').split(' ').includes('selected-radio-btn')) {
                years = 26
            }

            if (min && max && start) {
                setTraceList(setTraces(idList, start, years, [min, max]))
                setTimeout(() => {
                    if (document.querySelector('[data-title="Autoscale"]')) {
                        document.querySelector('[data-title="Autoscale"]').click()
                    }
                }, 500)
            }
        }

        // on mount
        useEffect(() => {
            $('#input-min').val(2)
            $('#input-max').val(7)
            $('#input-salary').val(getStartingSalaries(idList)[0])

            if (isDouble) {
                $('.select-btn').on('click', () => {
                    const p1 = $('#dropdown1').val()
                    const p2 = $('#dropdown2').val()

                    if (p1 !== 'none' && p2 !== 'none' && p1 !== p2) {
                        // console.log('updated to:', $('#dropdown1').val(), $('#dropdown2').val())
                        setTraceList(setTraces([p1, p2], null, 11, null))
                        setTimeout(() => {
                            if (document.querySelector('[data-title="Autoscale"]')) {
                                document.querySelector('[data-title="Autoscale"]').click()
                            }
                        }, 500)
                    }
                })
            }
        }, [])

        const selectRadioButton = (radio_id) => {
            $('.selected-radio-btn').removeClass('selected-radio-btn')
            $('#' + radio_id).addClass('selected-radio-btn')
        }

        return (
            <>
                <Plot data={traceList} layout={
                    {
                        paper_bgcolor: "rgb(255,255,255)",
                        plot_bgcolor: "rgb(229,229,229)",
                        xaxis: {
                            title: {
                                text: 'Years Worked'
                            },
                            gridcolor: "rgb(255,255,255)",
                            range: [1, 10],
                            showgrid: true,
                            showline: false,
                            showticklabels: true,
                            tickcolor: "rgb(127,127,127)",
                            ticks: "outside",
                            zeroline: false
                        },
                        yaxis: {
                            title: {
                                text: 'Salary (USD)'
                            },
                            gridcolor: "rgb(255,255,255)",
                            showgrid: true,
                            showline: false,
                            showticklabels: true,
                            tickcolor: "rgb(127,127,127)",
                            ticks: "outside",
                            zeroline: false
                        },
                        autosize: true
                    }
                } useResizeHandeler className={'pltly-graph'} config={{responsive: true}}/>
                {
                    !isDouble && (
                        <>
                            <div className={'row calc-input-row'}>
                                <div className={'row'}/>
                                <div className={'row'}/>
                                <div className={'row'}/>
                                <div className={'row'}/>
                                <div className={'row'}/>
                                minimum % increase
                                <div className={'row input-row'}>
                                    <input type={'number'} placeholder={'%'}
                                           id={'input-min'}/>
                                </div>
                                maximum % increase
                                <div className={'row input-row'}>
                                    <input type={'number'} placeholder={'%'}
                                           id={'input-max'}/>
                                </div>
                                starting salary
                                <div className={'row input-row'}>
                                    <input type={'number'} placeholder={'$'}
                                           id={'input-salary'}/>
                                </div>
                                <div className={'row'}/>
                                <div className={'row'} style={{'height': '40px', 'padding': '0', 'marginLeft': '0'}}>
                                    <div className="btn-group year-btn-group" role="group"
                                         aria-label="Basic radio toggle button group">
                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio1"
                                               autoComplete="off"
                                               onClick={() => {
                                                   // setYearsSelected(6)
                                                   selectRadioButton('btnradio1')
                                               }}/>
                                        <label className="btn btn-outline-secondary year-btn" htmlFor="btnradio1">5
                                            Years</label>

                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio2"
                                               autoComplete="off" onClick={() => {
                                            selectRadioButton('btnradio2')
                                        }}/>
                                        <label className="btn btn-outline-secondary year-btn" htmlFor="btnradio2">10
                                            Years</label>

                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio3"
                                               autoComplete="off" onClick={() => {
                                            selectRadioButton('btnradio3')
                                        }}/>
                                        <label className="btn btn-outline-secondary year-btn" htmlFor="btnradio3">25
                                            Years</label>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <button className={'btn btn-primary graph-button'} onClick={graphInputs}>graph</button>
                                </div>
                                <div className={'row'}/>
                                <div className={'row'}/>
                            </div>
                        </>
                    )
                }
            </>
        );
    }
;

export default MonteCarlo;