import {Link} from "react-router-dom";

// a button/link component used to navigate the site
// the text is the button text, the loc is the navigation location, the nav is the purpose of the button,
// and the onClick is a function the button does on click
const BLink = ({text, loc, nav, onClick}) => {
    if (nav === "left") {
        return (
            <div className={"col-3"}>
                <Link className='btn main-button left-button' to={loc}>
                    <img src={"arrow-left.svg"} alt={"left arrow"} id={"left-arrow"}/>
                    {text}
                </Link>
            </div>
        );
    } else if (nav === "right") {
        return (
            <div className={"col-3"}>
                <button className='btn main-button right-button' onClick={onClick}>
                    {text}
                    <img src={"arrow-right.svg"} alt={"right arrow"} id={"right-arrow"}/>
                </button>
            </div>
        );
    } else if (nav === "selection") {
        return (
            <div className={"row"}>
                <Link className='btn main-button quiz-2-button' to={loc}>
                    {text}
                </Link>
            </div>
        );
    } else if (nav === "split1") {
        return (
            <Link className='btn main-button split-button-1' to={loc}>
                {text}
                <img src={"arrow-right.svg"} alt={"right arrow"} id={"right-arrow"} style={{'height':'2vh', 'marginLeft':'10px'}}/>
            </Link>
        );
    }  else if (nav === "split2") {
        return (
            <Link className='btn main-button split-button-2' to={loc}>
                {text}
                <img src={"arrow-right.svg"} alt={"right arrow"} id={"right-arrow"} style={{'height':'2vh', 'marginLeft':'10px'}}/>
            </Link>
        );
    } else {
        return (
            <div className={"col-3"}>
                <Link className='btn main-button center-button' to={loc}>
                    {text}
                </Link>
            </div>
        );
    }
}

export default BLink;