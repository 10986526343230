import React, {useEffect, useState} from "react";
import $ from "jquery";
import positions from "../assets/positions";
import MonteCarlo from "./MonteCarlo";
import Popup from "./Popup";
import {toDollars} from "../assets/dataUtils";

const ComparePositionCarousel = ({idList}) => {
    const [isFront, setIsFront] = useState(true)
    const [currentID, setCurrentID] = useState(idList[0])
    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
        $("#right-popup").attr("hidden", true)

        const pos = positions.find(x => x.PositionId === currentID)

        $('#invisible-pos').val(currentID)

        $("#position-text").text(pos.Position)
        // $("#popup-color-box").css("background", positionColors[pos.PositionId])

        if (pos.Description !== '') {
            $("#description-text").text(pos.Description)
        } else {
            $("#description-text").text("Description not listed")
        }
        if (pos.SalaryMin && pos.SalaryMax) {
            $("#salary-text").text("$" + toDollars(pos.SalaryMin) + "-$" + toDollars(pos.SalaryMax) + "/year")
        } else {
            $("#salary-text").text("Salary not listed")
        }
        if (pos.Benefits) {
            $("#benefits-text").text("Approximately $" + toDollars(pos.Benefits))
        } else {
            $("#salary-text").text("Benefits not listed")
        }
        if (pos.EducationRequirement) {
            $("#education-text").text(pos.EducationRequirement)
        } else {
            $("#salary-text").text("Education Requirements not listed")
        }
        if (!(pos.Skills.includes(''))) {
            $("#skills-text").text(pos.Position + "s have the following skills: " + pos.Skills.join(", "))
        } else {
            $("#skills-text").text("Skills not listed")
        }
        if (pos.hasOwnProperty("VideoEmbed")) {
            var videoHtmls = ""
            for (const ind in pos.VideoEmbed) {
                videoHtmls += pos.VideoEmbed[ind]
            }
            $("#video-text").html(videoHtmls)
        } else {
            $("#video-text").text("There are no videos available for this position.")
        }
    }, [currentID])

    function closePopup() {
        setIsFront(true)
    }

    function flipPopup() {
        if (isFront) {
            document.getElementById('popup-flip-card').style.transform = "rotateY(180deg)"
            setTimeout(() => {
                if (document.querySelector('[data-title="Autoscale"]')) {
                    document.querySelector('[data-title="Autoscale"]').click()
                }
            }, 100)
        } else {
            document.getElementById('popup-flip-card').style.transform = "rotateY(0deg)"
        }
        setIsFront(!isFront)
    }

    const nextPos = () => {
        let index = currentIndex

        if (index >= idList.length - 1) {
            index = 0
            setCurrentIndex(index)
        } else {
            index = index + 1
            setCurrentIndex(index)
        }

        setTimeout(() => {
            const pos = positions.find(x => x.PositionId === idList[index])
            $('#input-salary').val(pos['SalaryMin'])
            $('.graph-button').trigger('click')
        }, 50)
    }

    const prevPos = () => {
        let index = currentIndex

        if (currentIndex <= 0) {
            index = idList.length - 1
            setCurrentIndex(index)
        } else {
            index = index - 1
            setCurrentIndex(index)
        }

        setTimeout(() => {
            const pos = positions.find(x => x.PositionId === idList[index])
            $('#input-salary').val(pos['SalaryMin'])
            $('.graph-button').trigger('click')
        }, 50)
    }

    useEffect(() => {
        setCurrentID(idList[currentIndex])
    }, [currentIndex])

    return (
        <>
            <Popup
                content={
                    <>
                        <div className={"row popup-header"}>
                            <div className={"col-4"}>
                                {/*<div class="color-box" id="popup-color-box"></div>*/}
                                <b id={"position-text"}></b>
                            </div>
                            <div className={'col-4'}>
                                {
                                    (isFront && <button className={"btn-secondary"} onClick={flipPopup}
                                                        style={{"marginLeft": "calc(100% - 180px)", "fontSize":'14px'}}><b>Learn More</b>
                                    </button>) || (<button className={"btn-secondary"} onClick={flipPopup}
                                                           style={{"marginLeft": "calc(100% - 180px)", 'fontSize':'14px'}}><b>Go Back</b>
                                    </button>)
                                }

                            </div>
                            <div className={'col-2'}>
                                <button className={'btn-secondary add-as-pos-btn'} id={'add-as-pos1'}>select as position 1</button>
                            </div>
                            <input className={'invisible'} id={'invisible-pos'}></input>
                            <div className={'col-2'}>
                                <button className={'btn-secondary add-as-pos-btn'} id={'add-as-pos2'}>select as position 2</button>
                            </div>
                        </div>
                        <div className={'flip-card'} id={'popup-flip-card'}>
                            <div className={"card-body popup-body flip-card-front"}>
                                <div className={'flip-card-front-inner'}>
                                    <div>
                                        <b>Description: </b>
                                        <p id={"description-text"} className={"popup-description-text"}></p>
                                    </div>
                                    <div>
                                        <b>Salary Range: </b>
                                        <p id={"salary-text"} className={"popup-text"}></p>
                                    </div>
                                    <div>
                                        <b>Benefits: </b>
                                        <p id={"benefits-text"} className={"popup-text"}></p>
                                    </div>
                                    <div>
                                        <b>Education Requirement: </b>
                                        <p id={"education-text"} className={"popup-text"}></p>
                                    </div>
                                    <div>
                                        <b>Skills: </b>
                                        <p id={"skills-text"} className={"popup-text"}></p>
                                    </div>
                                    <div>
                                        <b>Video(s): </b>
                                        <p id={"video-text"} className={"popup-text"}></p>
                                    </div>
                                </div>
                            </div>
                            <div className={"card-body popup-body flip-card-back"}>
                                <MonteCarlo idList={[currentID]}/>
                            </div>
                        </div>
                    </>}
                handleClose={closePopup}
            />
            {
                idList.length > 1 && <button className={'left-arrow compare-nav-left'} onClick={prevPos}>
                    <img src={'Polygon 7.png'}/>
                </button>
            }
            {
                idList.length > 1 && <button className={'right-arrow compare-nav-right'} onClick={nextPos}>
                    <img src={'Polygon 3.png'}/>
                </button>
            }
        </>
    );
}

export default ComparePositionCarousel;