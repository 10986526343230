const educationStrings = ['Degree', 'School', 'College']

export const getEducationPositions = (positions) => {
    return positions
                .filter(p => educationStrings.some(e => p['Position'].includes(e)))
                .map(p => p['PositionId'])
}

export const getNonEduEntryPositons = (positions) => {
    return positions
                .filter(p => !educationStrings.some(e => p['Position'].includes(e)))
                .filter(p => p['Timeframe'] === '0-5 years')
                .map(p => p['PositionId'])
}

export const getMiddlePositions = (positions) => {
    return positions
                .filter(p => !educationStrings.some(e => p['Position'].includes(e)))
                .filter(p => p['Timeframe'] === '6-15 years')
                .map(p => p['PositionId'])
}

export const getEndPositions = (positions) => {
    return positions
                .filter(p => !educationStrings.some(e => p['Position'].includes(e)))
                .filter(p => p['Timeframe'] === '16+ years')
                .map(p => p['PositionId'])
}

// convert integer value to dollars value
export const toDollars = (salary) => {
    let output = ''
    if (typeof salary === "number") {
        let salaryReversed = salary.toString().split('').reverse()
        let i = 0
        for (let c of salaryReversed) {
            if (i !== 0 && i !== salaryReversed.length && i % 3 === 0) {
                output = "," + output
            }
            output = c + output
            i++
        }
        return output
    }
}