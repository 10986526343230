const Popup = props => {
    return (
        <div className="popup-box">
            <div className="card card-primary box-overlay black-card-outline">
                {props.content}
                <button id={'close-popup-btn'} className={"btn-close"} onClick={props.handleClose}></button>
            </div>
        </div>
    );
};

export default Popup;