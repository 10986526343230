import Background from "./Background";
import Header from "./Header";
import Filler from "./Filler";
import Footer from "./Footer";
import BLink from "./BLink";
import React, {useState} from "react";
import Select from 'react-select';
import {useLocation, useHistory} from "react-router-dom";
import queryString from "query-string";
import $ from "jquery";
import filterData from "../assets/filterData";

const QuizSkills = () => {
    // getting the url parameters
    // in this instance it is getting the parameter associated with the key "name"
    const {search} = useLocation()
    const {name, edu, goal, skills} = queryString.parse(search)
    const [selectedOptions, setSelectedOptions] = useState([])
    let myGoal = ""

    switch (goal) {
        case "training":
            myGoal = "get more education and training"
            break
        case "current_role":
            myGoal = "move up in your current role"
            break
        case "money":
            myGoal = "make more money"
            break
        default:
            myGoal = "work right away"
    }
    
    function handleChange(newSelectedOptions) {
        const newSelectedOptionsList = newSelectedOptions.map(e => e['value'])        
        setSelectedOptions(newSelectedOptionsList)
    }

    function createSkillOptions(education) {
        let skills;

        switch (education) {
            case "HSG":
                skills = filterData["HighSchool"]["Skills"]
                break
            case "CG":
                skills = filterData["College"]["Skills"]
                break
            case "PRO":
                skills = filterData["Professional"]["Skills"]
                break
            default:
                skills = filterData["Veteran"]["Skills"]
                break
        }
        let skillMapList = []
        for (const skill in skills) {
            skillMapList.push({label: skills[skill], value: skills[skill]})
        }
        return skillMapList
    }

    const h = useHistory();
    // throw error if user does not type a name in, else navigate to the next page when clicking "next" button
    const nextPage = function () {
        if ($("#skill-dropdown").val() !== "default") {
            h.push("/quiz5?name=" + name + "&edu=" + edu + "&goal=" + goal + "&skills=" + selectedOptions.join(','))
        } else {
            $("#skill-dropdown").addClass('error');
        }
    }

    return (
        <div className={"home box"}>
            <Background/>
            <Header/>
            <div className='row'>
                <div className={"col-3"}>
                    <img src={"Talking Head.svg"} id={"head"} alt={"talking head"}/>
                    <img src={"Polygon 2.svg"} id={"polygon-2"} alt={"polygon 2"}/>
                </div>

                <div className={"col-6"}>
                    <div className='card card-primary col-7 main_text_view'>
                        <div className='card-body row justify-content-center'>
                            <h4>Let's explore careers that will let you {myGoal}, {name}.<br/><br/>
                            First, select your skills below.</h4>

                            <Select
                                styles=
                                    {{
                                        container: () => ({
                                            position: "static",
                                            boxSizing: "border-box",
                                            width: "50%"
                                        }),
                                        menu: () => ({
                                            position: "absolute",
                                            zIndex: "100",
                                            width: "47%",
                                            align: "center",
                                            backgroundColor: "white"
                                        })
                                   }}
                            options={createSkillOptions(edu)}
                            isMulti
                            isSearchable
                            onChange={handleChange}
                            width={200}/>
                        </div>
                        <div className={"row button-bar"}>
                            <BLink text={"Back"} nav={"left"} loc={"/quiz3?name=" + name + "&edu=" + edu}/>
                            <BLink text={"Next"} nav={"right"} onClick={nextPage}/>
                        </div>
                    </div>
                </div>
            </div>
            <Filler/>
            <Footer/>
        </div>
    );
};

export default QuizSkills;