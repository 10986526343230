import Background from "./Background";
import Header from "./Header";
import Filler from "./Filler";
import Footer from "./Footer";
import BLink from "./BLink";
import React from "react";
import {useLocation} from "react-router-dom";
import queryString from "query-string";

const QuizGoal = () => {

    const {search} = useLocation()
    const {name, edu} = queryString.parse(search)
    let educationLevel = "";

    // converting the edu parameter into text to use for the page directions
    switch (edu) {
        case "VET":
            educationLevel = "veteran"
            break
        case "PRO":
            educationLevel = "maritime professional"
            break
        case "CG":
            educationLevel = "college graduate"
            break
        default:
            educationLevel = "high school student/graduate"
    }

    return (
        <div className={"home box"}>
            <Background/>
            <Header/>
            <div className='row'>
                <div className={"col-3"}>
                    <img src={"Talking Head.svg"} id={"head"} alt={"talking head"}/>
                    <img src={"Polygon 2.svg"} id={"polygon-2"} alt={"polygon 2"}/>
                </div>

                <div className={"col-6"}>
                    <div className='card card-primary col-7 condensed-text-view'>
                        <div className='card-body row justify-content-center'>
                            <h4>{name}, as a {educationLevel}, there are many great pathways for you!<br/><br/>
                                What is your main goal?</h4>
                            <BLink text={"Get more education and training"} nav={"selection"}
                                   loc={"/quiz4?name=" + name + "&edu=" + edu + "&goal=training"}/>
                            <BLink text={"Move up in my current role"} nav={"selection"}
                                   loc={"/quiz4?name=" + name + "&edu=" + edu + "&goal=current_role"}/>
                            <BLink text={"Make more money"} nav={"selection"}
                                   loc={"/quiz4?name=" + name + "&edu=" + edu + "&goal=money"}/>
                            <BLink text={"Work right away"} nav={"selection"}
                                   loc={"/quiz4?name=" + name + "&edu=" + edu + "&goal=work"}/>
                        </div>
                        <div className={"row button-bar"}>
                            <BLink text={"Back"} nav={"left"} loc={"/quiz2?name=" + name}/>
                        </div>
                    </div>
                </div>
            </div>
            <Filler/>
            <Footer/>
        </div>
    );
};

export default QuizGoal;